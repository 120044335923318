/* global SENTRY_DSN */
import {Config} from "./config";
import {Monitor} from './monitor';
import * as Utils from "./utils";
import * as Constants from "./constants";
import $ from "jquery";
import {PriceScanner} from "./price-scanner";
import {Checkout} from "./checkout";
import './public-api';
import {Converter} from "./converter";
import {localizeCSS} from "./css";
import * as Sentry from '@sentry/browser';
import { callBadgeAndRenderBanner } from "./banner";
import { initKlarna } from "./gip-klarna";

class GIPShopify{

    constructor() {

        this.monitor = null;
        this.converter = null;
        this.checkout = null;

    }

    init(){

        this.loadConfigs(() => {

            Utils.clog(2, "GIP loaded");

            // Load up the trace ID if available
            Utils.loadTraceID();

            // Initialize the currency select
            // Any of the pages may have a currency select
            Utils.loadCacheCurrency();
            let currSelect = $(Constants.GIP_CURRENCY_SELECT);

            if (currSelect.length >= 1) {
                currSelect.val(Config.selectedCurrency || -1);
                currSelect.on('change', function() {

                    let val = $(this).children("option:selected").val();
                    if (val === -1) val = null;

                    // Set currency to selected value
                    Utils.selectCurrency(val);

                    Utils.clog(3, `Currency selector changed: ${val}`);

                    Utils.refreshPageAfterCurrencyChange();
                });
            }


            /**
             * Monitor properties
             */

            this.monitor = new Monitor();
            this.monitor.init();


            /**
             * Converter properties
             */
            this.converter = new Converter();
            this.converter.init();

            /**
             * Checkout properties
             */
            if(Utils.isCheckoutPage()){
                this.checkout = new Checkout();
                this.checkout.init();
            }

            callBadgeAndRenderBanner();

        });

    }

    loadConfigs(callback){

        // Determine whether or not we are on a Shopify Checkout page
        {
            let match = document.location.pathname.match(Constants.SHOPIFY_CHECKOUT_REGEX);

            // Always returns 4 elements if match, since there are 1 full match + 3 capturing groups
            if (match && match.length === 4) {

                //Utils.clog(3, match[0], match[1], match[2], match[3]);

                // This will only grab the cart ID from the URL properly when on checkout pages
                // with the exception of the order summary page. On the order summary page, the cart ID
                // will need to be retrieved from window.Shopify.Checkout.token.
                Config.shopifyCartId = match[1];

                Config.isThankYouPage = match[2] !== undefined; // '/thank_you'
                Config.isShopifyRedirect = match[3] !== undefined; // '/forward' | '/processing'

            }
        }

        // Redirect pages, don't do anything here
        if (Utils.isShopifyRedirectPage()) {
            // @TODO - Determine if we need the old code here
            return;
        }

        // Thank you page and order summary page
        if (Utils.isThankYouPage() || Utils.isOrderSummaryPage()) {

            // Retrieve order ID
            Config.postCheckoutPageOrderID = window.gip_order_id || null;
        }

        if (Utils.isCheckoutPage()) {

            // Retrieve cart token
            Config.checkoutToken = ((window.Shopify || {}).Checkout || {}).token;

            Utils.loadShopifyAPIHost();
        }

        // Determine the api host, stash host and client version from the script url
        {
            let script = $("#gointerpay_localize");
            let scriptSrc = script.attr('src');

            let match = scriptSrc.match(Constants.SCRIPT_REGEX);
            if (!match) {
                Utils.abortConverter("Script src not formatted correctly.");
                return;
            }

            let scriptHost = match[0];
            let clientVersion = "v1.3";
            let gipScriptHost;

            // Check for the configuration in the script tag
            // Using attr() instead of data() for its consistency of returning string values.
            Config.env = script.attr(Constants.CONFIG_NAMES.Env) || Config.env;
            Config.processStoreCurrency = script.attr(Constants.CONFIG_NAMES.processStoreCurrency) === 'true';
            Config.flcOnly = script.attr(Constants.CONFIG_NAMES.FlcOnly) === 'true';
            Config.autoRoundPrices = script.attr(Constants.CONFIG_NAMES.AutoRoundPrices) === 'true';
            Config.merchantId = script.attr(Constants.CONFIG_NAMES.MerchantId);
            Config.storeCurrency = (script.attr(Constants.CONFIG_NAMES.StoreCurrency) || Config.storeCurrency).toUpperCase();

            let whitelistedGatewaysStr = 
                localStorage.getItem(Constants.GIP_LS_WHITELIST_GATEWEAYS) || 
                script.attr(Constants.CONFIG_NAMES.WhitelistedGateways) || '';

            Config.whitelistedGateways = whitelistedGatewaysStr.split(',').map(val => val.trim()).filter(val => val !== "");
            Config.whitelistedGatewayNames = Utils.gatewayNamesFromCodes(Config.whitelistedGateways);
            let whitelistedStoreCurrencyGatewaysStr = script.attr(Constants.CONFIG_NAMES.WhitelistedStoreCurrencyGateways) || '';
            Config.whitelistedStoreCurrencyGateways = whitelistedStoreCurrencyGatewaysStr.split(',').map(val => val.trim()).filter(val => val !== "");
            Config.whitelistedStoreCurrencyGatewayNames = Utils.gatewayNamesFromCodes(Config.whitelistedStoreCurrencyGateways);

            let disabledCountries = script.attr(Constants.CONFIG_NAMES.DisabledCountries) || '';
            Config.disabledCountries = disabledCountries.split(',').map(val => val.trim().toUpperCase()).filter(val => val !== "");

            let allowedCurrencies = script.attr(Constants.CONFIG_NAMES.AllowedCurrencies) || '';
            Config.allowedCurrencies = allowedCurrencies.split(',').map(val => val.trim().toUpperCase()).filter(val => val !== "");
            Config.hideZeroDecimals = Utils.asBool(script.attr(Constants.CONFIG_NAMES.HideZeroDecimals));

            let currencyTag = script.attr(Constants.CONFIG_NAMES.CurrencyTag) || '';
            if(currencyTag) {
                if (currencyTag.match(/[^a-zA-Z\d]/g)) {
                    Utils.clog(3, "Currency tag has invalid characters");
                } else {
                    Config.currencyTag = currencyTag;
                }
            }

            // default to light theme
            Config.badgeTheme = script.attr(Constants.CONFIG_NAMES.BadgeTheme) === 'dark' ? 'dark' : 'light';
            // if banner theme is not defined, default to badge theme
            Config.bannerTheme = script.attr(Constants.CONFIG_NAMES.BannerTheme) === undefined ? Config.badgeTheme : script.attr(Constants.CONFIG_NAMES.BannerTheme);
            // default banner position to bottom
            Config.bannerPosition = script.attr(Constants.CONFIG_NAMES.BannerPosition) === 'top' ? 'top' : 'bottom';
            // set edge mode based on localstore or data-attribute
            Config.edgeMode = localStorage.getItem(Constants.GIP_LS_EDGE_MODE) !== null ?
                Utils.asBool(localStorage.getItem(Constants.GIP_LS_EDGE_MODE))
                : Utils.asBool(script.attr(Constants.CONFIG_NAMES.EdgeMode));

            // allow debug overrides
            Config.klarnaAnchorProduct = localStorage.getItem(Constants.GIP_LS_KLARNA_ANCHOR_PRODUCT) !== null ?
                localStorage.getItem(Constants.GIP_LS_KLARNA_ANCHOR_PRODUCT)
                : script.attr(Constants.CONFIG_NAMES.KlarnaAnchorProduct);

            // enforce `dark` only
            let attrKlarnaPlacementTheme = script.attr(Constants.CONFIG_NAMES.KlarnaPlacementTheme);
            if(attrKlarnaPlacementTheme && attrKlarnaPlacementTheme.toLowerCase() === 'dark') {
                Config.klarnaPlacementTheme = 'dark'
            }
            
            // enforce matching string format `credit-promotion-`
            let attrKlarnaPlacementKey = script.attr(Constants.CONFIG_NAMES.KlarnaPlacementKey);
            if(attrKlarnaPlacementKey && attrKlarnaPlacementKey.indexOf('credit-promotion-') === 0) {
                Config.klarnaPlacementKey = attrKlarnaPlacementKey;
            }
            
            // Production
            if(Config.env === Constants.ENV_PROD) {
                gipScriptHost = "https://shopify.gointerpay.net";
            }

            // Sandbox
            else if(Config.env === Constants.ENV_SANDBOX) {
                gipScriptHost = "https://shopify-sandbox.gointerpay.net";
            }

            // Dev
            else if(Config.env === Constants.ENV_DEV) {
                gipScriptHost = "https://shopify.rch.red";
            }

            // QA
            else if(Config.env === Constants.ENV_QA) {
                gipScriptHost = "https://shopify.rch.ninja";
            }

            else {
                Utils.abortConverter("Script env not set correctly.");
                return;
            }

            Config.scriptHost = scriptHost;
            Config.clientVersion = clientVersion;
            Config.gipScriptHost = gipScriptHost;
            Config.stashHost = Config.gipScriptHost.replace("shopify", "stash");
            Config.apiHost = Config.gipScriptHost.replace("shopify", "checkout");

            Utils.clog(3, "Environment:", Config.env);

        }

        initKlarna();

        // Check we have what we need.
        // If FLC only, we can bypass these checks.
        if ((!Config.gipScriptHost || !Config.merchantId)
            && !Config.flcOnly) {
            Utils.abortConverter("Missing key elements from script url.");
            return;
        }

        callback();

    }

    /**
     * Abort converter
     */
    abortConverter(){
        if(this.converter) {
            this.converter.abort();
        }
    }

    /**
     * Check if convert is aborted
     */
    convertAborted(){
        return this.converter ? this.converter.aborted() : true;
    }

    /**
     * Run the convert again as soon as possible
     */
    forceUpdate(){
        if(this.converter) {
            this.converter.forceConvert();
        }
    }

    /**
     * Stop monitor execution
     */
    stop(){
        if(this.monitor) {
            this.monitor.stop();
        }
    }

    /**
     * Inject our localize CSS into the head.
     *
     * We moved from loading localize.css as a separate stylesheet, and instead will
     * inject it into the head via JS. This will remove the need to install a separate
     * stylesheet as well as eliminate any errors relating to being able to load one
     * and not the other.
     */
    static loadLocalizeCSS(){

        let styles = `/* Reach Localize CSS */ ` + localizeCSS;

        //Utils.clog(3, styles);

        let styleSheet = document.createElement("style");
        styleSheet.innerText = styles;
        document.head.appendChild(styleSheet);
    }

    /**
     * Sentry.io error tracking
     *
     * We're using Sentry.io to track all JS errors from the localize.js script.
     */
    static initSentryErrorTracking(){

        // Check if Sentry was already initialized
        // If so we disable our Sentry to prevent any conflicts
        if(Sentry.getCurrentHub().getClient() !== undefined){
            Config.disableSentry = true;
            Utils.clog(3, "Another Sentry installation was detected, disabling own Sentry");
            return;
        }

        if(Config.debugMode) {
            window.onerror = function (error, url, line) {
                Utils.clog(3, 'error, url, line', error, url, line);
            };
        }

        let whitelistRegexList = [
            /https:\/\/assets\.rch\.how/,
            /https:\/\/assets\.rch\.io/,
            /https:\/\/assets\.rch\.dev/,
        ];

        let ignoreErrors = [
            'The play method is not allowed',
        ];

        Sentry.init({
            dsn: SENTRY_DSN, // SENTRY_DSN is defined through webpack

            release: 'shopify-localizejs@' + Constants.VERSION,

            // Use our tweaked version of whitelisting instead since Sentry will still
            // allow null URLs.
            //whitelistUrls: whitelistRegexList,

            ignoreErrors,

            integrations: integrations => {

                // We're removing some default integrations in the effort to filter out all
                // user-land errors coming from local/external scripts besides our own script.
                // These integrations wrap user-land code which makes the errors look like they're coming
                // from our script (which bundles the Sentry code), and we don't want that.

                // Remove wrapping for native APIs
                integrations = integrations.filter(integration => integration.name !== 'Breadcrumbs');

                // Remove wrapping for native time/event APIs (setTimeout etc.)
                integrations = integrations.filter(integration => integration.name !== 'TryCatch');

                return integrations;
            },
            beforeSend(event, hint) {

                if(Config.debugMode) {
                    Utils.clog(3, '=== beforeSend', event, hint);
                }

                // Here we do additional filtering due to whitelistUrls also allowing null URLs
                // to pass. We mimic the behaviour of getEventFilterUrl() and isWhitelistedUrl() in
                // sentry-javascript inboundfilters.ts file, but also disallow null URLs.
                // We also look at the last frame in order to get the most accurate filename,
                // since Sentry will wrap all user-land code and errors may appear to be from
                // our script (in node_modules/@sentry/browser/esm/helpers.js) when they're not.
                let urlToFilter;
                let isWhitelisted = false;
                try{
                    if (event.stacktrace) {
                        urlToFilter = event.stacktrace.frames.slice(-1)[0].filename;
                    } else if (event.exception) {
                        urlToFilter = event.exception.values[0].stacktrace.frames.slice(-1)[0].filename;
                    } else {
                        urlToFilter = null;
                    }
                } catch (e) {
                    urlToFilter = null;
                }

                if(typeof urlToFilter === 'string'){
                    isWhitelisted = whitelistRegexList.some(pattern => pattern.test(urlToFilter));
                }
                if(isWhitelisted){

                    //event.tags.custom_timestamp = Date.now();
                    event.tags.whitelisted_url = urlToFilter || '-';

                    return event;
                } else if (event.message !== undefined) {
                    
                    // do not block messages from being sent
                    return event;

                } else {
                    return null;
                }
            }
        });

        // Tag the merchant ID
        let script = $("#gointerpay_localize");
        let merchantId = script.attr(Constants.CONFIG_NAMES.MerchantId);

        if(merchantId) {

            Sentry.configureScope((scope) => {
                scope.setTag("merchant_id", merchantId);
            });

        }

    }

    /**
     * Check if we are blocking Google adbot crawlers
     *
     * @return {boolean}
     */
    static blockGoogleAdCrawlers() {
        let script = $("#gointerpay_localize");
        return script.attr(Constants.CONFIG_NAMES.BlockGACrawlers) === 'true'
            && (/Mediapartners-Google|AdsBot-Google-Mobile|AdsBot-Google-Mobile|AdsBot-Google/i.test(navigator.userAgent));
    }
}

// Export singleton
export let gipShopify = new GIPShopify();

/**
 * Main initialize
 */
export function initGIPShopify(){
    // check if in test mode
    Utils.loadGIPTest();

    // Init log level
    Utils.loadGIPLogLevel();

    // Disable all functionality if needed, useful for debugging.
    if(Utils.loadGIPDisable()){
        Utils.clog(3, "GIP disabled");
        return;
    }
    
    if(Utils.loadGIPDebug()){
        Utils.clog(3, "Debugging enabled");
    }

    // Disable the script if detect Google adbot crawlers
    if(GIPShopify.blockGoogleAdCrawlers()){
        Utils.clog(3, "GIP disabled for google adbot crawlers");
        return;
    }

    GIPShopify.initSentryErrorTracking();

    GIPShopify.loadLocalizeCSS();

    PriceScanner.preload();

    $(() => {

        Utils.clog(3, "Ready");

        gipShopify.init();

    });

}
