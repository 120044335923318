/**
 * @typedef {function} Deferred.async
 * @param {function} callback
 */

/**
 * @typedef {function} Deferred.set
 * @param {any} value
 * @returns {void}
 */

/**
 * Helper function to reduce concurrent calls and collision
 * Intended for a one-time execution, where expected value is awaited using the async function
 * If the value has not been resolved, add callback to que, and execute when resolved
 * If the callback is resolved, execute immediately.
 * Unlike a Promise, this is intended to allow multiple events to trigger uppon an event resolving
 *     chaining to an existing async call without initiating multiple instances.
 * @returns {Object} Deferred
 * @returns {Deferred.async} Deferred.async
 * @returns {Deferred.set} Deferred.set
 */
export const Deferred = function() {
    const self = {};
    self.callbacks = [];
    self.value = null;
    self.resolved = false;

    return {
        /**
         * assign a listener, or if resolved, run callback with stored value
         */
        async: callback => {
            if (self.resolved) {
                callback(self.value);
            }
            self.callbacks.push(callback);
        },
        /**
         * set value, and execute queued callbacks
         */
        set: value => {
            self.resolved = true;
            self.value = value;
            while (self.callbacks.length > 0) {
                self.callbacks.shift()(self.value);
            }
        },
    };
};
