import { h, Fragment } from "preact";
import { PaymentMethodReachC } from "./PaymentMethodReachC";
import { PaymentMethodOtherC } from "./PaymentMethodOtherC";
import { CreditCardC } from "./CreditCardC";
import { getOtherPaymentMethods } from "./appInternal";

/**
 * Handles the content of payment methods
 * @param {*} props
 */
export const PaymentMethodsC = (props) => {
    /** @type {Store} */
    const store = props.store;

    const paymentMethods = getOtherPaymentMethods();
    if (!paymentMethods) return null;

    const methods = paymentMethods.map((pm) => {
        return (
            <PaymentMethodOtherC
                store={store}
                paymentMethod={pm}
            ></PaymentMethodOtherC>
        );
    });

    return (
        <Fragment>
            <CreditCardC store={store}></CreditCardC>
            <PaymentMethodReachC store={store}></PaymentMethodReachC>
            {methods}
        </Fragment>
    );
};
