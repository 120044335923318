import { h, Fragment } from "preact";
import { useState, useEffect } from "preact/hooks";

import { PaymentMethodsC } from "./PaymentMethodsC";
import { AdditionalDataC } from "./AdditionalDataC";
import { SpinnerC } from "./SpinnerC";
import { BadgeC } from "./BadgeC";
import { ErrorC } from "./ErrorC";
import {
    extListener,
    sendCardsToIframe,
    initIframeLoadTimeout,
    initHeightUpdate,
} from "./appInternal";
import { EVENT_TYPES, PAYMENT_METHOD_CLASS } from "./index";
import { Config } from "../config";

const uninitialized = () => {
    throw "CheckoutApp has not initialized";
};

export const setters = {
    setValidationErrors: uninitialized,
    setErrorMessage: uninitialized,
    setPaymentMethods: uninitialized,
    setPaymentMethodId: uninitialized,
    setCards: uninitialized,
    setCardType: uninitialized,
    setCountry: uninitialized,
    setCountryData: uninitialized,
    setIssuerId: uninitialized,
    setShowProgress: uninitialized,
    setIsVisible: uninitialized,
    setIframeReady: uninitialized,
};

/** @type {Store} */
export const store = {};

export const AppC = () => {
    [store.validationErrors, setters.setValidationErrors] = useState({});
    [store.errorMessage, setters.setErrorMessage] = useState(null);
    [store.paymentMethods, setters.setPaymentMethods] = useState(null);
    [store.paymentMethodId, setters.setPaymentMethodId] = useState([]);
    [store.cards, setters.setCards] = useState([]);
    [store.cardType, setters.setCardType] = useState(null);
    [store.country, setters.setCountry] = useState(null);
    [store.countryData, setters.setCountryData] = useState([]);
    [store.issuerId, setters.setIssuerId] = useState(null);
    [store.showProgress, setters.setShowProgress] = useState(true);
    [store.isVisible, setters.setIsVisible] = useState(true);
    [store.iframeReady, setters.setIframeReady] = useState(false);

    // notify via callback when payment method changes
    useEffect(() => {
        if (Config.debugMode) window.GIP_CHECKOUT_APP = store;
        extListener(EVENT_TYPES.PAYMANT_METHOD_CHANGED, store.paymentMethodId);

        if (store.paymentMethodsId === PAYMENT_METHOD_CLASS.CARD) {
            // force a height update when user selects reach card pay method
            initHeightUpdate();
        }
    }, [store.paymentMethodId]);

    useEffect(sendCardsToIframe, [store.paymentMethods]);
    useEffect(initIframeLoadTimeout, []);

    const showApp = store.isVisible && !store.showProgress;
    const showLoader = store.isVisible && store.showProgress;

    const appStyle = !showApp ? "display:none;" : "display: block;";

    const errors = store.errorMessage && (
        <ErrorC message={store.errorMessage}></ErrorC>
    );

    return (
        <Fragment>
            {errors}
            <div style={appStyle}>
                <fieldset class="content-box">
                    <legend class="visually-hidden">
                        Choose a payment method
                    </legend>
                    <PaymentMethodsC store={store} />
                </fieldset>
                <BadgeC />
                <AdditionalDataC />
            </div>
            <SpinnerC show={showLoader} />
        </Fragment>
    );
};
