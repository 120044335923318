/**
 * Handles placement of klarna script and placement
 * tags in a product page, and updates price as needed
 */
 import { Config } from "./config";
 import * as Utils from "./utils";
 import { priceCache } from "./price-cache";
 import { METHODS_PATH, KLARNA_PAYMENT_METHODS, SHOPIFY_SELECTORS } from "./constants";
 import { GIPAPI } from "./gip-api";
 import $ from "jquery";
 
 /**
  * Notes
  *
  *   Klarna Race Condition
  * The klarna lib allows for a race condition that causes the messaging not to show up on
  * product page. This happens if we send two price updates with little time between them
  * (ie, price=0 then price=1000). If they resolve FIFO, then all is good, but in some cases
  * they've come back in a different order, so the library registers a price of 1000, but
  * will not render the messaging because the server last responded with a 0 which prevents
  * it from rendering. The same can be true of sending two different prices.
  */
 
 /**
  * @type {Promise<null>|null} initPromise
  */
 let initPromise = null;
 
 class NoKlarnaPayment extends Error {
     constructor(message) {
         super(message);
         this.name = "NoKlarnaPayment";
     }
 }
 
 /**
  * hold internal state
  */
 const state = {
     /**
      * @type {Element} The <klarna-placement> element
      *
      * We're assuming that there is only one on the page, we've added it,
      * and the element that we are adding to will not be removed
      */
     klarnaPlacmentElement: null,
 
     /**
      * cached price of product.
      * Is stored to prevent triggering price update on klarna unecessarily
      */
     cachedPrice: null,
     cachedLocale: null,
 
     paymentMethodData: null,
 
     /**
      * klrarna messaging may be supported for localized and non-localized stores.
      * Use flag to determine whether to get prices from DOM or use the localized prices
      */
     useLocalizedPrices: null,
 };
 
 /**
  * promise wrapper for GIPAPI mehod
  * @return {Promise<boolean>}
  */
 const isHandledByReach = async () => {
     return new Promise((resolve) => {
         GIPAPI.isHandledByReach().async(() => {
             // isKlarnaMessagingSupported determines whether the store is processed by reach
             // getPayments is still needed to tell if Klarana is an activated method on the store
             resolve(Config.isKlarnaMessagingSupported);
         });
     });
 };
 
 const getCountry = async () => {
     return new Promise((resolve, reject) => {
         // Load shipping country if previously saved
         Utils.loadCacheShippingCountry();
 
         if (Utils.getCountry(false) !== null || Config.localizeData !== null) {
             resolve(Utils.getCountry());
         }
     });
 };
 
 /**
  * Make Ajax request to getPaymentMethods and determine based on result whether the klarna
  * payment method is supported.
  *
  * @param {String} Country
  * @returns {Promise<PaymentMethod>}
  * @throws {NoKlarnaPayment|Error}
  */
 const getKlarnaPaymentMethod = async (Country) => {
     if (state.paymentMethodData) return state.paymentMethodData;
 
     const payload = {
         MerchantId: Config.merchantId,
         Country: Country,
         Currency: Config.selectedCurrency || Config.storeCurrency,
         Locale: Utils.getHtmlLang(),
     };
     try {
         const response = await Utils.ajaxGet(Config.apiHost + METHODS_PATH, payload);
         const { PaymentMethods } = JSON.parse(response);
 
         // get first payment method that has a supported klarna payment menthod id
         const firstMatch =
             Array.isArray(PaymentMethods) &&
             PaymentMethods.find((method) =>
                 KLARNA_PAYMENT_METHODS.find((klarnaId) => method.Id === klarnaId)
             );
 
         if (!firstMatch) {
             Utils.clog(4, "Klarna - No Klarna in payment methods");
             throw new NoKlarnaPayment();
         }
 
         if (firstMatch && !firstMatch.Parameters) {
             Utils.clog(4, "Klarna - No Parameters object found in Klarna payment menthod");
             throw new NoKlarnaPayment();
         }
 
         state.paymentMethodData = firstMatch;
         return firstMatch;
     } catch (error) {
         if (error instanceof NoKlarnaPayment) {
             // let NoKlarnaPayment bubble up
             throw error;
         } else {
             throw new Error("Klarna - failed to load getPaymentMethods API");
         }
     }
 };
 
 /**
  * returns the klarnaPlacement element, creates new one if it doesn't exist.
  * The method will not create a new element if the price is null to prevent the
  * klarna script from entering race condition. (see note👆 re. race condition)
  *
  * @param {Number} price
  * @returns {Promise<Element|null>} returns the klarnaPlacement element
  */
 const getKlarnaMessagingElement = async (price) => {
     if (state.klarnaPlacmentElement) return state.klarnaPlacmentElement;
 
     if (!price) {
         return null;
     }
 
     return await createKlarnaMessagingElement(price);
 };
 
 /**
  * Creates the klarana messaging element
  *
  * @param {Number} price
  * @param {Element} anchorElement element to attach
  * @returns {Promise<Element>} returns the generated klarnaPlacement element
  * @throws {Error} will throw errors via reject()
  */
 const createKlarnaMessagingElement = (price) => {
     return new Promise((resolve, reject) => {
         // additional error check 1
         if (!state.paymentMethodData) {
             reject(new Error("No Klarna payment menthod defined"));
         }
 
         // additional error check 2
         if (!state.paymentMethodData.Parameters) {
             reject(new Error("No Parameters available for klarna payment menthod"));
         }
 
         const dataLocale = Utils.getLocaleCode();
         const anchorElement = document.querySelector(Config.klarnaAnchorProduct);
         const { ClientId, OnSiteJavaScriptUrl } = state.paymentMethodData.Parameters;
 
         // add klarna-placement tag
         const klarnaPlacement = document.createElement("klarna-placement");
 
         klarnaPlacement.dataset.purchaseAmount = Math.round(price * 100);
         klarnaPlacement.dataset.key = Config.klarnaPlacementKey;
         klarnaPlacement.dataset.locale = dataLocale;
 
         if (Config.klarnaPlacementTheme)
             klarnaPlacement.dataset.theme = Config.klarnaPlacementTheme;
 
         anchorElement.parentNode.insertBefore(klarnaPlacement, anchorElement);
 
         // load klarna
         const script = document.createElement("script");
 
         script.src = OnSiteJavaScriptUrl;
         script.dataset.clientId = ClientId;
 
         document.head.appendChild(script);
         script.addEventListener("load", () => {
             Utils.clog(4, "Klarna - Script is loaded");
             state.klarnaPlacmentElement = klarnaPlacement;
             resolve(state.klarnaPlacmentElement);
         });
         script.addEventListener("error", () => {
             reject(new Error("Klarna - Script could not be loaded"));
         });
     });
 };
 
 /**
  * get the price
  * method does not handle its own errors
  * @param {boolean} [logError] defaults to true
  * @returns {number|void}
  */
 const getPrice = (logError = true) => {
     if (state.useLocalizedPrices) {
         // get the converted price
         return getConvertedPrice(logError);
     }
     // else, parse price from dom element
     const priceEl = $("[data-product-id]");
     if (!priceEl) return null;
     return Utils.getNumericPrice(priceEl);
 };
 
 /**
  * get the converted price
  * method does not handle its own errors
  * @param {boolean} [logError] defaults to true
  * @returns {number|void}
  */
 const getConvertedPrice = (logError = true) => {
     let gipElement = null;
 
     const selector = SHOPIFY_SELECTORS.productPrice;
     const productPriceElement = document.querySelector(selector);
 
     if (productPriceElement) {
         gipElement = productPriceElement.querySelector("[data-gip-price-id]");
     }
 
     if (gipElement !== null) {
         const priceId = gipElement.dataset.gipPriceId;
         const priceRow = priceCache.getPriceRow(priceId);
 
         if (Utils.isNumber(priceRow.convertedPrice)) {
             // if a converted price is available, use it
             return priceRow.convertedPrice;
         }
 
         if (Config.isStoreCurrency && Config.processStoreCurrency && Config.isActiveCurrency) {
             // if the domestic currency is not being converted, but is processed
             // by reach, use the (unconverted) merchant price
             return priceRow.merchantPrice;
         }
 
         // this may fire a few times before the price is available
         logError && Utils.clog(5, "Klarna - No converted price avaialble");
     }
 
     return null;
 };
 
 /**
  * Update the price in klarana-placement element
  * @param {Number} price
  */
 const updateKlarnaPlacement = async () => {
     let price = getPrice(true);
     let locale = Utils.getLocaleCode();
 
     // check price and locale against cache
     if (price === state.cachedPrice && locale === state.cachedLocale) {
         return;
     }
 
     state.cachedPrice = price;
     state.cachedLocale = locale;
 
     const klarnaPlacmentElement = await getKlarnaMessagingElement(price).catch((error) => {
         Utils.clog(4, "Klarna - " + error.message);
         return;
     });
 
     if (klarnaPlacmentElement === null) {
         Utils.clog(4, "Klarna - Placement element is not available");
     } else if (klarnaPlacmentElement.offsetParent === null) {
         Utils.clog(2, "Klarna - Placement element was removed from DOM");
     } else {
         if (klarnaPlacmentElement.dataset.purchaseAmount !== Math.round(price * 100)) {
             Utils.clog(4, "Klarna - Updated price: " + price);
         }
         if (klarnaPlacmentElement.dataset.locale !== locale) {
             Utils.clog(4, "Klarna - Updated locale: " + locale);
         }
         // multiply by 100, since klarna expects price to be in cents
         klarnaPlacmentElement.dataset.purchaseAmount = Math.round(price * 100);
         klarnaPlacmentElement.dataset.locale = locale;
         window.KlarnaOnsiteService = window.KlarnaOnsiteService || [];
         window.KlarnaOnsiteService.push({ eventName: "refresh-placements" });
     }
 };
 
 // create a debounced version to reduce changes when dom is changing
 // and reduces likeliness of race condition with klarna price changes not resolving FIFO
 const updateKlarnaDebounced = Utils.debounce(updateKlarnaPlacement, 500);
 
 /**
  * Watch the price element for changes
  * uses MutationObserver (supported by IE11, so should be safe)
  * @param {Element} klarnaPlacement the element to update on change in price
  */
 const watchPrice = () => {
     Utils.clog(4, "Klarna - Watching for product price changes");
 
     const observerCallback = () => {
         try {
             if (!state.klarnaPlacmentElement) {
                 // if there is no klarnaPlacmentElement, don't bother checking price
                 // the placement element is created asynchronously, and it may have not been generated yet
                 return;
             }
             updateKlarnaDebounced();
         } catch (err) {
             Utils.clog(0, err);
         }
     };
 
     const observerConfig = { attributes: true, childList: true, subtree: true };
     const observer = new MutationObserver(observerCallback);
     // MutationObserver can't tell when a parent is removed, so let's watch document for changes
     observer.observe(document, observerConfig);
 
     if (getPrice(false) === null) {
         const interval = setInterval(() => {
             if (getPrice(false) !== null) {
                 clearInterval(interval);
                 updateKlarnaDebounced();
             }
         }, 1000);
     }
 };
 
 /**
  * process klarna messaging
  * @returns {Promise<null>}
  */
 export const processKlarna = async () => {
     // find element on product page
     let anchorElement;
     try {
         const { klarnaAnchorProduct, flcOnly } = Config;
 
         // check all the sync requirements before making any api calls
         // disable for FLC
         if (flcOnly === true) {
             // exit silently
             throw new NoKlarnaPayment();
         }
 
         // only available on product page
         if (!Utils.isProductPage()) {
             // exit silently
             throw new NoKlarnaPayment();
         }
 
         // only available on product page if anchor element is defined
         if (!klarnaAnchorProduct) {
             Utils.clog(4, "Klarna - Anchor element not defined");
             // klarna is not needed, exit gracefully
             throw new NoKlarnaPayment();
         }
 
         anchorElement = document.querySelector(klarnaAnchorProduct);
         if (!anchorElement) {
             // anchor element defined, but not found - log warning and exit klarna handling
             Utils.clog(0, 'Klarna - Cannot find anchor element "' + klarnaAnchorProduct + '"');
             throw new NoKlarnaPayment();
         }
 
         const selector = SHOPIFY_SELECTORS.productPrice;
         const productPriceElement = document.querySelector(selector);
 
         if (!productPriceElement) {
             // product price element is not found - log warning and exit klarna handling
             Utils.clog(0, "Klarna - Cannot find product price element");
             throw new NoKlarnaPayment();
         }
 
         // We're on a product page with a valid klarna selector. Let's start making API calls
         await isHandledByReach();
 
         if (!Config.isKlarnaMessagingSupported) {
             Utils.clog(0, "Klarna - Currency is not processed by Reach");
             throw new NoKlarnaPayment();
         }
 
         // now we know that klarna should be processed by reach
 
         // check if the currecy is being localized
         // we're only checking for isStoreCurrency, since other variables have been accounted for
         state.useLocalizedPrices = Config.isStoreCurrency;
 
         if (state.useLocalizedPrices) {
             Utils.clog(4, "Klarna - Process messaging with localized currency");
         } else {
             Utils.clog(4, "Klarna - Process messaging with store currency");
         }
 
         let country = await getCountry();
 
         // after country is determined, check if klarna is supported for current country
         // initial payment method load
         let paymentMethod = await getKlarnaPaymentMethod(country);
         if (paymentMethod === undefined) throw new NoKlarnaPayment();
 
         // initial price update
         updateKlarnaPlacement();
 
         // start active watch
         watchPrice();
     } catch (error) {
         if (error instanceof NoKlarnaPayment) {
             // do nothing
             Utils.clog(4, "Klarna - No Klarna messaging");
         } else {
             throw error;
         }
     }
 };
 
 /**
  * Promissified singleton around klarna handler method
  * will let the function be run only once
  * @returns {Promise<null>}
  */
 export const initKlarna = () => {
     if (initPromise === null) {
         // initialize only once
         initPromise = processKlarna(initPromise).catch((error) => {
             // catch all unhandled errors by logging to console.error
             Utils.clog(0, error.message);
             // do not throw any error since rest of localize.js doesn't care
         });
     }
     return initPromise;
 };