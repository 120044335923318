import { h } from "preact";
import { CARD_TYPES, PAYMENT_TYPES, setPaymentMethodId } from "./index";
import { CardImagesC } from "./CardImagesC";
import { Config } from "../config";

export const CreditCardC = (props) => {
    /** @type {Store} */
    const store = props.store;
    const numCards = store.cards.length;

    // don't show if there are no cards
    if (numCards === 0) return null;

    const setPaymentMethod = () => setPaymentMethodId(PAYMENT_TYPES.CREDIT);

    const cardIds = store.cards.map((card) => card.Id);
    const maxCards = 4;
    const excludeCards = [CARD_TYPES.DINERS, CARD_TYPES.DISCOVERY];

    // template
    return (
        <div class="radio-wrapper content-box__row">
            <div class="gip_payment-input radio__input">
                <input
                    class="input-radio"
                    id="gip_custom_payment_method_CREDIT"
                    type="radio"
                    onChange={setPaymentMethod}
                    value={Config.gipGatewayId}
                    checked={store.paymentMethodId === PAYMENT_TYPES.CREDIT}
                    name="checkout[payment_gateway]"
                ></input>
            </div>
            <div class="radio__label content-box__emphasis payment-method-wrapper">
                <label
                    class="gip_payment-label radio__label__primary"
                    for="gip_custom_payment_method_CREDIT"
                >
                    Credit card
                </label>
                <CardImagesC
                    cardIds={cardIds}
                    maxCards={maxCards}
                    excludeCards={excludeCards}
                ></CardImagesC>
            </div>
        </div>
    );
};
