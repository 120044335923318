import {Config} from "./config";
import * as Utils from "./utils";
import {priceCache} from "./price-cache";
import * as Constants from "./constants";
import $ from "jquery";
import { gipShopify } from "./gip-shopify";
import * as Sentry from "@sentry/browser";

export class PriceScanner {

    /**
     * Run first to hide all prices
     *
     * Used to address the price flicker issue.
     * We create css rules for the element and an additional rule with the
     * 'data-gip-converted' attribute set to 'true'. This attribute will be
     * added when the price gets converted.
     *
     * We should cover the initial load of prices, but other scripts that change the
     * price will not be covered, as there would be too many possibilities.
     */
    static preload(){
        Utils.clog(3, 'Preload');

        let element = document.createElement('style');

        Config.preloadStyleEle = element;

        document.head.appendChild(element);
        let sheet = element.sheet;

        let styleCounter = 0;

        // Add hide css for elements that will always be hidden
        function addCSS(cssString){
            sheet.insertRule(cssString, styleCounter++);
        }

        let hideSelectorString = '.gip-convert-hide {opacity: 0 !important;}';
        addCSS(hideSelectorString);
        let displayNoneSelectorString = '.gip-convert-display-none {display: none !important;}';
        addCSS(displayNoneSelectorString);

        // Add hide/show css for auto-convert selectors
        let selectorString = Constants.CONVERT_SELECTORS.join(',');
        let convertedSelector = Constants.CONVERT_SELECTORS.map(str => str + '[data-gip-converted="true"]');
        let convertedSelectorString = convertedSelector.join(',');

        function addHideShowCSSHelper(selectorString, convertedSelectorString){
            // Hide css
            let styles = selectorString + ' {opacity:0;}';
            sheet.insertRule(styles, styleCounter++);

            // Show css
            styles = convertedSelectorString + ' {opacity:1;}';
            sheet.insertRule(styles, styleCounter++);
        }

        addHideShowCSSHelper(selectorString, convertedSelectorString);

        // Add hide/show css for line items
        let lineItemSelectors = [
            'tr.product > td.product__price > span',                        // Line item amount
            'tr.product > td.product__price > del',                         // Line item original amount
            'tr.product > .product__description > ul li.reduction-code',    // Line item discount lines
        ];

        // Additional selectors
        let additionalSelectors = [

            // We will always hide the default shipping method prices because we need to parse them
            // before converting.
            Constants.SHOPIFY_SELECTORS.checkoutShippingMethods,            // Shipping methods
            Constants.SHOPIFY_SELECTORS.checkoutDiscountedShippingMethods,  // Discounted shipping methods

        ];

        // Additional selectors for checkout
        let checkoutTotalSelectors = [

            Constants.SHOPIFY_SELECTORS.checkoutSubtotal,                   // Subtotal
            Constants.SHOPIFY_SELECTORS.checkoutDiscountsAndGiftCards,      // Discounts and giftcards
            Constants.SHOPIFY_SELECTORS.checkoutDiscountsMobile,            // Discounts mobile
            '.gip-extra-discount-display',                                  // Discounts extra display
            Constants.SHOPIFY_SELECTORS.checkoutDuties,                     // Duties
            Constants.SHOPIFY_SELECTORS.checkoutTax,                        // Taxes
            Constants.SHOPIFY_SELECTORS.checkoutTotal,                      // Total
            Constants.SHOPIFY_SELECTORS.checkoutTotalCurrency,              // Total currency
            Constants.SHOPIFY_SELECTORS.checkoutTaxInPriceLine,             // Taxes included in price line
            Constants.SHOPIFY_SELECTORS.checkoutTaxInPrice,                 // Taxes included in price

            // Single product price script discounts
            '.gip-single-discount',
        ];

        Config.checkoutTotalClasses = checkoutTotalSelectors.slice(); // Save a copy

        // Cart related
        let cartSelectors = [
            '.gip-cart-line-item-price-display',                // non-discounted unit price
            '.gip-cart-line-item-discounted-price-display',     // discounted unit price
            '.gip-cart-line-item-final-price-display',          // final unit price

            '.gip-cart-line-item-total',                        // non-discounted line item total
            '.gip-cart-discounted-line-item-total',             // discounted line item total
            '.gip-cart-final-line-item-total',                  // final line item total

            '.gip-cart-subtotal',                               // non-discounted cart subtotal
            '.gip-cart-discounted-subtotal',                    // discounted cart subtotal
            '.gip-cart-total-discounts',                        // total discount amount
            '.gip-cart-additional-fee > span.money',            // additional fees
            '.gip-cart-total',                                  // cart total
        ];

        // Secondary cart related
        let secondaryCartSelectors = cartSelectors.slice();
        secondaryCartSelectors = secondaryCartSelectors.map(ele => ele.replace('gip-cart', 'gip-secondary-cart'));

        // Extra cart related
        let extraCartSelectors = cartSelectors.slice();
        extraCartSelectors = extraCartSelectors.map(ele => ele.replace('gip-cart', 'gip-extra-cart'));

        // Extra2 cart related
        let extra2CartSelectors = cartSelectors.slice();
        extra2CartSelectors = extra2CartSelectors.map(ele => ele.replace('gip-cart', 'gip-extra2-cart'));

        let allCartSelectors = cartSelectors.concat(secondaryCartSelectors, extraCartSelectors, extra2CartSelectors);

        // Save a copy, without the '*-additional-fee' classes
        Config.preloadCartClasses = allCartSelectors.slice()
            .filter(_class => _class.indexOf('-additional-fee') === -1);

        // Add hide/show css for shopify selectors
        let allAdditionalSelectors = lineItemSelectors.concat(additionalSelectors, checkoutTotalSelectors, allCartSelectors);
        selectorString = allAdditionalSelectors.join(',');
        convertedSelector = allAdditionalSelectors.map(str => str + '[data-gip-converted="true"]');
        convertedSelectorString = convertedSelector.join(',');

        addHideShowCSSHelper(selectorString, convertedSelectorString);
    }

    /**
     * Reset checkout totals
     *
     * Used to reset and hide the totals when shipping options are selected. We need this because
     * Shopify's own scripts modify the totals and we don't want to show those unconverted values.
     */
    static shippingResetCheckoutTotals(){

        //Utils.clog(3, '!!! RESET ALL CHECKOUT ELEMENTS !!!');

        if(!PriceScanner.callCounter){
            PriceScanner.callCounter = 0;
        }

        let elementsToReset = Config.checkoutTotalClasses;

        for (let i = 0; i < elementsToReset.length; i++) {
            const elementToReset = $(elementsToReset[i]);

            // Skip certain checkout elements when running for the first time to avoid double converting.
            if(PriceScanner.callCounter === 0
                && (
                    elementsToReset[i] === Constants.SHOPIFY_SELECTORS.checkoutTax
                    || elementsToReset[i] === Constants.SHOPIFY_SELECTORS.checkoutTaxInPrice
                )
            ){
                continue;
            }

            PriceScanner.resetElement(elementToReset);
        }

        PriceScanner.callCounter++;

        //Utils.clog(3, PriceScanner.callCounter);
    }

    /**
     * Reset cart totals
     *
     * Used to reset/hide the cart totals when cart parameters change. This is used when there are
     * custom scripts that modify the totals on an ajax cart or similar, and we don't want to show
     * those unconverted values.
     */
    static resetCartTotals(){

        //Utils.clog(3, '!!! RESET ALL CART ELEMENTS !!!');

        let elementsToReset = Config.preloadCartClasses;

        for (let i = 0; i < elementsToReset.length; i++) {
            const elementToReset = $(elementsToReset[i]);

            PriceScanner.resetElement(elementToReset);
        }

    }

    /**
     * Scans the page for unconverted prices
     */
    scan() {

        // If currency changed, set all prices to unconverted
        if (Config.selectedCurrency !== priceCache.priceCurrency) {
            //Utils.clog(3, '!!! CHANGED CURRENCY !!!');
            $('[data-gip-converted="true"]').attr('data-gip-converted', 'false');
        }
        priceCache.changeCurrency(Config.selectedCurrency);

        //Utils.clog(3, 'Config.selectedCurrency');
        //Utils.clog(3, Config.selectedCurrency);

        let newPriceIDs = [];

        // Loop over prices
        let selector = Constants.CONVERT_SELECTORS.join(',');
        let list = $(selector);

        // Sentry testing
        let maxPricesToTrack = 10;
        let priceTrackCounter = 0;
        let priceTrackArray = [];
        let minPriceAmount = 1000000;

        for (let c = 0; c < list.length; c++) {
            const item = $(list[c]);

            //Utils.clog(3, item);

            let productID = null;
            let variantID = null;
            let merchantPrice = null;

            let displayed = item.html().trim();

            // Regular prices
            if (item.is('span.money')) {

                // Skip converted ones
                if (item.attr('data-gip-converted') === 'true') {

                    //Utils.clog(3, 'Found converted');

                    // Some themes change the prices themselves and need to be checked.
                    // If found to be changed, reset the element and price data so the
                    // next poll can convert it.

                    let changesFound = false;

                    let productPriceID = item.attr('data-gip-price-id');
                    item.addClass('notranslate');

                    let productPriceRow = priceCache.getPriceRow(productPriceID);

                    if (productPriceRow) {
                        // create an element and handle through jQuery for a similar result
                        let newDisplayPrice = $('<span/>').html(Utils.getDisplayedPrice(productPriceRow.convertedPrice)).html().trim();
                        
                        if (item.html().trim() !== newDisplayPrice) {
                            PriceScanner.resetElement(item);
                            changesFound = true;
                        }
                    }

                    if(!changesFound){
                        continue;
                    }
                }

                productID = item.parent().attr('data-product-id');

                // Special handling for product page, as the data-variant-id
                // can be changed when the user selects different variants.
                if(Utils.isProductPage()){

                    let variantSelect = $('select[data-gip-product-variant-select]');
                    if(variantSelect.length === 1){
                        let variantSelectProductID = variantSelect.attr('data-gip-product-variant-select');
                        let variantID = item.parent().attr('data-variant-id');

                        // Check that the variant product ID matches
                        if(variantSelectProductID && variantSelectProductID === productID
                            && (
                                // And either the variant ID exists and is not tagged with ':' such as ':compare'
                                // or no variant ID exists – which could happen if a disabled variant was selected and
                                // cleared the variant ID.
                                (variantID && variantID.indexOf(':') === -1)
                                || !variantID
                            )
                        ){

                            let variantSelectVariantID = variantSelect.val();

                            //Utils.clog(3, "Changing variant ID from ", variantID, " to ", variantSelectVariantID);

                            item.parent().attr('data-variant-id', variantSelectVariantID);
                            Utils.clog(3, "Found variant ID, changed to " + variantSelectVariantID);
                        }
                    }

                }

                variantID = item.parent().attr('data-variant-id');

                merchantPrice = Utils.getNumericPrice(item);
            }

            // Checkout shipping methods
            else if (item.is(Constants.SHOPIFY_SELECTORS.checkoutShippingMethods + '.gip-shipping-price-processed')
                || item.is(Constants.SHOPIFY_SELECTORS.checkoutDiscountedShippingMethods + '.gip-shipping-price-processed')) {

                // Skip converted ones
                if (item.attr('data-gip-converted') === 'true') {
                    continue;
                }

                // Skip 'Free' ones
                if (displayed === 'Free'){
                    continue;
                }

                productID = null;
                variantID = 'noround';

                const radioEl = item.parents('.content-box__row').find('.input-radio');
                if (radioEl.length === 1 && radioEl.attr('gip-shipping-value')) {
                    // use gip-shipping-value from flc/dtc if available
                    merchantPrice = radioEl.attr('gip-shipping-value');
                }
                else if (radioEl.length === 1 && radioEl.attr('data-checkout-total-shipping-cents')) {
                    merchantPrice = radioEl.attr('data-checkout-total-shipping-cents') / 100;
                } else {
                    merchantPrice = Utils.getNumericPrice(item);
                }
            }

            // Checkout shipping
            // Checkout duties
            else if (item.is(Constants.SHOPIFY_SELECTORS.checkoutShipping)
                || item.is(Constants.SHOPIFY_SELECTORS.checkoutDuties)) {

                // Skip if not shipping page
                if(!Utils.isShippingPage()){
                    continue;
                }

                // Skip converted ones
                if (item.attr('data-gip-converted') === 'true') {
                    continue;
                }

                // Skip 'Free' shipping
                if (item.is(Constants.SHOPIFY_SELECTORS.checkoutShipping)
                    && displayed === 'Free'){
                    continue;
                }

                productID = null;
                variantID = 'noround';

                merchantPrice = Utils.getNumericPrice(item);
            }

            // Checkout taxes
            // Checkout taxes included in prices
            else if (item.is(Constants.SHOPIFY_SELECTORS.checkoutTax)
                || item.is(Constants.SHOPIFY_SELECTORS.checkoutTaxInPrice)) {

                // Skip if not custom info page or shipping page
                if(!Utils.isCustomerInfoPage() && !Utils.isShippingPage()){
                    continue;
                }

                // Skip converted ones
                if (item.attr('data-gip-converted') === 'true') {
                    continue;
                }

                productID = null;
                variantID = 'noround';

                try {

                    // try to parse data-checkout-total-taxes-target
                    merchantPrice = item.data().checkoutTotalTaxesTarget / 100;
                    
                } catch (error) {
                    
                    // fallback to text content if price not available or not parseable
                    merchantPrice = Utils.getNumericPrice(item);

                }
                
            }

            else {
                // Not an element in Constants.CONVERT_SELECTORS, skipping.
            }

            // Round merchantPrice
            if(Utils.isZeroDecimalCurrency(Config.storeCurrency)){
                merchantPrice = Math.round(merchantPrice);
            } else {
                merchantPrice = Utils.round(merchantPrice);
            }

            // Try retrieving the existing price ID if it exists.
            // It can be reused for elements that don't have product/variant IDs.
            let existingPriceID = item.attr('data-gip-price-id');
            
            let priceRow = priceCache.insertPriceRow(productID, variantID, existingPriceID, merchantPrice);
            
            // If converted successfully, add tags
            if (priceRow) {
                
                item.attr('data-gip-price-id', priceRow.priceID);
                item.attr('data-gip-original-value', merchantPrice);
                item.attr('data-gip-converted', 'false');
                item.addClass('notranslate');

                newPriceIDs.push(priceRow.priceID);

            }

            // Sentry tracking
            if(!Config.disableSentry && merchantPrice > minPriceAmount && priceTrackCounter < maxPricesToTrack){
                priceTrackArray.push({
                    displayed,
                    merchantPrice,
                    priceRow: !!priceRow,
                    productID,
                    variantID,
                });
                priceTrackCounter++;
            }

        }

        if(!Config.disableSentry && priceTrackArray.length > 0) {

            Sentry.addBreadcrumb({
                category: 'price-scanner--scan',
                message: 'large price exception in price scanner',
                data: {
                    maxPricesToTrack,
                    minPriceAmount,
                    priceTrackCounter,
                    priceTrackArray: JSON.stringify(priceTrackArray, (k, v) => v === undefined ? null : v),
                },
                level: 'debug'
            });
            Sentry.captureMessage('Large price exception');
        }

        //Utils.clog(3, 'Scanned! ' + Date.now() + ' ' + Config.storeCurrency);

        return newPriceIDs;
    }

    /**
     * Reset the element by removing all price tracking data
     */
    static resetElement(ele) {

        let priceID = ele.attr('data-gip-price-id');

        if(priceID) {
            //priceCache.clearPrice(priceID);
        }

        ele.removeAttr('data-gip-price-id data-gip-original-value data-gip-converted');

    }

    /**
     * Update all the new prices on the page
     */
    updatePrices(newPriceIDs) {

        //Utils.clog(3, 'Update prices');
        //Utils.clog(3, newPriceIDs);

        for (let i = 0; i < newPriceIDs.length; i++) {
            const newPriceID = newPriceIDs[i];

            let priceRow = priceCache.getPriceRow(newPriceID);
            if (priceRow) {

                //Utils.clog(3, priceRow);

                let item = $("[data-gip-price-id='" + priceRow.priceID + "']");

                let newDisplay = Utils.getDisplayedPrice(priceRow.convertedPrice);

                item.html(newDisplay);

                item.attr('data-gip-converted', 'true');
                item.addClass('notranslate');
            } else {
                Utils.clog(3, 'Price row not found');
            }
        }

        this.postProcess();

        //Utils.clog(3, 'Updated prices!');
    }

    /**
     * Post processing
     *
     * A fair amount of post processing will need to be done for prices
     * not associated with products, such as subtotals, line totals etc.
     */
    postProcess() {

        //Utils.clog(3, 'postProcess');

        if (Utils.isCheckoutPage()) {

            PriceScanner.processCheckout();
        }

        // Process cart if available
        if(Utils.isCartPage()) {

            this.processCart();

        }

        // Process secondary cart if available
        if($('.gip-secondary-cart').length === 1){

            this.processCart('secondary');

        }

        // Process extra cart if available
        if($('.gip-extra-cart').length === 1){

            this.processCart('extra');

        }

        // Process extra cart2 if available
        if($('.gip-extra2-cart').length === 1){

            this.processCart('extra2');

        }

        // Process single price script discount
        // Used for a product price that is permanently discounted via Shopify Scripts at a fixed rate.
        let singlePriceDiscountEles = $('.gip-single-discount');
        if(singlePriceDiscountEles.length > 0){

            for (let i = 0; i < singlePriceDiscountEles.length; i++) {
                const singlePriceDiscountEle = $(singlePriceDiscountEles[i]);

                //Utils.clog(3, 'found .gip-single-discount');

                let convertedPriceEle = singlePriceDiscountEle.siblings('.gip-single-discount-price').find('.money[data-gip-converted="true"]');
                if(convertedPriceEle.length === 1){

                    //Utils.clog(3, 'found .gip-single-discount sibling price');

                    let priceID = convertedPriceEle.attr('data-gip-price-id');
                    convertedPriceEle.addClass('notranslate');

                    let priceRow = priceCache.getPriceRow(priceID);
                    if (priceRow) {

                        //Utils.clog(3, priceRow);

                        let discountTitle = singlePriceDiscountEle.attr('data-gip-single-discount-title');

                        let {match, title, percentValue, flatOriginal, flatValue} = PriceScanner.parseShopifyScriptDiscountTitle(discountTitle);

                        if(match){

                            let discountedPrice = null;
                            if(percentValue){
                                discountedPrice = Utils.round(priceRow.convertedPrice * Utils.round((100 - percentValue) / 100));
                            } else {
                                discountedPrice = Utils.round(priceRow.convertedPrice - flatValue);
                            }

                            let newDisplay = Utils.getDisplayedPrice(discountedPrice);

                            singlePriceDiscountEle.html(newDisplay);
                            singlePriceDiscountEle.attr('data-gip-converted', 'true');
                            singlePriceDiscountEle.addClass('notranslate');
                        }

                    }

                }
            }

        }
    }

    /**
     * Process checkout values
     */
    static processCheckout(){

        // Don't process until checkoutData is ready.
        if(!Config.checkoutData || Config.checkoutData.lineItems.length === 0){
            return;
        }

        // Don't process if stuff is rendering, such as the shipping/duties section
        // Only display if shipping/duty not rendering
        if(gipShopify.checkout.renderShippingAndDutyLock === 1) {
            Utils.clog(3, 'Process checkout render lock');
            return;
        }

        // Calculate line totals
        let checkoutItemLines = $('tr.product');
        for (let i = 0; i < checkoutItemLines.length; i++) {
            const itemLine = $(checkoutItemLines[i]);

            let itemLineTotal = itemLine.find('td.product__price > span:nth-of-type(1)');

            let itemLineVariantID = itemLine.attr('data-variant-id');

            // If the data is matches
            if(Config.checkoutData.lineItems[i] && Config.checkoutData.lineItems[i].variant_id === itemLineVariantID){
                let itemLineData = Config.checkoutData.lineItems[i];

                let lineItem = {
                    price: itemLineData.consumer_price,                 // Converted price
                    lineQty: itemLineData.quantity,
                    lineTotal: itemLineData.consumer_subtotal,          // Converted line total
                    discountedLineTotal: itemLineData.consumer_total,   // SSD discounted converted line total
                    finalLineTotal: itemLineData.consumer_total         // Final line total to use
                };

                // Display line totals
                let originalItemLineTotal = itemLineTotal.siblings('del');
                if(originalItemLineTotal.length === 1){

                    // It there is a strike-through price, it means a script discount or an automatic discount is active.

                    // Display original line total
                    originalItemLineTotal.html(Utils.getDisplayedPrice(lineItem.lineTotal, Config.checkoutData.currency));
                    originalItemLineTotal.attr('data-gip-converted', 'true');
                    originalItemLineTotal.addClass('notranslate');

                    // Display discounted line total
                    itemLineTotal.html(Utils.getDisplayedPrice(lineItem.discountedLineTotal, Config.checkoutData.currency));
                    itemLineTotal.attr('data-gip-converted', 'true');
                    itemLineTotal.addClass('notranslate');

                } else {

                    // No discounts.

                    // Display line total
                    itemLineTotal.html(Utils.getDisplayedPrice(lineItem.lineTotal, Config.checkoutData.currency));
                    itemLineTotal.attr('data-gip-converted', 'true');
                    itemLineTotal.addClass('notranslate');

                }

                // Display line discounts
                // Shopify will display script discount and automatic discount amounts on the line item,
                // so they will need to be converted.
                let itemLineDiscountEles = itemLine.find('.product__description > ul li.reduction-code').not('[data-gip-line-item-discount-parsed="true"]');
                if(itemLineDiscountEles.length > 0){
                    for (let j = 0; j < itemLineDiscountEles.length; j++) {
                        const itemLineDiscountEle = $(itemLineDiscountEles[j]);

                        let itemLineDiscountTextEle = itemLineDiscountEle.find('span.reduction-code__text');

                        let discountText = itemLineDiscountTextEle.text().trim();
                        //Utils.clog(3, discountText);

                        // Separate description from amount
                        let match = discountText.match(/\([^()]*\)/g);
                        let discountAmt = match[match.length - 1];
                        let discountDesc = discountText.replace(discountAmt, '').trim();
                        //Utils.clog(3, discountDesc);

                        // Find matching discount
                        for (let k = 0; k < itemLineData.discounts.length; k++) {
                            const discount = itemLineData.discounts[k];

                            // Match title or description.
                            // For line discounts, title is present for regular buy X get Y discounts,
                            // while description is present for automatic discounts and script discounts.
                            let discountTitleOrDescription = null;
                            if(typeof discount.description === 'string'){
                                discountTitleOrDescription = discount.description.toUpperCase();
                            } else if(typeof discount.title === 'string'){
                                discountTitleOrDescription = discount.title.toUpperCase();
                            }

                            if(discountTitleOrDescription !== null && discountTitleOrDescription === discountDesc){

                                itemLineDiscountTextEle.text(discountDesc + ' (-' + Utils.getDisplayedPrice(discount.consumer_amount, Config.checkoutData.currency) + ')');
                                itemLineDiscountEle.attr('data-gip-line-item-discount-parsed', 'true');
                                itemLineDiscountEle.attr('data-gip-converted', 'true');
                                itemLineDiscountEle.addClass('notranslate');
                                break;
                            }
                        }
                    }
                }

            } else {

                // Stop processing checkout, data is out of sync.
                // Wait until new data is pulled before trying again.
                return;
            }

        }

        // Display subtotal
        let newSubtotalDisplay = Utils.getDisplayedPrice(Config.checkoutData.subtotal, Config.checkoutData.currency);
        let subtotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutSubtotal);

        if(subtotalEle.length === 1) {
            subtotalEle.html(newSubtotalDisplay);
            subtotalEle.attr('data-gip-converted', 'true');
            subtotalEle.addClass('notranslate');
        }

        // Display discounts
        let discountsTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutDiscounts);

        // Temp backwards compat
        if(discountsTotalEle.length === 0){
            discountsTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutDiscountsOld);
        }

        let smallDiscountsEle = $(Constants.SHOPIFY_SELECTORS.checkoutDiscountsMobile);
        let extraDiscountsEle = $('.gip-extra-discount-display');
        if(Config.checkoutData.discount.amount){

            let discountDisplay = Utils.getDisplayedPrice(Config.checkoutData.discount.amount, Config.checkoutData.currency);

            // Show price
            discountsTotalEle.html(discountDisplay);
            smallDiscountsEle.html(discountDisplay);

            // Show discount amount in custom elements (elements will not be hidden pre-conversion)
            extraDiscountsEle.html(discountDisplay);
        } else {
            // Show original text – could be 'Free shipping'
        }
        discountsTotalEle.attr('data-gip-converted', 'true');
        discountsTotalEle.addClass('notranslate');
        smallDiscountsEle.attr('data-gip-converted', 'true');
        smallDiscountsEle.addClass('notranslate');
        extraDiscountsEle.attr('data-gip-converted', 'true');
        extraDiscountsEle.addClass('notranslate');

        // Show 'Free' shipping options since they are not converted normally
        let shippingRows = $(Constants.SHOPIFY_SELECTORS.checkoutShippingMethods + '.gip-shipping-price-processed'
            + ', ' + Constants.SHOPIFY_SELECTORS.checkoutDiscountedShippingMethods + '.gip-shipping-price-processed');
        for (let i = 0; i < shippingRows.length; i++) {
            const shippingRow = $(shippingRows[i]);
            if(shippingRow.html().trim() === 'Free'){
                shippingRow.attr('data-gip-converted', 'true');
                shippingRow.addClass('notranslate');
            }
        }

        // Calculate shipping
        // Get from already converted price
        let shippingTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutShipping);
        let shippingPriceRow = priceCache.getPriceRow(shippingTotalEle.attr('data-gip-price-id'));

        if (shippingPriceRow && Config.checkoutData.shipping.converted === null) {
            // Save the priceCache shipping if converted normally
            Config.checkoutData.shipping.converted = shippingPriceRow.convertedPrice;
            //Utils.clog(3, 'Got shipping value from priceCache', shippingPriceRow.convertedPrice);
        }

        // Calculate and display duties if available
        // Get from already converted price
        if(Config.checkoutData.shipping.gipShippingPresent){

            let dutiesTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutDuties);
            let dutiesPriceRow = priceCache.getPriceRow(dutiesTotalEle.attr('data-gip-price-id'));

            if (dutiesPriceRow && Config.checkoutData.shipping.dutiesConverted === null) {
                // Save the priceCache duties if converted normally
                Config.checkoutData.shipping.dutiesConverted = dutiesPriceRow.convertedPrice;
                //Utils.clog(3, 'Got duties value from priceCache', dutiesPriceRow.convertedPrice);
            }

            // Check the combined converted value to see if we are off by a small margin.
            // If so, adjust the dutiesConverted to accommodate this.
            let combinedShippingEle = $('#combined-shipping-check');
            let combinedConvertedPrice = Utils.getNumericPrice(combinedShippingEle);
            if(Utils.isNumber(combinedConvertedPrice)){

                //Utils.clog(3, 'Checking for converted shipping delta');

                let dutiesDelta = combinedConvertedPrice - Config.checkoutData.shipping.converted - Config.checkoutData.shipping.dutiesConverted;
                let maxDelta;
                if(Utils.isZeroDecimalCurrency(Config.selectedCurrency)){
                    dutiesDelta = Math.round(dutiesDelta);
                    maxDelta = 3;
                } else {

                    // Round to 2 decimals
                    dutiesDelta = Utils.round(dutiesDelta);
                    maxDelta = 0.03;
                }

                //Utils.clog(3, `Duties delta: ${dutiesDelta}`);

                if(Math.abs(dutiesDelta) > 0 && Math.abs(dutiesDelta) <= maxDelta){

                    Config.checkoutData.shipping.dutiesConverted += dutiesDelta;
                    //Utils.clog(3, `Adjusted converted duties to match converted combined value: ${dutiesDelta}`);

                } else {
                    //Utils.clog(3, `Converted shipping delta not within range: ${maxDelta}, no adjustment needed`);
                }
            }

            // Only re-display if marked as converted
            if(dutiesTotalEle.attr('data-gip-converted') === 'true') {
                dutiesTotalEle.html(Utils.getDisplayedPrice(Config.checkoutData.shipping.dutiesConverted, Config.checkoutData.currency));
            }
        }

        // Display shipping
        if(shippingTotalEle.length === 1){

            // Special handling for 'Free' or '–' or 'Calculated at next step' shipping
            // If either, set the element to converted so that the price will be
            // displayed. This is required because 'Free' or '–' or 'Calculated at next step' doesn't get converted
            // the normal way and will not be displayed otherwise.
            if(shippingTotalEle.html().trim() === 'Free' || shippingTotalEle.text().trim() === '—Not yet available' || shippingTotalEle.text().trim() === 'Calculated at next step') {
                shippingTotalEle.attr('data-gip-converted', 'true');
                shippingTotalEle.addClass('notranslate');
            }

            // Display the converted amount
            else {

                // Only re-display if marked as converted
                if(shippingTotalEle.attr('data-gip-converted') === 'true') {
                    shippingTotalEle.html(Utils.getDisplayedPrice(Config.checkoutData.shipping.converted, Config.checkoutData.currency));
                }
            }
        }

        // Display converted shipping in summary
        if($(".review-block__content[gip-shipping-summary-converted='true']").length === 0) {
            let blocks = $(".review-block__content");
            for (let i = 0; i < blocks.length; i++) {
                const block = $(blocks[i]);
                let content = block.html();
                
                // Match and replace the first regex Capturing Group
                // Only replace if a price value is shown, ignore 'Free' etc.
                let match = content.match(/[\s\S]*·[\s]*(<strong class="emphasis">(\s*<span class="skeleton-while-loading--inline">\s*)?([^<>]*)(\s*<\/span>\s*)?<\/strong>)/);
                if (match) {

                    if (Config.checkoutData.shipping.summaryPrice === null) {

                        // Break early to prevent further processing if the converted price
                        // is not available yet.
                        if(Config.checkoutData.shipping.converted === null){
                            break;
                        }

                        Config.checkoutData.shipping.summaryPrice = Config.checkoutData.shipping.converted;
                    }
                    let replaceWith = Config.checkoutData.shipping.summaryPrice > 0 ? Utils.getDisplayedPrice(Config.checkoutData.shipping.summaryPrice, Config.checkoutData.currency) : 'Free';
                    replaceWith = ` <strong class="emphasis" id="gip-shipping-summary-price">${replaceWith}</strong>`;
                    block.html(content.replace(match[1], replaceWith));
                    block.attr('gip-shipping-summary-converted', 'true'); // Only parse once
                    break;
                }
            }
        } else {

            // After parsing once, just keep displaying the amount
            let shippingSummaryPriceEle = $('#gip-shipping-summary-price');
            if(shippingSummaryPriceEle.length === 1){
                let replaceWith = Config.checkoutData.shipping.summaryPrice > 0 ? Utils.getDisplayedPrice(Config.checkoutData.shipping.summaryPrice, Config.checkoutData.currency) : 'Free';
                shippingSummaryPriceEle.html(replaceWith);
            }
        }

        // Calculate taxes
        // Get from already converted price in the summary pane.
        let taxesTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutTax);
        let taxesPriceRow = priceCache.getPriceRow(taxesTotalEle.attr('data-gip-price-id'));

        if(Utils.isCustomerInfoPage() || Utils.isShippingPage()){
            if(taxesPriceRow){
                Config.checkoutData.taxes.original = taxesPriceRow.merchantPrice;
                Config.checkoutData.taxes.converted = taxesPriceRow.convertedPrice;
            }
            if(taxesTotalEle.length === 1) {
                taxesTotalEle.html(Utils.getDisplayedPrice(Config.checkoutData.taxes.converted, Config.checkoutData.currency));
            }

        } else if(Utils.isPaymentMethodPage() || Utils.isThankYouPage() || Utils.isOrderSummaryPage()){
            if(taxesTotalEle.length === 1) {
                taxesTotalEle.html(Utils.getDisplayedPrice(Config.checkoutData.taxes.converted, Config.checkoutData.currency));
                taxesTotalEle.attr('data-gip-converted', 'true');
                taxesTotalEle.addClass('notranslate');
            }
        }

        // Calculate taxes included in price
        let taxesInPriceLineEle = $(Constants.SHOPIFY_SELECTORS.checkoutTaxInPriceLine);
        let taxesInPriceEle = $(Constants.SHOPIFY_SELECTORS.checkoutTaxInPrice);
        let taxesInPricePriceRow = priceCache.getPriceRow(taxesInPriceEle.attr('data-gip-price-id'));

        if(Utils.isCustomerInfoPage() || Utils.isShippingPage()){
            if(taxesInPricePriceRow){
                Config.checkoutData.taxes.converted_included = taxesInPricePriceRow.convertedPrice;
            }
            if(taxesInPriceLineEle.length === 1) {
                taxesInPriceLineEle.attr('data-gip-converted', 'true'); // Show line
                taxesInPriceLineEle.addClass('notranslate');
            }
        } else if(Utils.isPaymentMethodPage() || Utils.isThankYouPage() || Utils.isOrderSummaryPage()){
            if(taxesInPriceLineEle.length === 1 && taxesInPriceEle.length === 1) {
                taxesInPriceEle.html(Utils.getDisplayedPrice(Config.checkoutData.taxes.converted_included, Config.checkoutData.currency));
                taxesInPriceLineEle.attr('data-gip-converted', 'true'); // Show line
                taxesInPriceLineEle.addClass('notranslate');
                taxesInPriceEle.attr('data-gip-converted', 'true');
                taxesInPriceEle.addClass('notranslate');
            }
        }

        // Calculate gift cards
        let giftCardElements = $(Constants.SHOPIFY_SELECTORS.checkoutGiftCards);
        if(giftCardElements.length > 0){

            for (let i = 0; i < giftCardElements.length; i++) {
                const giftCardEle = $(giftCardElements[i]);

                let giftCardText = giftCardEle.find('span.reduction-code__text span:first-child').html();

                //Utils.clog(3, giftCardText);

                let match;
                if((match = giftCardText.match(/•••• (.{4})/))){

                    //Utils.clog(3, 'match!!!', match);

                    let code = match[1].toUpperCase();
                    let matchingCard = Config.checkoutData.giftCards.cards.find((card) => card.last_characters === code);
                    if(matchingCard !== undefined) {

                        //Utils.clog(3, 'matching card!!!', matchingCard);

                        let amountEle = giftCardEle.find('td.total-line__price > span:first-child');
                        amountEle.html(Utils.getDisplayedPrice(matchingCard.consumer_amount, Config.checkoutData.currency));
                        amountEle.attr('data-gip-converted', 'true');
                        amountEle.addClass('notranslate');

                        // Display in the payment method gift card section if on the thank-you page
                        if(Utils.isThankYouPage() || Utils.isOrderSummaryPage()){

                            $('.gip-ty-payment-method-gift-card-' + matchingCard.last_characters).html(Utils.getDisplayedPrice(Math.abs(matchingCard.consumer_amount), Config.checkoutData.currency));

                        }
                    }
                }
            }
        }

        // Calculate total
        let total;

        total = Config.checkoutData.subtotal
            + Config.checkoutData.discount.amount
            + Config.checkoutData.shipping.converted
            + Config.checkoutData.shipping.dutiesConverted
            + Config.checkoutData.taxes.converted
            + Config.checkoutData.giftCards.totalAmount;

        let totalEle = $(Constants.SHOPIFY_SELECTORS.checkoutTotal);
        let totalCurrencyEle = $(Constants.SHOPIFY_SELECTORS.checkoutTotalCurrency);

        if (Config.checkoutData.total.original === null) {
            // Only save original once
            Config.checkoutData.total.original = Utils.getNumericPrice(totalEle);
        }

        Config.checkoutData.total.calculated = total;

        let newTotalDisplay = Utils.getDisplayedPrice(total, true, Config.checkoutData.currency);
        totalEle.html(newTotalDisplay);
        totalCurrencyEle.html(Config.checkoutData.currency);

        totalEle.attr('data-gip-converted', 'true');
        totalEle.addClass('notranslate');
        totalCurrencyEle.attr('data-gip-converted', 'true');
        totalCurrencyEle.addClass('notranslate');

        // Calculate small screen totals
        let smallOriginalPriceEle = $('.total-recap__original-price');
        let smallFinalPriceEle = $('.total-recap__final-price');

        smallOriginalPriceEle.html(Utils.getDisplayedPrice(Config.checkoutData.total.calculated - Config.checkoutData.discount.amount - Config.checkoutData.lineItemDiscountTotal, Config.checkoutData.currency));
        smallFinalPriceEle.html(Utils.getDisplayedPrice(Config.checkoutData.total.calculated, Config.checkoutData.currency));

        // Display total in the payment method line in thank-you page
        $('.gip-ty-payment-method-amount').html(Utils.getDisplayedPrice(total, Config.checkoutData.currency));

        //Utils.clog(3, Config.checkoutData);

    }

    /**
     * Process cart values
     *
     * @param cartId The name for the cart. Valid values are: 'secondary' | 'extra' | 'extra2'.
     *               If empty, will default to the base cart class.
     */
    processCart(cartId){

        //Utils.clog(3, 'Process cart');

        let baseCartClass = cartId ? 'gip-' + cartId + '-cart' : 'gip-cart';

        let baseCartId = cartId ? cartId + 'CartData' : 'cartData';

        // Init new cart objects
        if(Config[baseCartId] === undefined){

            // Cart calculated prices
            Config[baseCartId] = {
                lineItems: [],                  // Details in postProcess()
                subtotal: null,                 // Subtotal of converted prices
                totalDiscountedAmount: null,    // Total SSD discounted converted amount

                // Subtotal of Shopify Script Discount (SSD) discounted converted prices
                // = subtotal - totalDiscountedAmount
                discountedSubtotal: null,

                additionalFees: [],             // Array of any additional fees

                // Cart total
                // = discountedSubtotal + additionalFees
                total: null,
            }
        }

        let cartDataObj = Config[baseCartId];

        let subtotal = 0.0;
        let totalDiscountedAmount = 0.0;

        let lineItems = [];

        // Calculate line totals
        let cartItemLines = $('.' + baseCartClass + ' .' + baseCartClass + '-line-item');
        for (let i = 0; i < cartItemLines.length; i++) {
            const itemLine = $(cartItemLines[i]);
            itemLine.addClass('notranslate');

            //Utils.clog(3, 'start line item loop');

            let linePriceEle = itemLine.find('.' + baseCartClass + '-line-item-price span.money');
            let linePrice = Utils.getNumericPrice(linePriceEle);
            let lineCompareAtPriceEle = itemLine.find('.' + baseCartClass + '-line-item-compare-at-price span.money');
            let lineCompareAtPrice = Utils.getNumericPrice(lineCompareAtPriceEle);
            let lineQtyEle = itemLine.find('.' + baseCartClass + '-line-item-qty');
            let lineQty = lineQtyEle.is('input') ? lineQtyEle.val() : lineQtyEle.html();
            lineQty = parseInt(lineQty, 10);

            let lineTotal = linePrice * lineQty;
            lineTotal = Utils.round(lineTotal);

            let lineCompareAtTotal = lineCompareAtPrice * lineQty;
            lineCompareAtTotal = Utils.round(lineCompareAtTotal);

            subtotal += lineTotal;
            subtotal = Utils.round(subtotal);

            let lineItem = {
                price: linePrice,                   // Converted price
                compareAtPrice: lineCompareAtPrice, // Converted compare atprice
                lineQty: lineQty,
                lineTotal: lineTotal,               // Converted line total
                lineCompareAtTotal: lineCompareAtTotal, // Converted line compare at total

                // Shopify Script Discount (SSD) – related values

                originalPrice: null,                // Original price
                originalDiscountedPrice: null,      // Original SSD discounted price
                discountPercent: null,              // Estimated SSD discount percent based on originalPrice and originalDiscountedPrice

                discountedPrice: null,              // SSD discounted converted price
                discountedLineTotal: null,          // SSD discounted converted line total

                discountAmount: null,               // SSD discount amount
                lineDiscountAmount: null,           // SSD line discount amount

                finalPrice: linePrice,              // Final price to use
                finalLineTotal: lineTotal           // Final line total to use
            };

            // Process SSD values if available
            let lineItemMetaEle = itemLine.find('.' + baseCartClass + '-line-item-meta');
            if (lineItemMetaEle.length === 1){

                let lineItemOriginalPrice = (parseInt(lineItemMetaEle.attr('data-original-price-cents'), 10) || 0) / 100;
                let lineItemDiscountedPrice = (parseInt(lineItemMetaEle.attr('data-discounted-price-cents'), 10) || 0) / 100;

                // Check for valid values. Discounted price can be >= 0.
                if(lineItemOriginalPrice > 0 && lineItemDiscountedPrice >= 0){

                    // Calculate discount
                    lineItem.originalPrice = lineItemOriginalPrice;
                    lineItem.originalDiscountedPrice = lineItemDiscountedPrice;

                    // Get the most accurate discount rate (keep decimals)
                    lineItem.discountPercent = lineItemDiscountedPrice / lineItemOriginalPrice;

                    // The discountPercent will be able to support:
                    // - script discounts
                    // - normal discounts
                    // - automatic discounts

                    // Calculate discounted price
                    let discountedPrice = lineItem.price * lineItem.discountPercent;
                    lineItem.discountedPrice = Utils.round(discountedPrice);

                    // Calculate line total
                    lineItem.discountedLineTotal = Utils.round(lineItem.discountedPrice * lineItem.lineQty);

                    // Calculate discount amount
                    lineItem.discountAmount = Utils.round(lineItem.price - lineItem.discountedPrice);

                    // Calculate line discount amount
                    lineItem.lineDiscountAmount = Utils.round(lineItem.lineTotal - lineItem.discountedLineTotal);

                    // Calculate total discount
                    totalDiscountedAmount += lineItem.lineTotal - lineItem.discountedLineTotal;
                    totalDiscountedAmount = Utils.round(totalDiscountedAmount);

                    lineItem.finalPrice = lineItem.discountedPrice;
                    lineItem.finalLineTotal = lineItem.discountedLineTotal;
                }
            }

            lineItems.push(lineItem);

            // Display non-discounted line price
            let linePriceDisplayEle = itemLine.find('.' + baseCartClass + '-line-item-price-display');
            linePriceDisplayEle.html(Utils.getDisplayedPrice(lineItem.price));
            linePriceDisplayEle.attr('data-gip-converted', 'true');
            linePriceDisplayEle.addClass('notranslate');

            // Display line compare at price
            let lineCompareAtPriceDisplayEle = itemLine.find('.' + baseCartClass + '-line-item-compare-at-price-display');
            lineCompareAtPriceDisplayEle.html(Utils.getDisplayedPrice(lineItem.compareAtPrice));
            lineCompareAtPriceDisplayEle.attr('data-gip-converted', 'true');
            lineCompareAtPriceDisplayEle.addClass('notranslate');

            // Display discounted line price
            let lineDiscountedPriceDisplayEle = itemLine.find('.' + baseCartClass + '-line-item-discounted-price-display');
            lineDiscountedPriceDisplayEle.html(Utils.getDisplayedPrice(lineItem.discountedPrice));
            lineDiscountedPriceDisplayEle.attr('data-gip-converted', 'true');
            lineDiscountedPriceDisplayEle.addClass('notranslate');

            // Display final line price
            let lineFinalPriceDisplayEle = itemLine.find('.' + baseCartClass + '-line-item-final-price-display');
            lineFinalPriceDisplayEle.html(Utils.getDisplayedPrice(lineItem.finalPrice));
            lineFinalPriceDisplayEle.attr('data-gip-converted', 'true');
            lineFinalPriceDisplayEle.addClass('notranslate');

            /* --- */

            // Display non-discounted line item total
            let lineTotalEle = itemLine.find('.' + baseCartClass + '-line-item-total');
            lineTotalEle.html(Utils.getDisplayedPrice(lineItem.lineTotal));
            lineTotalEle.attr('data-gip-converted', 'true');
            lineTotalEle.addClass('notranslate');

            // Display non-discounted line item compare at total
            let lineCompareAtTotalEle = itemLine.find('.' + baseCartClass + '-line-item-compare-at-total');
            lineCompareAtTotalEle.html(Utils.getDisplayedPrice(lineItem.lineCompareAtTotal));
            lineCompareAtTotalEle.attr('data-gip-converted', 'true');
            lineCompareAtTotalEle.addClass('notranslate');

            // Display discounted line item total
            let lineDiscountedTotalEle = itemLine.find('.' + baseCartClass + '-discounted-line-item-total');
            lineDiscountedTotalEle.html(Utils.getDisplayedPrice(lineItem.discountedLineTotal));
            lineDiscountedTotalEle.attr('data-gip-converted', 'true');
            lineDiscountedTotalEle.addClass('notranslate');

            // Display discount amount
            let discountAmountEle = itemLine.find('.' + baseCartClass + '-discount-amount');
            discountAmountEle.html(Utils.getDisplayedPrice(lineItem.discountAmount));
            discountAmountEle.attr('data-gip-converted', 'true');
            discountAmountEle.addClass('notranslate');

            // Display line discount amount
            let lineDiscountAmountEle = itemLine.find('.' + baseCartClass + '-line-discount-amount');
            lineDiscountAmountEle.html(Utils.getDisplayedPrice(lineItem.lineDiscountAmount));
            lineDiscountAmountEle.attr('data-gip-converted', 'true');
            lineDiscountAmountEle.addClass('notranslate');

            // Display final line item total
            let lineFinalTotalEle = itemLine.find('.' + baseCartClass + '-final-line-item-total');
            lineFinalTotalEle.html(Utils.getDisplayedPrice(lineItem.finalLineTotal));
            lineFinalTotalEle.attr('data-gip-converted', 'true');
            lineFinalTotalEle.addClass('notranslate');

        }

        //Utils.clog(3, subtotal);

        // Calculate subtotal
        cartDataObj.lineItems = lineItems;
        cartDataObj.subtotal = subtotal;
        cartDataObj.totalDiscountedAmount = totalDiscountedAmount;
        cartDataObj.discountedSubtotal = Utils.round(cartDataObj.subtotal - cartDataObj.totalDiscountedAmount);

        // Display subtotal
        let subtotalEle = $('.' + baseCartClass + '-subtotal');
        subtotalEle.html(Utils.getDisplayedPrice(cartDataObj.subtotal));
        subtotalEle.attr('data-gip-converted', 'true');
        subtotalEle.addClass('notranslate');

        // Display discounted subtotal
        let discountedSubtotalEle = $('.' + baseCartClass + '-discounted-subtotal');
        discountedSubtotalEle.html(Utils.getDisplayedPrice(cartDataObj.discountedSubtotal));
        discountedSubtotalEle.attr('data-gip-converted', 'true');
        discountedSubtotalEle.addClass('notranslate');

        // Display total discount amount
        let totalDiscountedEle = $('.' + baseCartClass + '-total-discounts');
        totalDiscountedEle.html(Utils.getDisplayedPrice(totalDiscountedAmount));
        totalDiscountedEle.attr('data-gip-converted', 'true');
        totalDiscountedEle.addClass('notranslate');

        // Calculate cart total
        let additionalFees = [];
        let additionalFeeEles = $('.' + baseCartClass + '-additional-fee > span.money');
        if(additionalFeeEles.length > 0){
            for (let i = 0; i < additionalFeeEles.length; i++) {
                const additionalFeeEle = $(additionalFeeEles[i]);

                let additionalFee = Utils.getNumericPrice(additionalFeeEle);
                if(Utils.isNumber(additionalFee)){
                    additionalFees.push(Utils.round(parseFloat(additionalFee)));
                }
            }
            additionalFeeEles.attr('data-gip-converted', 'true');
            additionalFeeEles.addClass('notranslate');
        }
        cartDataObj.additionalFees = additionalFees;
        cartDataObj.total = Utils.round(cartDataObj.discountedSubtotal + cartDataObj.additionalFees.reduce((a, b) => a + b, 0));

        // Display cart total
        let totalEle = $('.' + baseCartClass + '-total');
        totalEle.html(Utils.getDisplayedPrice(cartDataObj.total));
        totalEle.attr('data-gip-converted', 'true');
        totalEle.addClass('notranslate');

        //Utils.clog(3, cartDataObj);

    }

    /**
     * Parse a Shopify Script discount message
     *
     * The discount value is retrieved by parsing the last numeric value in the string.
     * If the value ends with a '%', it will be treated as a percentage discount.
     * Otherwise it will be treated as a flat amount. The flat amount will be converted
     * before finding the difference from the converted original amount.
     *
     * Eg.  'Buy 3 for 10% off' = 10% off.
     *      'Buy 3 for $15 off' = 15 off. The 15 will be converted before being used.
     */
    static parseShopifyScriptDiscountTitle(discountTitle){

        let parsedDiscount = {
            match: false,
            title: discountTitle,
            percentValue: null,     // Percent used
            flatOriginal: null,     // Original flat amount
            flatValue: null,        // Converted flat amount
        };

        let match = discountTitle.match(/[\d]+%?/g);
        if(match){

            //Utils.clog(3, match);

            let lastMatch = match[match.length - 1];
            let lastMatchFlatOriginal = null;
            let lastMatchFlat = null;
            let lastMatchPercent = null;

            if(lastMatch.indexOf('%') !== -1){
                lastMatchPercent = Utils.round(parseFloat(lastMatch.replace('%', '')));
            } else {

                lastMatchFlatOriginal = Utils.round(parseFloat(lastMatch));

                lastMatchFlat = Utils.convert(lastMatchFlatOriginal);

            }

            parsedDiscount.match = true;
            parsedDiscount.percentValue = lastMatchPercent;
            parsedDiscount.flatOriginal = lastMatchFlatOriginal;
            parsedDiscount.flatValue = lastMatchFlat;

        }

        return parsedDiscount;

    }

}
