import { h } from "preact";
import { useEffect } from "preact/hooks";
import { callAndRenderBadge } from "../badge";

export const BadgeC = () => {
    useEffect(() => {
        callAndRenderBadge();
    }, []);

    return <div class="gip_badge-wrapper"></div>;
};
