/**
 * CheckoutApp is a preact app that handles the payment type selection
 * if user selects reach method, it acts as a intermediary between the
 * iframe and the localize script
 */

import { render, h } from "preact";
import { Promise } from "q";
import { Config } from "../config";
import * as Utils from "../utils";
import * as Sentry from "@sentry/browser";
import "./types";
import { AppC, setters, store } from "./AppC";
import {
    handleIframeMessage,
    initHeightUpdate,
    submitCredit,
    setListener,
    validateCountryData,
    validateIssuer,
} from "./appInternal";

export const CARD_TYPES = {
    DISCOVERY: "DISC",
    DINERS: "DINERS",
    VISA: "VISA",
    MC: "MC",
    AMEX: "AMEX",
};

export const PAYMENT_METHOD_CLASS = {
    CARD: "Card",
    ONLINE: "Online",
};

export const PAYMENT_TYPES = {
    CREDIT: "CREDIT", // the reach payment methods
    SHOPIFY: "_SHOPIFY_", // shopify payment methods
};

export const EVENT_TYPES = {
    PAYMANT_METHOD_CHANGED: "paymant-method-changed",
};

export const ERRORS = {
    VALIDATION_FAILED: "Field validation failed",
    FORM_NOT_READY: "Form not ready",
    INVALID_PAYMENT_METHOD: "Invalid payment method",
    NON_RECOVERABLE: "Non-recoverable exception",
};

let mounted = false;
export const isMounted = () => mounted;

/**
 * Entry function to mount preact app on provided element
 * @param {HTMLElement} el
 * @param {function(Error)} callback
 */
export const mount = function (el, callback) {
    if (callback) setListener(callback);

    render(<AppC />, el);

    window.addEventListener("message", handleIframeMessage);
    window.addEventListener("resize", initHeightUpdate);

    mounted = true;
    Utils.clog(4, "Checkout App mounted");
};

/**
 * @param {PaymentMethod[]} paymentMethods
 */

export const setPaymentMethods = (paymentMethods) => {
    if (setters === null) throw "call mount before interacting with class";
    setters.setPaymentMethods(paymentMethods);
};

/**
 * @param {string} paymentMethod
 */
export const setPaymentMethodId = (paymentMethod) => {
    if (setters === null) throw "call mount before interacting with class";
    setters.setPaymentMethodId(paymentMethod);
    Utils.savePreviousPaymentType(paymentMethod);
};

/**
 * @param {Card[]} paymentMethods
 */
export const setCards = (cards) => {
    if (setters === null) throw "call mount before interacting with class";
    setters.setCards(cards);
};

/**
 * @param {string} country
 */
export const setCountry = (country) => {
    if (setters === null) throw "call mount before interacting with class";
    setters.setCountry(country);
};

/**
 * @param {boolean} show
 */
export const setShowProgress = (show) => {
    if (setters === null) throw "call mount before interacting with class";
    setters.setShowProgress(show);
};

/**
 * @param {boolean} isVisible
 */
export const setIsVisible = (isVisible) => {
    if (setters === null) throw "call mount before interacting with class";
    setters.setIsVisible(isVisible);
};

/**
 * @returns {string|null}
 */
export const getPaymentMethodId = () => store.paymentMethodId;

/**
 * @param {boolean} [failSilently]
 * @returns {PaymentMethod|null}
 */
export const getPaymentMethod = (failSilently = false) => {
    const Id = store.paymentMethodId;

    if (store.paymentMethodId === PAYMENT_TYPES.CREDIT) {
        if (store.cardType === null) {
            // this could be missing for valid reasons, or due to error
            if (failSilently) return;
            Sentry.setExtra("store", JSON.stringify(store));
            Sentry.captureMessage("Credit card type is null");
            throw "can't match unkown card type to payment methods";
        }
        // get the payment method with the matching cardId
        return (
            store.paymentMethods.find((pm) => pm.Id === store.cardType) || null
        );
    }
    try {
        return store.paymentMethods.find((pm) => pm.Id === Id) || null;
    } catch (error) {
        return null;
    }
};

/**
 * handle submit payment call
 * @returns {Promise<StashData>} paymentMethod.Id
 * @throws {ERRORS.VALIDATION_FAILED}
 */
export const handleSubmitPayment = () => {
    Utils.clog(3, "Handle submit payment");
    console.log(3, "Handle submit payment");

    setters.setErrorMessage(null);

    return validateForm()
        .then(() => {
            if (store.paymentMethodId === PAYMENT_TYPES.CREDIT) {
                return submitCredit();
            }

            return;
        })
        .then(() => generateStashData())
        .catch((error) => {
            // do not fail silently, since checkout handles the error
            throw error;
        });
};

/**
 * Validate asynchronously (allow future possible change to make iframe validation)
 */
export const validateForm = () => {
    // clear errors
    return Promise((resolve) => {
        let validIssuer = validateIssuer();
        let validCountryData = validateCountryData();

        Utils.clog(4, "Issuer valid: " + validIssuer);
        Utils.clog(4, "Country data valid: " + validCountryData);

        if (validCountryData && validIssuer) {
            resolve();
        } else {
            throw ERRORS.VALIDATION_FAILED;
        }
    });
};

export const generateStashData = () => {
    try {
        const paymentMethod = getPaymentMethod();

        if (paymentMethod === null) {
            // null or unsupported payment method
            throw ERRORS.INVALID_PAYMENT_METHOD;
        }

        let country = Utils.getCountry();

        // Financing is not (and may never be) implemented
        let finData = {
            Instalments: null,
            Rate: null,
            Cost: null,
        };

        // get locale
        let locale = Utils.getLocaleCode();

        // Collect data to stash
        /** @type {StashData} */
        let stashData = {
            StashTime: Date.now(),
            CartId: Config.shopifyCartId,
            PaymentClass: paymentMethod.Class,
            PaymentMethod: paymentMethod.Id,
            ConsumerCurrency: Config.selectedCurrency,
            ConsumerCountry: country,
            DisplayedPrice: Config.checkoutData.total.calculated,
            RateOfferId: Config.localizeData
                ? Config.localizeData.RateOffer.Id
                : null,

            // The IsFFP flag will enforce the order to be processed in store currency.
            // However it is redundant because every time we want the order to be processed in store currency
            // we will always be sending the correct currency in ConsumerCurrency anyway.
            // Send anyway.
            IsFFP:
                Config.processStoreCurrency &&
                Config.selectedCurrency === Config.storeCurrency,

            FinanceInstalments: finData.Instalments,
            FinanceRate: finData.Rate,
            FinanceCost: finData.Cost,
            DeviceId: Config.gipFingerprint,
            ClientVersion: Config.clientVersion,
            DiscountItems: Config.checkoutData.discountItems.join(","),
            Locale: locale,
        };

        let countryData = store.countryData;

        // Add country data
        Object.keys(countryData).forEach((key) => {
            stashData[key] = countryData[key].value;
        });

        // Add issuer if available
        if (store.issuerId) stashData.Issuer = store.issuerId;

        return stashData;
    } catch (error) {
        Sentry.captureException(error, { tags: {errorType: "Generate Stash Data Fail"} } );
    }
};

/**
 * that red erro on top of the payment methods that looks like a shopify error
 * @param {string} message
 */
export const setErrorMessage = (message) => {
    setters.setErrorMessage(message);
};
