import $ from "jquery";
import * as Utils from "./utils";
import * as Constants from "./constants";
import * as CheckoutApp from "./checkoutApp";
import * as Sentry from '@sentry/browser';
import {Config} from "./config";
import {GIPAPI} from "./gip-api";
import {PriceScanner} from "./price-scanner";
import {gipShopify} from "./gip-shopify";

export class Checkout {

    constructor() {
        this.allPaymentMethods = [];
        this.enableProcessCheckoutPage = false;
        this.enableProcessShippingPage = false;
        this.enableProcessPaymentPage = false;
        this.processPaymentPagePending = false;
        this.submitPending = false;
        this.abortedPaymentProcessing = false;
        this.modifiedURLWithReachFlag = false;
        this.renderShippingAndDutyLock = 0; // 0/1 – Lock to prevent multiple threads updating the shipping/duty values/display at the same time
        this.enableProcessThankYouPage = false;

    }

    init() {

        this.initCheckoutPage();

        if (Utils.isCustomerInfoPage()) {

            this.initCustomerInfoPage();

        } else if (Utils.isShippingPage()) {

            this.initShippingPage();

        } else if (Utils.isPaymentMethodPage()) {

            this.initPaymentPage();

        } else if (Utils.isThankYouPage() || Utils.isOrderSummaryPage()) {

            this.initPostCheckoutPage();
        }

    }

    /**
     * Initialize any checkout page
     */
    initCheckoutPage() {

        Utils.clog(3, 'On checkout page');

        // Load shipping country if previously saved
        Utils.loadCacheShippingCountry();

        // Init checkout data
        Checkout.initCheckoutCartData();

        // Cleanup payment type if on any page other than payment page, since that
        // is the only page it is used.
        if(!Utils.isPaymentMethodPage()){
            Utils.clearPreviousPaymentType();
        }

        // Enable/disable various parts of checkout depending on if the page is
        // localizing or not.
        Config.localizeDataObservable.subscribe(data => {
            if (data.RateOffer) {

                Utils.clog(3, 'Resolved localizeData');

                // Only enable processing after localizing
                this.enableProcessCheckoutPage = true;
                this.enableProcessShippingPage = true;
                this.enableProcessPaymentPage = true;

            } else if (data === false) {

                Utils.clog(3, "Invalid RateOffer");

                // Only enable processing after localizing
                this.enableProcessCheckoutPage = true;
                this.enableProcessShippingPage = true;

                // Disabled country takes precedence
                if(Config.isDisabledCountry){
                    this.abortPaymentProcessing('Not processing disabled country');
                } else if(Config.processStoreCurrency){
                    Utils.clog(3, "Processing store currency orders");
                    this.enableProcessPaymentPage = true;
                } else {
                    this.abortPaymentProcessing('Not processing store currency orders');
                }
            }
        });

        // Trigger the observable if FLC only, as localize wont be called.
        if (Config.flcOnly) {
            Config.localizeDataObserver.next(false);
        }

        Config.monitorObservable.subscribe(() => {

            //Utils.clog(3, 'Processing checkout page');

            /**
             * Process GIP shipping method in summary if present.
             * Summary box is only available on shipping and payment steps.
             * Keep running while the #gip-shipping-summary-processed is not present.
             */
            let shippingSummaryEle = $('#gip-shipping-summary-processed');
            if ((Utils.isShippingPage() || Utils.isPaymentMethodPage())
                && shippingSummaryEle.length === 0) {

                // Parse GIP shipping name in summary
                let blocks = $(".review-block__content");
                for (let i = 0; i < blocks.length; i++) {
                    const block = $(blocks[i]);
                    let content = block.html();

                    // Match and replace name if a GIP shipping name ie. '[10.00|10.00]' is present.
                    let matches = content.match(/(.+) ([[\d.|]+])([\s]*·[\s]*.+)/);
                    if (matches) {

                        block.attr('id', 'gip-shipping-summary-processed'); // Adding this ID indicates the summary has been parsed
                        block.addClass('notranslate');

                        let rawValues = matches[2];
                        let parsedValues = rawValues.match(/[\d.]+/g);

                        let shippingValue = parsedValues[0];
                        let dutyValue = parsedValues[1];

                        block.attr('gip-shipping-value', shippingValue);
                        block.attr('gip-duty-value', dutyValue);

                        // Change value to include only shipping value
                        let shipping_display;
                        if(parseFloat(shippingValue) === 0){
                            shipping_display = 'Free'
                        } else {
                            shipping_display = Utils.getDisplayedPrice(parseFloat(shippingValue), Config.storeCurrency);
                        }
                        let newName = matches[1] + ' · <strong class="emphasis">' + shipping_display + '</strong>';
                        block.html(newName);

                        // Only set shipping amounts if converted amount is not yet available from /getCart data
                        if(Config.checkoutData.shipping.converted == null) {

                            Config.checkoutData.shipping.original = parseFloat(block.attr('gip-shipping-value'));
                            Config.checkoutData.shipping.converted = null;
                            Config.checkoutData.shipping.dutiesOriginal = parseFloat(block.attr('gip-duty-value'));
                            Config.checkoutData.shipping.dutiesConverted = null;

                            Config.checkoutData.shipping.gipShippingPresent = true;

                            if (matches[3].indexOf('Free') !== -1) {
                                Config.checkoutData.shipping.freeShipping = true;
                            }

                            //Utils.clog(3, matches[3]);
                            //Utils.clog(3, $.trim(matches[3]));
                        }

                        // We must always render again because Shopify might have reset the section
                        this.renderShippingAndDutyTotals('summary');

                        break;
                    }

                }

            }

            /**
             * Process right-hand panel checkout values.
             * Keep running while the #gip-product-table-processed is not present on the
             * tbody of the .product-table element, since this element and inner contents
             * are refreshed whenever Shopify recalculates anything ie. adding/removing
             * discount codes.
             */
            let productTableBodyEle = $('.product-table > tbody#gip-product-table-processed');
            if (this.enableProcessCheckoutPage
                && productTableBodyEle.length === 0) {

                Utils.clog(3, 'Process product table');
                $('.product-table > tbody').attr('id', 'gip-product-table-processed');

                // Get discount items to pass to stash
                this.processDiscountItems();

                // All pages before thank-you page will get data from getCart
                // This will only be called for localized users.
                if(!Utils.isOrderSummaryPage()
                    && !Utils.isThankYouPage()) {

                    if(!Config.flcOnly && !gipShopify.convertAborted()) {

                        GIPAPI.getCheckoutCart(data => {

                            //Utils.clog(3, data);

                            this.cleanCheckoutCartData(data);

                            this.modifyURL();

                        });
                    }

                    // Else if not converting but still processing store currency
                    else if(!this.abortedPaymentProcessing) {

                        this.modifyURL();

                    }
                }

                // Thank-you and order summary page will get data from getOrder
                // Data format should be the same.
                else if (!Config.flcOnly && 
                    ( Utils.isOrderSummaryPage() || Utils.isThankYouPage() )) {

                    GIPAPI.getOrderSummaryData(data => {

                        //Utils.clog(3, data);

                        this.cleanCheckoutCartData(data);

                        this.modifyURL();

                        this.enableProcessThankYouPage = true;

                    });
                }
            }

            // Process any thank-you and order summary page functions
            // in the monitor loop.
            if(this.enableProcessThankYouPage){

                PriceScanner.processCheckout();

            }

        });
    }

    /**
     * Modify the URL when Reach is processing payments, meaning either localizing or
     * if not, processing store currency.
     */
    modifyURL(){

        // Only modify once
        if(!this.modifiedURLWithReachFlag) {
            
            // disable for FLC
            if (Config.flcOnly === true) return;

            GIPAPI.isHandledByReach().async(() => {
                const showBadge = Config.isBadgeRequired;
                this.modifiedURLWithReachFlag = true;
                Utils.clog(4, `${showBadge ? "Modify URL" : "Do not modify URL"}`);
                if(!showBadge) return;

                GIPAPI.badge(badge => {
                    // Modify URL with display param
                    let newURL = Utils.addURLParameter('SoldByREACHonbehalfof' + badge.MerchantName.replace(/ /g, ''));

                    window.history.replaceState(null, document.title, newURL);
                });
            })
        }
    }

    /**
     * Get discounted variant IDs
     *
     * Get the variant IDs that a product-specific discount applies to by parsing
     * the checkout line items for the discount text.
     */
    processDiscountItems(){

        let discountCodes = [];

        if(Utils.isCustomerInfoPage() || Utils.isShippingPage() || Utils.isPaymentMethodPage()){

            // Get discount code from tags list
            let discountCodeEles = $('.tags-list .reduction-code__text');
            discountCodeEles.each(function(){
                discountCodes.push($(this).text());
            });

        } else if(Utils.isThankYouPage()) {

            // Grab discount code from Shopify.checkout object on the page
            let usedDiscountCode = (((window.Shopify || {}).checkout || {}).discount || {}).code;
            if(usedDiscountCode){
                discountCodes.push(usedDiscountCode);
            }

        }

        //Utils.clog(3, discountCodes);

        // Get line item discount data
        let lineItemDiscountData = this.getLineItemDiscountData();
        //Utils.clog(3, lineItemDiscountData);

        // Find any matches
        let discountCodeUsed = null;
        let discountItems = [];
        for (let i = 0; i < discountCodes.length; i++) {
            const discountCode = discountCodes[i];

            for (let j = 0; j < lineItemDiscountData.length; j++) {
                const lineItem = lineItemDiscountData[j];

                for (let k = 0; k < lineItem.discounts.length; k++) {
                    const discount = lineItem.discounts[k];

                    if(discountCode === discount.description && discountCodeUsed === null){
                        discountCodeUsed = discountCode;
                    }

                    if(discountCodeUsed === discount.description){
                        discountItems.push(lineItem.index);
                        break;
                    }
                }
            }

            if(discountCodeUsed){
                break;
            }
        }

        Config.checkoutData.discountCodeUsed = discountCodeUsed;
        Config.checkoutData.discountItems = discountItems;
    }

    /**
     * Get line item discount text
     *
     * Parse discount text to be used in /getCart
     */
    getLineItemDiscountData(){

        let lineItems = [];

        // Calculate line totals
        let checkoutItemLines = $('tr.product');
        for (let i = 0; i < checkoutItemLines.length; i++) {
            const itemLine = $(checkoutItemLines[i]);

            let itemLineProductID = itemLine.attr('data-product-id');
            let itemLineVariantID = itemLine.attr('data-variant-id');

            let lineItem = {
                pid: itemLineProductID,
                vid: itemLineVariantID,
                discounts: [],
                index: i
            };

            // Get all displayed line discounts
            let itemLineDiscountEles = itemLine.find('.product__description > ul li.reduction-code');
            if(itemLineDiscountEles.length > 0){
                for (let j = 0; j < itemLineDiscountEles.length; j++) {
                    const itemLineDiscountEle = $(itemLineDiscountEles[j]);

                    let itemLineDiscountTextEle = itemLineDiscountEle.find('span.reduction-code__text');

                    let discountText = itemLineDiscountTextEle.text().trim();
                    //Utils.clog(3, discountText);

                    // Separate description from amount
                    let match = discountText.match(/\([^()]*\)/g);
                    let discountAmt = match[match.length - 1];
                    let discountDesc = discountText.replace(discountAmt, '').trim();
                    //Utils.clog(3, discountDesc);

                    lineItem.discounts.push({
                        text: discountText,
                        description: discountDesc,
                        amount: discountAmt
                    });
                }
            }

            lineItems.push(lineItem);
        }

        return lineItems;

    }

    static initCheckoutCartData(){

        // Init data by extending the 2 templates
        Config.checkoutData = $.extend(true, {}, Config.checkoutDataRetrievedTemplate, Config.checkoutDataCalculatedTemplate);
    }

    /**
     * Cleanup and prep data to use locally
     */
    cleanCheckoutCartData(data){

        // Reset retrieved data
        $.extend(true, Config.checkoutData, Config.checkoutDataRetrievedTemplate);

        //Utils.clog(3, 'cleanCheckoutCartData', Config.checkoutData.subtotal);

        let lineItemDiscountTotal = 0.00;

        // Cleanup data
        for (let c = 0; c < data.items.length; c++) {
            const item = data.items[c];

            item.consumer_price = parseFloat(item.consumer_price);
            item.consumer_subtotal = parseFloat(item.consumer_subtotal);
            item.consumer_total = parseFloat(item.consumer_total);

            lineItemDiscountTotal += item.consumer_subtotal - item.consumer_total;

            //item.quantity = item.quantity;
            //item.discounts = item.discounts;
            //item.id = item.id;
            item.variant_id = item.variant_id + '';

            for (let i = 0; i < item.discounts.length; i++) {
                const discount = item.discounts[i];

                discount.consumer_amount = parseFloat(discount.consumer_amount);
            }
        }

        // Map to local format
        Config.checkoutData.currency = data.currency;
        Config.checkoutData.lineItems = data.items;
        Config.checkoutData.lineItemDiscountTotal = lineItemDiscountTotal * -1;
        Config.checkoutData.subtotal = parseFloat(data.consumer_subtotal);

        if(data.discount){
            Config.checkoutData.discount.title = data.discount.title;
            Config.checkoutData.discount.amount = parseFloat(data.discount.consumer_amount) * -1;
        }

        // Set shipping values
        // Only set if available and a shipping option was not selected from the shipping page
        if(data.shipping && !Config.checkoutData.shipping.selectedShippingOption){

            Config.checkoutData.shipping.original = null;
            Config.checkoutData.shipping.converted = parseFloat(data.shipping.consumer_price);
            Config.checkoutData.shipping.dutiesOriginal = null;
            Config.checkoutData.shipping.dutiesConverted = parseFloat(data.shipping.consumer_duty);
            Config.checkoutData.shipping.title = data.shipping.title;

            if(Config.checkoutData.shipping.title.match(/(.+) ([[\d.]+\|[\d.]+])/)){
                Config.checkoutData.shipping.gipShippingPresent = true;
            }
            if(Config.checkoutData.shipping.converted === 0){
                Config.checkoutData.shipping.freeShipping = true;
            } else {
                // Set back to false if eg. a free shipping discount is removed
                Config.checkoutData.shipping.freeShipping = false;
            }

            this.renderShippingAndDutyTotals('cart data');
        }

        if(data.taxes){

            // Use taxes from cart data if on payment page or beyond.
            if(Utils.isPaymentMethodPage() || Utils.isThankYouPage() || Utils.isOrderSummaryPage()) {
                Config.checkoutData.taxes.original = null;
                Config.checkoutData.taxes.converted = parseFloat(data.taxes.consumer_total);
                Config.checkoutData.taxes.converted_included = parseFloat(data.taxes.consumer_taxes);
            }
        }

        if(data.gift_cards){
            let totalAmount = 0;
            for (let i = 0; i < data.gift_cards.length; i++) {
                const giftCard = data.gift_cards[i];
                giftCard.consumer_amount = parseFloat(giftCard.consumer_amount) * -1;
                giftCard.last_characters = giftCard.last_characters.toUpperCase();

                totalAmount += giftCard.consumer_amount;
            }

            Config.checkoutData.giftCards.cards = data.gift_cards;
            Config.checkoutData.giftCards.totalAmount = Utils.round(totalAmount);
        }

        //Utils.clog(3, 'cleanCheckoutCartData after parsing', Config.checkoutData.subtotal);
    }

    /**
     * Initialize customer info page
     */
    initCustomerInfoPage() {

        Utils.clog(3, 'On customer info page');

        // (Customer Info Step)
        // Change email/phone input to only email
        // Technically, people could still put in a phone but at least they were
        // told. If we still see instances of this we can do something more
        // complicated. See DEV-3042.
        let contactInput = $("#checkout_email_or_phone");
        if (contactInput.length === 1 && contactInput.attr("placeholder") !== Constants.STRINGS.Email) {

            contactInput.attr("placeholder", Constants.STRINGS.Email).prev().html(Constants.STRINGS.Email);
            $("#error-for-email_or_phone").html(Constants.MESSAGES.MissingEmail);

        }

        // (Customer Info Step)
        // Save shipping country to localstorage
        // If this page has the shipping country, then save the value and attach
        // an event.
        // If this page has the billing country instead, use that input to get value

        let shippingCountryInput = $("#checkout_shipping_address_country");
        let billingCountryInput = $("#checkout_billing_address_country");

        let saveShippingCountry = selector => {
            let country = selector.children("option:selected").attr("data-code");
            Utils.selectShippingCountry(country);
        };

        if (billingCountryInput.length) {
            
            saveShippingCountry(billingCountryInput);

            billingCountryInput.off('change.GIP').on('change.GIP', function () {
                saveShippingCountry(billingCountryInput);
            });

        } else if (shippingCountryInput.length) {

            saveShippingCountry(shippingCountryInput);

            shippingCountryInput.off('change.GIP').on('change.GIP', function () {
                saveShippingCountry(shippingCountryInput);
            });

        }

    }

    /**
     * Initialize shipping page
     */
    initShippingPage() {

        Utils.clog(3, 'On shipping page');

        Config.monitorObservable.subscribe(() => {

            // Wait for shipping loader to be removed
            // Also Shopify might reset the page, so we need to re-init
            let shippingLoader = $('div.section.section--shipping-method .blank-slate');
            let shippingRadioInputs = $('input.input-radio[data-checkout-total-shipping]');

            if (this.enableProcessShippingPage
                && shippingLoader.length === 0
                && shippingRadioInputs.length > 0
                && $(shippingRadioInputs[0]).attr('gip-shipping-option-processed') === undefined) {
                this.processShippingPage();
            }
        });
    }


    /**
     * Initialize payment method page
     */
    initPaymentPage() {

        Utils.clog(3, 'On payment page');

        // Don't process if FLC only
        if (Config.flcOnly) {
            this.abortPaymentProcessing("FLC only, do not process payment page");
        }

        // Don't process free orders
        if ($(Constants.SHOPIFY_FREE_SELECTOR).is(":visible")) {
            this.abortPaymentProcessing("Gift card covered or free order, do no process payment page");
        }

        GIPAPI.fingerprint();

        /**
         * Check if payment section is ready to be parsed.
         */
        let checkPaymentSectionReady = () => {

            // Return false early if the payment section is not available.
            if ($(Constants.SHOPIFY_PAYMENT_SELECTOR).length !== 1) {
                return false;
            }

            // Return early if gateway ID was retrieved successfully before.
            if (Config.gipGatewayId) {
                return true;
            }

            this.processShopifyGateways();

            // Did we get it?
            if (!Config.gipGatewayId) {

                // We aren't configured, but they have other options so just shut down
                // the trash compactor.
                this.abortPaymentProcessing("Unable to locate GIP Gateway");
                return false;

            }

            return true;
        };

        Config.monitorObservable.subscribe(() => {

            //Utils.clog(3, 'Loop ran');

            // Process only if not aborted.
            // ---
            // Process if the page hasn't been processed or was reset by
            // Shopify. Shopify may reset the page with their own scripts.
            // One example is when a discount code is added or removed it resets
            // the payment methods, billing address, checkout buttons etc.
            // We will check whether a page is reset by the presence of the .GIP
            // class in our custom checkout button.
            // ---
            // Also don't run until enabled.
            // ---
            // Also don't run until the payment method div is ready. This can happen
            // when there is a loader that says 'Calculating taxes...'
            if (!this.abortedPaymentProcessing
                && this.enableProcessPaymentPage
                && $("button.GIP").length === 0
                && checkPaymentSectionReady()) {

                // Show loader again in case the first time was not ready
                // this.showLoader();

                this.processPaymentPage();
            }


            // Process the aborted page since we actively revert back the
            // normal Shopify payment methods.
            else if (this.abortedPaymentProcessing) {
                this.processAbortedPaymentPage();
            }
        });

    }

    /**
     * Init the shipping step
     *
     * Sometimes Shopify refreshes the options, and the handler is lost, so we rerun
     * the initialization.
     */
    processShippingPage() {

        Utils.clog(3, 'Process shipping page');

        let shippingRadioInputs = $('input.input-radio[data-checkout-total-shipping]');
        if (shippingRadioInputs.length > 0) {

            /**
             * Parse shipping options
             */

            let gipShippingPresent = false;

            for (let i = 0; i < shippingRadioInputs.length; i++) {
                const input = $(shippingRadioInputs[i]);

                const shippingOptionRow = input.closest('.content-box__row');

                // Add processed attribute so we don't need to re-init unnecessarily
                if (!input.attr('gip-shipping-option-processed')) {

                    let labelText = shippingOptionRow.find('span[data-shipping-method-label-title]').attr('data-shipping-method-label-title');

                    let labelPrice = shippingOptionRow.find('span.radio__label__accessory > span');
                    let labelDiscountedPrice = shippingOptionRow.find('span.radio__label__accessory > del');

                    // match title with dhl/flc shipping and duty
                    let labelMatches = labelText.match(/(.+)(\[[\d.|]+\])/);
                    
                    if (labelMatches) {

                        let parsedShippingName = labelMatches[1];
                        let rawValues = labelMatches[2];

                        let parsedValues = rawValues.match(/[\d.]+/g);

                        let labelHtml = shippingOptionRow.find('span[data-shipping-method-label-title]');
                        let descriptionHtml = labelHtml.find('span');
                        labelHtml.html(parsedShippingName);
                        labelHtml.append('<br/>');

                        try {
                            // try to parse content (html markup) of description
                            descriptionHtml.html(Utils.nl2br(descriptionHtml[0].innerText));
                            // Prices in shipping method description should not be rounded
                            // find all tagged money elements and wrap with another span that prevents from rounding
                            // this is needed because localize script checks the data-variant-id on the parent and
                            // updating the logic may cause side effects
                            descriptionHtml.find('span.money').wrap('<span data-variant-id="noround"></span>');
                        } catch (error) {
                            // on error, do nothing
                        }

                        labelHtml.append(descriptionHtml);

                        let shippingValue = parsedValues[0];
                        let dutyValue = parsedValues[1];

                        input.attr('gip-shipping-value', shippingValue);
                        input.attr('gip-duty-value', dutyValue);

                        let shippingPriceDisplay;
                        if(parseFloat(shippingValue) === 0){
                            shippingPriceDisplay = 'Free';
                        } else {
                            shippingPriceDisplay = Utils.getDisplayedPrice(shippingValue, true, Config.storeCurrency);
                        }

                        // Handle the discounted shipping price if it exists
                        if(labelDiscountedPrice.length === 1){

                            // Exclude duties from the price of the shipping option
                            labelDiscountedPrice.html(shippingPriceDisplay).addClass('gip-shipping-price-processed');
                            labelPrice.addClass('gip-shipping-price-processed');

                        } else {
                            // Exclude duties from the price of the shipping option
                            labelPrice.html(shippingPriceDisplay).addClass('gip-shipping-price-processed');
                        }

                        input.attr('gip-shipping-option-processed', 'gip-shipping');
                        input.addClass('notranslate');

                        gipShippingPresent = true;

                    } else {

                        let totalValue = parseInt(input.attr('data-checkout-total-shipping-cents'), 10) / 100;

                        input.attr('gip-shipping-value', totalValue);

                        // Handle the discounted shipping price if it exists
                        if(labelDiscountedPrice.length === 1){

                            labelDiscountedPrice.addClass('gip-shipping-price-processed');
                            labelPrice.addClass('gip-shipping-price-processed');

                        } else {

                            labelPrice.addClass('gip-shipping-price-processed');

                        }

                        input.attr('gip-shipping-option-processed', 'non-gip-shipping');
                        input.addClass('notranslate');

                    }
                }
            }

            Config.checkoutData.shipping.gipShippingPresent = gipShippingPresent;

            /**
             * Handle on select shipping
             */

            let selectShippingOption = input => {

                //Utils.clog(3, 'selectShippingOption', input);

                // Additional processing if convert is running
                if (!gipShopify.convertAborted()) {
                    Config.selectedShippingOptionHandle = input.val();

                    PriceScanner.shippingResetCheckoutTotals();
                }

                // Set shipping values
                if (input.attr('gip-shipping-option-processed') === 'gip-shipping') {

                    Config.checkoutData.shipping.original = parseFloat(input.attr('gip-shipping-value'));
                    Config.checkoutData.shipping.converted = null;
                    Config.checkoutData.shipping.dutiesOriginal = parseFloat(input.attr('gip-duty-value'));
                    Config.checkoutData.shipping.dutiesConverted = null;

                    // Set flag if shipping is free
                    Config.checkoutData.shipping.freeShipping = input.attr('data-checkout-total-shipping-cents') === '0';

                    // If converter is aborted, use original values as converted values
                    if(gipShopify.convertAborted()){
                        Config.checkoutData.shipping.converted = Config.checkoutData.shipping.original;
                        Config.checkoutData.shipping.dutiesConverted = Config.checkoutData.shipping.dutiesOriginal;

                        // Set to store currency since this can be run before getCheckoutCart is called
                        Config.checkoutData.currency = Config.storeCurrency;
                    }

                } else {

                    Config.checkoutData.shipping.original = parseFloat(input.attr('gip-shipping-value'));
                    Config.checkoutData.shipping.converted = null;
                    Config.checkoutData.shipping.dutiesOriginal = null;
                    Config.checkoutData.shipping.dutiesConverted = null;

                    // Set flag if shipping is free
                    Config.checkoutData.shipping.freeShipping = input.attr('data-checkout-total-shipping-cents') === '0';

                    // If converter is aborted, use original values as converted values
                    if(gipShopify.convertAborted()){
                        Config.checkoutData.shipping.converted = Config.checkoutData.shipping.original;

                        // Set to store currency since this can be run before getCheckoutCart is called
                        Config.checkoutData.currency = Config.storeCurrency;
                    }

                }

                //Utils.clog(3, Config.checkoutData.shipping);

                Config.checkoutData.shipping.selectedShippingOption = true;

                //Utils.clog(3, 'Selected shipping option:', Config.checkoutData.shipping.original, Config.checkoutData.shipping.converted, Config.checkoutData.shipping.dutiesOriginal, Config.checkoutData.shipping.dutiesConverted);

                this.renderShippingAndDutyTotals('shipping option');

            };

            shippingRadioInputs.off('change.GIP').on('change.GIP', function () {
                selectShippingOption($(this));
            });

            // Also reset totals once on init
            // This is necessary when the shipping option is reset, it will need
            // to be re-converted.
            let selectedShippingRadioInput = $('input.input-radio[data-checkout-total-shipping]:checked');
            selectShippingOption(selectedShippingRadioInput);
        }

    }

    /**
     * Builds the display for shipping and duties/tax lines
     *
     * Runs when:
     *  - shipping option is selected in shipping step
     *  - GIP shipping format is detected in summary block
     *  - GIP shipping format is detected in thank you page
     */
    renderShippingAndDutyTotals(from) {

        this.renderShippingAndDutyLock = 1;

        Utils.clog(3, 'Render shipping and duty totals – from', from);

        let shippingEle = $('tr.total-line.total-line--shipping');

        // Add Duties / Taxes line if GIP options present
        if (Config.checkoutData.shipping.gipShippingPresent) {

            // Add line only if not yet added
            let dutiesEle = $('tr.total-line.total-line--duties');
            if (dutiesEle.length === 0) {

                shippingEle.after(`<tr class="total-line total-line--duties">
                            <td class="total-line__name tooltip-owner">Duties / Taxes</td>
                            <td class="total-line__price"><span class="order-summary__emphasis"></span></td>
                        </tr>`);
            }

            let dutiesTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutDuties);

            // Display duties
            let price_duties_display;

            // If duties are already converted, then display only without converting.
            if(Config.checkoutData.shipping.dutiesConverted !== null){
                price_duties_display = Utils.getDisplayedPrice(Config.checkoutData.shipping.dutiesConverted, Config.checkoutData.currency);
                dutiesTotalEle.html(price_duties_display);
                PriceScanner.resetElement(dutiesTotalEle); // Reset element
                dutiesTotalEle.attr('data-gip-converted', 'true'); // Display only without converting
                dutiesTotalEle.addClass('notranslate');
            } else {
                price_duties_display = Utils.getDisplayedPrice(Config.checkoutData.shipping.dutiesOriginal, true, Config.storeCurrency);
                dutiesTotalEle.html(price_duties_display);
                PriceScanner.resetElement(dutiesTotalEle); // Remove to allow conversion
            }

        } else {

            // If GIP options are not present, there's a Shopify bug where the GIP option may still
            // be in the summary, and the duties line is added. In that case we should just remove the line again.
            $('tr.total-line.total-line--duties').remove();
        }

        /* ---------- */

        // Check for 'Free shipping' discount
        let discountsTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutDiscounts);

        // Temp backwards compat
        if(discountsTotalEle.length === 0){
            discountsTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutDiscountsOld);
        }

        let freeShippingDiscountApplied = (discountsTotalEle.length > 0 && $.trim($(discountsTotalEle[0]).html()) === 'Free shipping');

        // Display shipping – show Free instead of 0.00
        let price_shipping_display;
        if (Config.checkoutData.shipping.original === 0
            || freeShippingDiscountApplied
            || Config.checkoutData.shipping.freeShipping) {

            price_shipping_display = 'Free';

        } else {

            // If shipping is already converted, then display only without converting.
            if(Config.checkoutData.shipping.converted !== null){
                price_shipping_display = Utils.getDisplayedPrice(Config.checkoutData.shipping.converted, Config.checkoutData.currency);
            } else {
                price_shipping_display = Utils.getDisplayedPrice(Config.checkoutData.shipping.original, true, Config.storeCurrency);
            }

        }

        setTimeout(() => { // setTimeout required to run after Shopify's own scripts modify the page
            let shippingTotalEle = $(Constants.SHOPIFY_SELECTORS.checkoutShipping);

            shippingTotalEle.html(price_shipping_display);

            // Reset shipping total so it can be converted
            PriceScanner.resetElement(shippingTotalEle);

            //Utils.clog(3, 'Display shipping amount', price_shipping_display);

            // If shipping is already converted, then display only without converting.
            if(Config.checkoutData.shipping.converted !== null){
                shippingTotalEle.attr('data-gip-converted', 'true');
                shippingTotalEle.addClass('notranslate');
            }

            this.renderShippingAndDutyLock = 0;

        }, 0);

        /* ---------- */

        // Combine shipping + duties total to ensure conversion matches
        // This is to fix a bug where convertedShipping + convertedDuties != convertedCombinedShippingAndDuties
        // We will adjust convertedDuties so that convertedShipping + convertedDuties == convertedCombinedShippingAndDuties
        let combinedShippingEle = $('#combined-shipping-check');
        if(combinedShippingEle.length === 0){
            shippingEle.after(`<span data-variant-id="noround"><span id="combined-shipping-check" class="money" style="display: none;"></span></span>`);
            combinedShippingEle = $('span#combined-shipping-check');
        }

        let combinedPrice;
        let combined_shipping_display;

        // If shipping is already converted, then display only without converting.
        if(Config.checkoutData.shipping.converted !== null){
            combinedPrice = (Config.checkoutData.shipping.converted || 0) + (Config.checkoutData.shipping.dutiesConverted || 0);
            combined_shipping_display = Utils.getDisplayedPrice(combinedPrice, Config.checkoutData.currency);
        } else {
            combinedPrice = (Config.checkoutData.shipping.original || 0) + (Config.checkoutData.shipping.dutiesOriginal || 0);
            combined_shipping_display = Utils.getDisplayedPrice(combinedPrice, true, Config.storeCurrency);
        }

        setTimeout(function () { // setTimeout required to run after Shopify's own scripts modify the page
            combinedShippingEle.html(combined_shipping_display);

            // Reset combined shipping
            PriceScanner.resetElement(combinedShippingEle);

            // If shipping is already converted, then display only without converting.
            if(Config.checkoutData.shipping.converted !== null){
                combinedShippingEle.attr('data-gip-converted', 'true');
                combinedShippingEle.addClass('notranslate');
            }
        }, 0);

        /* ---------- */

        /**
         * Recalculate total for the case of having 'Free shipping' discount,
         * non-localized currency, and duties/taxes included. This is required
         * to add back the duties/taxes portion to the total, since Shopify
         * does not include any shipping cost when 'Free shipping' discount is
         * applied.
         *
         * Also recalculate total for the case of free shipping in general. This
         * could happen if Shopify Scripts are used to offer free shipping.
         *
         * We don't need to check for non-localized currency since if the
         * convert loop is running, it will continually overwrite the total
         * with the proper calculated value anyway.
         */
        let totalEle = $(Constants.SHOPIFY_SELECTORS.checkoutTotal);
        if (freeShippingDiscountApplied
            || Config.checkoutData.shipping.freeShipping) {

            setTimeout(function () { // setTimeout required to run after Shopify's own scripts modify the page
                Utils.clog(3, 'Recalculate total for non-localized currency');
                //Utils.clog(3, 'Need to recalculate total', Config.checkoutData.shipping);
                //Utils.clog(3, 'Total original value', Utils.getNumericPrice(totalEle.html()));

                let dutiesOriginal = (parseFloat(Config.checkoutData.shipping.dutiesOriginal) || 0);

                if (totalEle.attr('gip-total-original') === undefined) {
                    // get value from shopify-defined dataset variable
                    let totalWithoutDutiesShop = parseInt(totalEle.attr('data-checkout-payment-due-target')) / 100;

                    // also parse value as fallback
                    let totalWithoutDutiesParsed = Utils.getNumericPrice(totalEle);

                    // subtract duties from total (use dataset value if available, otherwise use fallback)
                    let totalWithoutDuties = (totalWithoutDutiesShop || totalWithoutDutiesParsed) - dutiesOriginal;

                    // Save the total without duties included
                    totalEle.attr('gip-total-original', totalWithoutDuties);

                    // prevent google translate from modifying the price
                    totalEle.addClass('notranslate');
                }

                let totalOriginal = parseFloat(totalEle.attr('gip-total-original'));
                let totalWithDuties = totalOriginal + dutiesOriginal;
                let totalDisplay = Utils.getDisplayedPrice(totalWithDuties, true, Config.storeCurrency);

                //Utils.clog(3, 'Recalculate values', totalOriginal, dutiesOriginal, totalWithDuties);

                // check if the value changed before triggering a re-render
                if (totalWithDuties !== totalOriginal) {
                    totalEle.html(totalDisplay);
                }
            }, 0);
        }

    }

    processPaymentPage() {

        Utils.clog(3, 'Process payment page');

        // Throttle
        if (this.processPaymentPagePending) {
            return;
        }
        this.processPaymentPagePending = true;

        Utils.clog(3, 'Processing payment page');

        // Get GIP gateway ID for use when submitting
        this.processShopifyGateways();

        this.initBillingAddressSection();

        this.initCheckoutButtonSection();

        this.initPaymentMethodSection();
    }

    /**
     * Initialize our custom checkout button
     */
    initBillingAddressSection() {

        Utils.clog(3, 'Init billing address section');

        // Save and watch billing country
        let billingAddressToggled = () => {

            let billField = $(Constants.SHOPIFY_BILLCOUNTRY_SELECTOR);

            let updateBillingCountry = (country) => {
                Config.billingCountry = country;

                //Utils.clog(3, 'updateBillingCountry');
                //Utils.clog(3, Utils.getCountry());

                this.updatePaymentMethods();
            };

            // We need to put the following at the end of the run queue so that any
            // animation that is happening can finish first and the billField's real
            // visibility can be determined.
            setTimeout(() => {
                if (billField.is(":visible")) {

                    updateBillingCountry(billField.children("option:selected").attr("data-code"));

                    billField.off('change.GIP').on('change.GIP', function () {
                        updateBillingCountry($(this).children("option:selected").attr("data-code"));
                    });

                } else {
                    updateBillingCountry(null);
                    billField.off('change.GIP');
                }
            }, 0);
        };

        billingAddressToggled();

        $(Constants.SHOPIFY_DIFFBILL_SELECTOR).off('change.GIP').on('change.GIP', function () {
            billingAddressToggled();
        });

    }

    /**
     * Initialize our custom checkout button
     */
    initCheckoutButtonSection() {

        Utils.clog(3, 'Init checkout button section');

        // Mod the checkout button to support our process
        // Only run if the button hasn't been processed ie. has .GIP class
        if ($("button.GIP").length === 0) {
            // In the July 2018 checkout upgrade, Shopify added a "Continue
            // to the payment gateway" button.  As far as I can tell, this does
            // nothing differently than the "Complete order" button with respect
            // to the Hosted Payment SDK, so we're just going to use the visible
            // button as our base.
            let buttons = $("button.step__footer__continue-btn").filter(":visible");
            // There must be one and only one button visible
            if (buttons.length !== 1) {
                this.abortPaymentProcessing("Detected multiple visible buttons on checkout page");
                return;
            }

            // Override form button behaviour
            let mainBtn = $(buttons[0]);
            let overrideBtn = mainBtn.clone().insertAfter(mainBtn).addClass("GIP").attr("type", "button");
            mainBtn.hide();

            overrideBtn.off('click.GIP').on('click.GIP', (event) => {
                event.preventDefault();
                event.stopPropagation();
               
                this.submitPayment(mainBtn);

                return false;
            });

            // Block enter key from submitting the checkout.
            // The form submit event couldn't be prevent so we override the
            // actual enter key instead.
            // Allow enter to work on textarea and submit/button type buttons.
            $(document).on('keypress', ':input:not(textarea):not([type=submit]):not([type=button])', function(e) {
                if (e.keyCode === 13) {
                    e.preventDefault();
                    //Utils.clog(3, 'Caught enter');
                }
            });
        }

    }

    /**
     * Display the loading animation and hide the payment methods section
     */
    showLoader() {

        // TODO: check if we still need this🤷‍♂️
        // Hide Shopify payment methods
        this.toggleShopifyPaymentGatewaySection('hide');

        // Add progress indicator
        CheckoutApp.isMounted() && CheckoutApp.setShowProgress(true);
    }
    
    static hideLoader() {
        CheckoutApp.isMounted() && CheckoutApp.setShowProgress(false);
    }

    /**
     * Process Shopify payment gateways
     */
    processShopifyGateways() {

        // Only process if it hasn't yet
        let gws = $(Constants.SHOPIFY_PAYMENT_SELECTOR).find(Constants.SHOPIFY_GATEWAY_SELECTOR);

        if(gws.length > 0 && $(gws[0]).attr('gip-gateway-processed') === undefined) {

            let gipGatewayId = null;
            let gatewayData = [];

            // Go through payment gateways
            for (let i = 0; i < gws.length; ++i) {
                let gw = $(gws[i]);

                // The html for grabbing the gateway text can be in many different formats
                // for various cases such as singular gateways, text name gateways, image gateways.
                // This selector seems to work so far for all of them.
                let gw_text = gw.find('.radio__label .radio__label__primary').text().trim();

                // Save gateway info
                gatewayData.push({
                    name: gw_text,
                    gateway_id: gw.attr(Constants.SHOPIFY_GATEWAY_ATTRIBUTE),
                    index: i
                });

                // Mark as processed
                gw.attr('gip-gateway-processed', 'true');
                gw.addClass('notranslate');

                // Grab the GIP gateway by name
                if (Constants.GIP_GATEWAY_NAMES.indexOf(gw_text) !== -1) {
                    gipGatewayId = gw.attr(Constants.SHOPIFY_GATEWAY_ATTRIBUTE);
                }
            }

            Config.gipGatewayId = gipGatewayId;
            Config.shopifyGateways = gatewayData;

            //Utils.clog(3, gws.find('input[type="radio"]'));

            const selectPaymentMethodType = this.selectPaymentMethodType;

            gws.find('input[type="radio"]').off('change.GIP').on('change.GIP', function(){

                Utils.clog(3, 'Changed to shopify gateway: ' + $(this).val());

                selectPaymentMethodType(CheckoutApp.PAYMENT_TYPES.SHOPIFY);

            });

            Utils.clog(3, 'Parsed shopify gateways');
            Utils.clog(3, Config.shopifyGateways);
        }

    }

    /**
     * Abort and cleanup any payment page functions
     */
    abortPaymentProcessing(...args) {

        // Only run for payment page
        if(!Utils.isPaymentMethodPage()){
            return;
        }

        if(args.length > 0) {
            Utils.clog(3, ...args);
        }

        Utils.clog(3, 'Aborting payment processing');

        this.abortedPaymentProcessing = true;

        Checkout.hideLoader();
        CheckoutApp.isMounted() && CheckoutApp.setIsVisible(false);

        this.resetShopifyPaymentSection();

        Utils.clearPreviousPaymentType();
    }

    /**
     * Show Shopify payment methods
     */
    resetShopifyPaymentSection() {

        // If GIP gateway not yet found, try again
        if (!Config.gipGatewayId) {
            this.processShopifyGateways();
        }

        //Utils.clog(3, 'Config.gipGatewayId: ' + Config.gipGatewayId);

        // Show all Shopify payment methods except GoInterpay
        if(Config.shopifyGateways !== null) {
            for (let i = 0; i < Config.shopifyGateways.length; i++) {
                const shopifyGateway = Config.shopifyGateways[i];

                if (Constants.GIP_GATEWAY_NAMES.indexOf(shopifyGateway.name) !== -1) {
                    this.togglePaymentGateway(shopifyGateway.gateway_id, 'hide');
                } else {
                    this.togglePaymentGateway(shopifyGateway.gateway_id, 'show');
                }
            }
        }

        // Show Shopify payment section
        this.toggleShopifyPaymentGatewaySection('show');
    }

    checkoutAppEventHandler(action, payload) {

        if(action === CheckoutApp.ERRORS.NON_RECOVERABLE) {
            Sentry.captureException(new Error("Un-recoverable error encountered"), { tags: {errorType: "Checkout App Fail"} } );
            this.abortPaymentProcessing("Un-recoverable error encountered");
            return;
        }

        if(action === CheckoutApp.EVENT_TYPES.PAYMANT_METHOD_CHANGED && payload !== null) {
            // hide shopify selection if non-shopify method selected
            const paymentMethod = payload;
            if(paymentMethod !== CheckoutApp.PAYMENT_TYPES.SHOPIFY){
                $(Constants.SHOPIFY_PAYMENT_SELECTOR).find(Constants.SHOPIFY_GATEWAY_SUBFIELDS_SELECTOR).addClass('hidden');
            }
        }
        

    }

    initPaymentMethodSection() {
        Utils.clog(3, 'Init payment method section');
        
        // need to wrap in an arrow function to maintain `this` context in class-based method
        const appEventHandler = (action, payload) => this.checkoutAppEventHandler(action, payload);
        
        const checkoutAppEl = document.createElement('div');
        checkoutAppEl.id = "gip-payment-methods-app";
        checkoutAppEl.setAttribute("data-payment-subform", "required");
        checkoutAppEl.setAttribute("style", "margin-bottom: 30px");

        // mount preact app and pass event handler
        CheckoutApp.mount(checkoutAppEl, appEventHandler);

        // Choose place to insert the template
        let originalPaymentSection = $(`.section--payment-method div[data-payment-subform='required']:not(#gip-payment-methods-app)`);
        if(originalPaymentSection.length === 1){
            // Insert immediately before the original payment section if possible
            originalPaymentSection.before(checkoutAppEl);
            
        } else {
            // Else prepend it in the payment section wrapper
            $('.section--payment-method .section__content').prepend(checkoutAppEl);
        }

        // payment method update is triggered via initBillingAddressSection method

    }

    updatePaymentMethods() {
        // load getPaymentMethods
        GIPAPI.getPaymentMethods((methods) => {
            if (methods) {

                this.processPaymentMethods(methods);
                
                const country = Utils.getCountry();
                CheckoutApp.isMounted() && CheckoutApp.setCountry(country);

                this.processPaymentPagePending = false;

            } else {

                Sentry.captureMessage("getPaymentMethods failed", { tags: {errorType: "getPaymentMethods API Fail"} } );

            }
        })
    }

    selectPaymentMethodType(type) {
        CheckoutApp.isMounted() && CheckoutApp.setPaymentMethodId(type);
    }

    processPaymentMethods(methods) {

        let payment_method = null;

        // Divide the methods to cards or other
        this.allPaymentMethods = methods;

        // This may not be needed anymore (not sent to CheckoutApp)
        // but still being used for some logic
        let other_payment_methods = [];
        
        // cards avalilable logic can go into CheckoutApp
        let cards_available = [];

        let country_code = Utils.getCountry();
        let custom_display_rules = ["VISA", "MC", "AMEX"];
        let currency_code = Config.selectedCurrency;
        for (let i = 0; i < this.allPaymentMethods.length; i++) {
          const method = this.allPaymentMethods[i];

          // Add any extra info on specific methods
          if (method.Id === "PAYPAL") {
            // Main logo for paypal to replace the text
            method._name_logo_img_src =
              "https://st.rch.io/methods/" + "PAYPAL" + ".png";

            // Card logos to show with paypal
            method._card_ids_available = ["VISA", "MC", "AMEX"];
          } else {
            method._card_ids_available = [method.Id];
          }

          if (method.Class === "Card") {
            cards_available.push(method);
          } else {
            other_payment_methods.push(method);
          }
        }
        
        // Check previously saved payment type so we can auto-select the correct payment type
        let previousPaymentType = Utils.loadPreviousPaymentType();
        let defaultReachPaymentTypeSet = false;

        // If no previous saved payment type, then set to a default reach type
        // depending on what's available: either 'CREDIT' for a card or the ID of
        // one of the 'other' payment methods.
        if (previousPaymentType === null) {
          if (cards_available.length > 0) {
            payment_method = CheckoutApp.PAYMENT_TYPES.CREDIT;
            this.selectPaymentMethodType(payment_method);
            defaultReachPaymentTypeSet = true;
          } else if (other_payment_methods.length > 0) {
            payment_method = other_payment_methods[0].Id;
            this.selectPaymentMethodType(payment_method);
            defaultReachPaymentTypeSet = true;
          }
        }

        // Else if a Reach type was saved, try to select it as default
        else if (previousPaymentType !== CheckoutApp.PAYMENT_TYPES.SHOPIFY) {
          if (previousPaymentType === CheckoutApp.PAYMENT_TYPES.CREDIT && cards_available.length > 0) {
            payment_method = CheckoutApp.PAYMENT_TYPES.CREDIT;
            this.selectPaymentMethodType(payment_method);
            defaultReachPaymentTypeSet = true;
          } else if (other_payment_methods.length > 0) {
            let found_method = other_payment_methods.find(
              (ele) => ele.Id === previousPaymentType
            );
            if (found_method) {
              payment_method = previousPaymentType;
              this.selectPaymentMethodType(payment_method);
              defaultReachPaymentTypeSet = true;
            }
          }
        }

        // No Reach payment methods default set, then one of Shopify's payment
        // methods must be selected.
        if (!defaultReachPaymentTypeSet) {
          this.selectPaymentMethodType(CheckoutApp.PAYMENT_TYPES.SHOPIFY);
        }

        // Sorts cards
        let sortCards = function (cards, country, currency) {
          if (
            Constants.PAYMENT_METHOD_ORDER_PER_COUNTRY[country] &&
            Constants.PAYMENT_METHOD_ORDER_PER_COUNTRY[country][currency]
          ) {
            custom_display_rules =
              Constants.PAYMENT_METHOD_ORDER_PER_COUNTRY[country][currency];
          }

          return cards.sort(function (a, b) {
            for (var i = 0; i < custom_display_rules.length; i++) {
              if (a.Id === custom_display_rules[i]) return -1;
              if (b.Id === custom_display_rules[i]) return 1;
            }

            if (a.Name < b.Name) return -1;
            if (a.Name > b.Name) return 1;
            return 0;
          });
        };

        cards_available = sortCards(
          cards_available,
          country_code,
          currency_code
        );

        // handle whitelisted methods
        this.initShopifyPaymentMethodSection(other_payment_methods);
        if(CheckoutApp.isMounted()) {
            CheckoutApp.setPaymentMethods(methods);
            CheckoutApp.setCards(cards_available);
            CheckoutApp.setPaymentMethodId(payment_method);
        }
        Checkout.hideLoader();
    }
    
    /**
     * Display and filter Shopify payment methods
     */
    initShopifyPaymentMethodSection(reach_methods) {

        // Utils.clog(3, 'Init shopify payment method section');

        // Special handling for Paypal. If Reach Paypal is available while Shopify Paypal
        // is whitelisted, show Reach over Shopify, otherwise show Shopify.
        let reachPaypalAvailable = reach_methods.filter(obj => obj.Id === 'PAYPAL').length === 1;
        let _this = this;
        function showPaymentGateway(shopifyGateway){

            if(shopifyGateway.name === 'PayPal' && reachPaypalAvailable){
                _this.togglePaymentGateway(shopifyGateway.gateway_id, 'hide');
                return false;
            } else {
                _this.togglePaymentGateway(shopifyGateway.gateway_id, 'show');
                return true;
            }
        }

        // Filter Shopify payment methods depending on whether the order is
        // domestic or localized.

        // Domestic (store currency) orders
        // The only time this is ever reached is when processStoreCurrency = true, otherwise we
        // would have already aborted payment processing altogether.
        if(gipShopify.convertAborted()){

            let hasActiveShopifyGateway = false;

            // Check if 'all' gateway present
            let whitelistAllGateways = Config.whitelistedStoreCurrencyGatewayNames.indexOf('_ALL_GATEWAYS_') !== -1;
            if(whitelistAllGateways){

                // Show all payment methods except Reach's
                for (let i = 0; i < Config.shopifyGateways.length; i++) {
                    const shopifyGateway = Config.shopifyGateways[i];

                    if(Constants.GIP_GATEWAY_NAMES.indexOf(shopifyGateway.name) === -1){

                        if(showPaymentGateway(shopifyGateway)) {
                            hasActiveShopifyGateway = true;
                        }

                    } else {

                        this.togglePaymentGateway(shopifyGateway.gateway_id, 'hide');

                    }
                }

            } else {

                // Hide all payment methods except these
                for (let i = 0; i < Config.shopifyGateways.length; i++) {
                    const shopifyGateway = Config.shopifyGateways[i];

                    if(Config.whitelistedStoreCurrencyGatewayNames.indexOf(shopifyGateway.name) !== -1){

                        if(showPaymentGateway(shopifyGateway)) {
                            hasActiveShopifyGateway = true;
                        }

                    } else {

                        this.togglePaymentGateway(shopifyGateway.gateway_id, 'hide');

                    }
                }

            }

            if(hasActiveShopifyGateway){
                this.toggleShopifyPaymentGatewaySection('show');
            } else {
                this.toggleShopifyPaymentGatewaySection('hide');
            }

        }

        // Localized orders
        else {

            let hasActiveShopifyGateway = false;

            // Check if 'all' gateway present
            let whitelistAllGateways = Config.whitelistedGatewayNames.indexOf('_ALL_GATEWAYS_') !== -1;
            if(whitelistAllGateways){

                // Show all payment methods except Reach's
                for (let i = 0; i < Config.shopifyGateways.length; i++) {
                    const shopifyGateway = Config.shopifyGateways[i];

                    if(Constants.GIP_GATEWAY_NAMES.indexOf(shopifyGateway.name) === -1){

                        if(showPaymentGateway(shopifyGateway)) {
                            hasActiveShopifyGateway = true;
                        }

                    } else {

                        this.togglePaymentGateway(shopifyGateway.gateway_id, 'hide');

                    }
                }

            } else {

                // Hide all payment methods except these
                for (let i = 0; i < Config.shopifyGateways.length; i++) {
                    const shopifyGateway = Config.shopifyGateways[i];

                    if(Config.whitelistedGatewayNames.indexOf(shopifyGateway.name) !== -1){

                        if(showPaymentGateway(shopifyGateway)) {
                            hasActiveShopifyGateway = true;
                        }

                    } else {

                        this.togglePaymentGateway(shopifyGateway.gateway_id, 'hide');

                    }
                }

            }

            if(hasActiveShopifyGateway){
                this.toggleShopifyPaymentGatewaySection('show');
            } else {
                this.toggleShopifyPaymentGatewaySection('hide');
            }

        }
    }

    /**
     * Helper function to show or hide a payment gateway
     */
    togglePaymentGateway(gateway_id, show_hide){

        //Utils.clog(3, 'Toggle gateway', show_hide, gateway_id);

        let gatewayEle = $(`[data-select-gateway="${gateway_id}"]`);
        let subfieldsEle = $(`[data-subfields-for-gateway="${gateway_id}"]`);

        // Use classes for hide/show because it allows us to use better DOM selection methods.
        if(show_hide === 'show') {
            gatewayEle.removeClass('hide-gateway');
            subfieldsEle.removeClass('hide-gateway');
        } else if(show_hide === 'hide') {
            gatewayEle.addClass('hide-gateway');
            subfieldsEle.addClass('hide-gateway');
        }
    }

    /**
     * Helper function to show or hide the shopify payment gateway section
     */
    toggleShopifyPaymentGatewaySection(show_hide){
        let sectionEle = $(`.section--payment-method div[data-payment-subform='required']:not(#gip-payment-methods-app)`);

        if(show_hide === 'show'){
            sectionEle.show();
        } else if(show_hide === 'hide'){
            sectionEle.hide();
        }
    }

    submitPayment(shopifyButton) {
        let paymentMethodId = CheckoutApp.getPaymentMethodId();


        Utils.clog(4, 'Using payment method ', paymentMethodId);

        if(paymentMethodId === null){
            // show error
            CheckoutApp.isMounted() && CheckoutApp.setErrorMessage(Constants.MESSAGES.SelectMethod)

            // do not allow processing if payment method is not registered
            return
        }

        // If the GIP gateway is selected, don't allow submitting.
        if($("#checkout_payment_gateway_" + Config.gipGatewayId).prop('checked') === true){            
            Utils.clog(3, 'Block submitting GIP gateway');
            return;
        }

        // If submitting a shopify payment method, trigger the Shopify submit and don't do anything else.
        if(paymentMethodId === CheckoutApp.PAYMENT_TYPES.SHOPIFY) {
            Utils.savePreviousPaymentType(CheckoutApp.PAYMENT_TYPES.SHOPIFY);
            shopifyButton.click();
            return;
        }

        // Check if this order is 'free' or has gift cards in play that cover the cost.
        if ($(Constants.SHOPIFY_FREE_SELECTOR).is(":visible")) {
            Utils.savePreviousPaymentType(CheckoutApp.PAYMENT_TYPES.SHOPIFY);
            shopifyButton.click();
            return;
        }

        const CHECKOUT_STATE = {
            PROCESS_FORM: 1,
            SEND_STASH: 2,
        };


        let state = CHECKOUT_STATE.PROCESS_FORM;
        // if we got to this point, we can continue processing the via checkoutApp
        this.submitPending = true;
        CheckoutApp.handleSubmitPayment()
            .then((stashPayload) => {
                state = CHECKOUT_STATE.SEND_STASH;

                this.showLoader();
                
                Utils.clog(3, stashPayload);

                return GIPAPI.stash(stashPayload)
            })
            .then(() => {
                Utils.clog(3, 'Stash success');

                if (!Config.gipGatewayId) {
                    this.abortPaymentProcessing("No GIP gateway defined");
                    return;
                }
                
                // Submit to Shopify as if nothing had happened

                Utils.savePreviousPaymentType(CheckoutApp.getPaymentMethodId());
                shopifyButton.click();
            })
            .catch(err => {
                this.submitPending = false;
                
                if (state == CHECKOUT_STATE.PROCESS_FORM && err == CheckoutApp.ERRORS.INVALID_PAYMENT_METHOD) {

                    // but if the iframe returns an unsupported payment method it won't send the stash
                    // ideally some information could be shown in UI, but for now at least log to console
                    Utils.clog(3, 'Invalid payment method selected');
                    
                } else if (state == CHECKOUT_STATE.PROCESS_FORM && err != CheckoutApp.ERRORS.NON_RECOVERABLE) {
                    
                    // if we can recover, just exit precess and allow user to update data
                    Utils.clog(2, err);

                } else {
                    // this error is bad, mmkay... abort 

                    console.error(err);
                    Sentry.captureException(err, { tags: {errorType: "Stash Failed"} } );
                    
                    Utils.clog(3, 'Stash failed');
                    
                    Checkout.hideLoader();
                    
                    this.abortPaymentProcessing('Unable to stash data');
                }
            })

        return false;
        
    }

    /**
     * Process the aborted payment page
     */
    processAbortedPaymentPage() {

        //Utils.clog(3, 'Process aborted payment page');

        // Don't continue if payment section isn't available yet
        if ($(Constants.SHOPIFY_PAYMENT_SELECTOR).length !== 1) {
            return;
        }

        this.resetShopifyPaymentSection();
    }

    /**
     * Initialize the post checkout page
     */
    initPostCheckoutPage() {

        Utils.clog(3, 'On post checkout page');

        if(Utils.isThankYouPage()){

            Utils.clog(3, 'On thank you page');

        }

        else if(Utils.isOrderSummaryPage()){

            Utils.clog(3, 'On order summary page');

        }

        // Process GIP shipping method on thank you page if present.
        let shippingEle = $('#gip-ty-shipping-summary-processed');
        if (shippingEle.length === 0) {

            let shippingEle = $("h3:contains('Shipping method') + p");
            if (shippingEle.length === 1) {

                //Utils.clog(3, shippingEle);

                let content = shippingEle.html();

                // Match and replace name if a GIP shipping name ie. '[10.00|10.00]' is present.
                let matches = content.match(/(.+) (\[[\d.|]+\])/);
                if (matches) {

                    shippingEle.attr('id', 'gip-ty-shipping-summary-processed'); // Add ID to indicate parsed
                    shippingEle.addClass('notranslate');

                    let newName = matches[1]; // Only show name, don't even show the price
                    shippingEle.html(newName);

                    let rawValues = matches[2];
                    let parsedValues = rawValues.match(/[\d.]+/g);

                    let shippingValue = parsedValues[0];
                    let dutyValue = parsedValues[1];

                    shippingEle.attr('gip-shipping-value', shippingValue);
                    shippingEle.attr('gip-duty-value', dutyValue);

                    // Only set shipping amounts if converted amount is not yet available from /getCart data
                    if(Config.checkoutData.shipping.converted == null) {

                        Config.checkoutData.shipping.original = parseFloat(shippingEle.attr('gip-shipping-value'));
                        Config.checkoutData.shipping.converted = null;
                        Config.checkoutData.shipping.dutiesOriginal = parseFloat(shippingEle.attr('gip-duty-value'));
                        Config.checkoutData.shipping.dutiesConverted = null;

                        Config.checkoutData.shipping.gipShippingPresent = true;

                    }

                    // We must always render again because Shopify might have reset the section
                    this.renderShippingAndDutyTotals('order summary');
                }

            }

        }

        // @TODO - Update payment method

        let paymentMethodEle = $('span.payment-method-list__item__amount').not('.gip-ty-payment-method-processed');
        if(paymentMethodEle.length >= 1){

            paymentMethodEle.each(function(){

                let ele = $(this);

                // Prepare the line with a class to display the total amount for the line that
                // has the generic payment icon and has one of the Reach's gateway names.
                if(ele.siblings('i.payment-icon--generic').length === 1){
                    let gatewayName = ele.siblings('i.payment-icon--generic').text().trim();

                    if(Constants.GIP_GATEWAY_NAMES.indexOf(gatewayName) !== -1){

                        let match = ele.text().match(/ - (.*)/);
                        let originalAmount = match ? match[1] : '';

                        ele.html(' - <span class="gip-ty-payment-method-amount">' + originalAmount + '</span>');
                    }
                }

                // Prepare the gift card line with a class that can be used to display the converted amount
                else if(ele.siblings('i.payment-icon--gift-card').length === 1){

                    let giftCardText = ele.siblings('span.payment-method-list__item__info').text();
                    if(giftCardText.indexOf('ending with ') === 0){
                        let offset = 12;
                        let giftCardLast4 = giftCardText.substring(offset).toUpperCase();

                        let match = ele.text().match(/ - (.*)/);
                        let originalAmount = match ? match[1] : '';

                        ele.html(' - <span class="gip-ty-payment-method-gift-card-' + giftCardLast4 + '">' + originalAmount + '</span>');
                    }
                }

                ele.addClass('gip-ty-payment-method-processed'); // Add ID to indicate parsed

            });


        }
    }

    

    // /**
    //  * 
    //  * @param {String} Type PaymentMethod.Id or CardInfo.PaymentMethod
    //  * @returns {PaymentMethod|undefined}
    //  */
    // getPaymentMethodById(Id) {
    //     return this.allPaymentMethods.find(pm => pm.Id === Id) || {}
    // }
}