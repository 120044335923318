import "./typedef";
import Q from "q";

export const Config = {

    // Debug mode
    // Convenience flag for debugging Sentry.io
    debugMode: false,

    disableSentry: false,

    // Log Level
    // define what logs should be shown in console
    // 0:error, 1:warning, 2:info, 3:verbose, 4:debug, 5:silly
    logLevel: 1,

    // Trace ID for debugging requests
    traceID: null,

    // Preload style element
    // A reference to the JS generated style element used for price flicker
    preloadStyleEle: null,

    // The environment pulled from the script url.
    env: 'production', // Either 'production' | 'sandbox' | 'development'

    // The merchant id pulled from the script url
    merchantId: null,

    // The client version pulled from the script url. Defaults to v1.0
    clientVersion: null,

    // The store's base currency.  This must match the Shopify store currency.
    storeCurrency: 'USD',

    // If true, the script will not abort payment processing if localized to store currency.
    processStoreCurrency: null,

    // If true, the script will not run the price convert or payment method functions.
    // This is used for merchants who only require the FLC feature.
    flcOnly: null,

    // If true, the script will auto-round any prices
    // To disable rounding for a price, mark the span.money element with a parent element with
    // a data-variant-id attribute value of 'noround'.
    autoRoundPrices: null,

    // Whitelisted gateways will be shown alongside Reach gateways for localized currency orders.
    // Whitelisted store currency gateways will be shown for store currency orders when Reach is also processing store currency.
    // Gateways are specified by a code, and will be mapped to a name.
    whitelistedGateways: [],
    whitelistedGatewayNames: [],
    whitelistedStoreCurrencyGateways: [],
    whitelistedStoreCurrencyGatewayNames: [],

    // Disabled countries
    disabledCountries: [],
    isDisabledCountry: false,

    // Allowed currencies
    // If 1 or more currencies is specified, then any localized currency returned from /localize
    // must also be included in the list to allow conversion. If the backend returns a rate offer
    // that's not in the list, the selected currency will default to the store currency.
    // If the list is empty, all localized currencies will be allowed.
    allowedCurrencies: [],

    // Tag the localize currency stored in the localStorage.
    // We can use this to force any users that do not have this tag present to re-localize
    // since there is no other way to clear a user's currency other than by clearing "cookies and other site data".
    currencyTag: null,

    // Currency code selected via the JS API.  If non null, this is passed to /localize.
    // and the IP detected location and associated currency are overridden.
    // selectedCurrency will copy localizeData.Currency when localizeData returns.
    // Use Utils.selectCurrency() to set this rather than directly.
    selectedCurrency: null,

    // The host name for this script
    scriptHost: null,

    // The host name for GIP side scripts
    gipScriptHost: null,

    // The API URL, determined from scriptHost
    apiHost: null,

    // The location of the stash, determined from scriptHost
    stashHost: null,

    /**
     * @type {LocalizeResponse} localizeData The localize result
     */
    localizeData: null,

    // Deferred localizeData
    localizeDataDeferred: Q.defer(),

    // Flag to indicate the currency selector has been processed to include
    // the localized currency.
    currencySelectorProcessed: false,

    // Observe when localize is run
    // Used in payment page
    localizeDataObservable: null,
    localizeDataObserver: null,

    // Observe the monitor
    // Used in payment page
    monitorObservable: null,
    monitorObserver: null,

    // Price flicker helper classes for checkout and cart
    checkoutTotalClasses: [],
    preloadCartClasses: [],

    /**
     * Checkout related
     */

    // Shopify defined api host
    shopifyAPIHost: null,

    // Checkout data template for retrieved data
    // Merged into checkoutData via jQuery.extend()
    checkoutDataRetrievedTemplate:{
        currency: null,
        lineItems: [],
        lineItemDiscountTotal: 0.00,    // Sum of all line item discounts
        subtotal: 0.00,
        discount: {                     // Cart wide discount (has it's own line under subtotal)
            title: null,
            amount: 0.00
        },
        giftCards: {
            cards: [],
            totalAmount: null
        }
    },

    // Checkout data template for calculated values
    // Merged into checkoutData via jQuery.extend()
    checkoutDataCalculatedTemplate: {

        // Keep track of the variant IDs that the current discount code applies to.
        // This information is passed into /getCart and stash, since the backend does not have this info.
        discountCodeUsed: null, // Current discount code
        discountItems: [], // Variant IDs

        shipping: {
            selectedShippingOption: false,  // True if on the shipping page and an option is selected (one will always be selected on init)
                                            // If true, then we ignore the values from backend (getCart)
            gipShippingPresent: null, // True if there are GIP shipping options present

            original: null, // Shop original shipping price
            converted: null, // Converted shipping price with localize

            dutiesOriginal: null, // Duties/taxes from GIP shipping
            dutiesConverted: null, // Converted with localize

            // Replace the amount in summary. Save the replaced amount as this
            // amount should not be changed even when the user selects different
            // shipping options.
            summaryPrice: null,

            freeShipping: false, // Flag for ensuring free shipping
        },
        taxes: {
            original: null,             // Shop original
            converted: null,            // Converted tax; value added on top of subtotal
            converted_included: null    // Converted tax included in prices; value not added on top of subtotal
        },
        total: {
            original: null, // Shop original
            calculated: null // Calculated by summing checkout data
        },
    },

    // Checkout data. Will be initialized to checkoutDataRetrievedTemplate + checkoutDataCalculatedTemplate
    // We need to use separate templates because retrieved and calculated values are updated at different times.
    checkoutData: null,

    /**
     * Feature-flag for enabling features that are pending full release
     */
    edgeMode: false,

    /**
     * Element selector for klarna on product page
     */
    klarnaAnchorProduct: false,

    /**
     * Theme, `dark` or null only
     */
    klarnaPlacementTheme: null,

    /**
     * placement key, not enforced
     * available values are
     * `credit-promotion-auto-size`
     * `credit-promotion-badge`
     * `credit-promotion-small`
     * `credit-promotion-standard`
     */
    klarnaPlacementKey: 'credit-promotion-badge',

    // UUID of the Shopify checkout id... if this is null, then we are on a
    // 'shop' page and not actually checking out yet.  Therefore we can do
    // things differently.
    shopifyCartId: null,

    // Flag indicating if this is the "Thank You" page
    isThankYouPage: false,

    // Redirect page after submitting checkout, could be '/forward' or
    // '/processing' pages.
    isShopifyRedirect: false,

    // Country code selected for shipping, since it isn't available on the
    // payment method page.
    shippingCountry: null,

    // Country code selected for billing.  This may be the same as the shipping
    // country code.
    billingCountry: null,

    // Id of the GIP Shopify gateway pulled from the DOM
    gipGatewayId: null,

    // Track all the gateways from Shopify
    shopifyGateways: null,

    // Device fingerprint
    gipFingerprint: null,

    // Shipping option handle
    selectedShippingOptionHandle: null,

    // The order ID *sometimes* available on thank-you page
    // and *always* available on the order summary page.
    postCheckoutPageOrderID: null,

    // The checkout token available on all checkout pages as Shopify.Checkout.token.
    // This is not to be confused with the tokens in the URLS which changes.
    checkoutToken: null,

    /**
     * after localize api request exacutes, denotes whether localized currency matches store currency
     */
    isStoreCurrency: null,

    /**
     * denotes whether the customer's currency is allowed
     */
    isActiveCurrency: null,

    /**
     * denotes whether badge or banner should be shown
     */
    isBadgeRequired: null,

    /**
     * denotes whether klarna messaging should be handled based on Reach handling checkout
     *   Theoretically, this is is the equivalent of and should be handled by processStoreCurrency,
     *   but the use may interfere with current functionality.
     */
    isKlarnaMessagingSupported: null,
    
    /**
     * if true, will remove decimal when `.00` 
     * @param {Boolean}
     */
    hideZeroDecimals: null,
};