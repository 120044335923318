import "./types";
import { Fragment, h } from "preact";
import { setPaymentMethodId } from "./index";
import { useState } from "preact/hooks";
import { validateIssuer } from "./appInternal";
import { setters, store } from "./AppC";
import { CardImagesC } from "./CardImagesC";
import * as Constants from "../constants";
import { Config } from "../config";

export const PaymentMethodOtherC = (props) => {
    const maxCards = 4;

    /** @type {Store} */
    const store = props.store;

    /** @type {PaymentMethod} */
    const method = props.paymentMethod;

    const inputId = "gip_custom_payment_method_" + method.Id;

    const cardIds = method._card_ids_available || [];

    const methodBody =
        store.paymentMethodId === method.Id ? (
            <MethodBodyC method={method}></MethodBodyC>
        ) : (
            /**
             * if payment method is not selected, create an empty hidden div
             * because some css rules used by merchants use the last-child selector
             */
            <div
                class="radio-wrapper content-box__row content-box__row--secondary"
                style={"display:none"}
            ></div>
        );

    return (
        <Fragment>
            <div class="radio-wrapper content-box__row">
                <div class="gip_payment-input radio__input">
                    <input
                        class="input-radio"
                        id={inputId}
                        type="radio"
                        value={Config.gipGatewayId}
                        onChange={() => setPaymentMethodId(method.Id)}
                        checked={store.paymentMethodId === method.Id}
                        name="checkout[payment_gateway]"
                    ></input>
                </div>
                <div class="radio__label content-box__emphasis payment-method-wrapper">
                    {/* show label image or text */}
                    {method._name_logo_img_src ? (
                        <label
                            for={inputId}
                            class="gip_payment-label radio__label__primary content-box__emphasis"
                        >
                            <img
                                alt={method.Name}
                                class="offsite-payment-gateway-logo"
                                src={method._name_logo_img_src}
                            ></img>
                            <span class="visually-hidden">{method.Name}</span>
                        </label>
                    ) : (
                        <label
                            class="gip_payment-label radio__label__primary"
                            for={inputId}
                        >
                            {method.Name}
                        </label>
                    )}
                    <CardImagesC
                        cardIds={cardIds}
                        maxCards={maxCards}
                    ></CardImagesC>
                </div>
            </div>
            {methodBody}
        </Fragment>
    );
};

const MethodBodyC = (props) => {
    /** @type {PaymentMethod} */
    const method = props.method;

    return (
        <div class="radio-wrapper content-box__row content-box__row--secondary">
            <MethodIssuerC method={method} />
            <div class="blank-slate">
                <i class="blank-slate__icon icon icon--offsite"></i>
                <p>
                    After clicking “Complete order”, you will be redirected to{" "}
                    {method.Name} complete your purchase securely.
                </p>
            </div>
        </div>
    );
};

const MethodIssuerC = (props) => {
    /** @type {PaymentMethod} */
    const method = props.method;

    const issuers = Array.isArray(method.Issuers) ? method.Issuers : [];

    if (issuers.length > 0) {
        // track if the error was already shown
        // this is to dynamically show errors on empty only after first submit
        const [isDirty, setIsDirty] = useState(false);

        const options = issuers.map((issuer) => {
            return <option value={issuer.Id}>{issuer.Name}</option>;
        });

        const updateIssuer = (e) => {
            const value = e.target.value;
            setters.setIssuerId(value);

            // if the input is "dirty" it means that there was an error already
            if (isDirty) {
                // run validation to determine that is set
                // but do it after the setter has a chance to execute
                setTimeout(() => validateIssuer(), 0);
            }
        };

        const showError = issuerHasErrors(method.Id);
        console.log({ showError, method, errs: store.validationErrors });
        if (showError) setIsDirty(true);
        const errorClass = showError && "gip_field--error";

        return (
            <div class="fieldset" data-credit-card-fields="" dir="ltr">
                <div class="field field--required" class={errorClass}>
                    <div class="field__input-wrapper field__input-wrapper--select">
                        <select
                            placeholder="Card Type"
                            autocomplete="off"
                            class="field__input field__input--select"
                            aria-required="true"
                            onChange={updateIssuer}
                            style="padding: 0.92857em 0.78571em;"
                        >
                            <option value="">Issuer</option>
                            {options}
                        </select>
                    </div>
                    {showError && (
                        <div class="field--error">
                            <p class="field__message--error">
                                {Constants.MESSAGES.SelectIssuer}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return null;
};

/**
 * @param {string} method PaymentMethod Id
 */
const issuerHasErrors = (methodId) => {
    /** @type {ValidationErrors} */
    const issuerErrors = store.validationErrors.Issuer || {};

    if (issuerErrors[methodId] !== undefined) return true;

    return false;
};
