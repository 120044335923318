/**
 * PublicAPI
 *
 * Define any functions to expose to global window object here.
 */

import $ from "jquery";
import * as Utils from "./utils";
import {gipShopify} from "./gip-shopify";
import {PriceScanner} from "./price-scanner";
import {Config} from "./config";


// Expose jQuery to global
// Disabled as this was conflicting with Shopify and other plugins
// e.g. won't be able to add to cart
//window.$ = $;
//window.jQuery = $;


const PublicAPI = {

    /**
     * Force the monitor to run immediately
     */
    forceUpdate:() => {
        gipShopify.forceUpdate();
    },

    /**
     * Manually reset the calculated cart totals
     *
     * This is useful to hide any unconverted totals until the next convert
     * loop, which may have been added/created by custom scripts. The only
     * element that needs the custom script to replace the original value back
     * is `.gip-cart-additional-fee > span.money`, which if not done will result
     * in the converted value being converted again.
     */
    resetCalculatedCartTotals:() => {
        PriceScanner.resetCartTotals();
    },

    /**
     * Select currency
     */
    selectCurrency: currency => {

        // Set currency to user selected value
        Utils.selectCurrency(currency);

        Utils.refreshPageAfterCurrencyChange();
    },

    /**
     * Test our formatting function with all required currencies
     *
     * @see https://docs.withreach.com/display/PUB/Displaying+Currencies
     */
    currencyFormats: () => {

        let testAmount = 1000;

        let currencies = Utils.currencies;

        for (let i = 0; i < currencies.length; i++) {
            const currency = currencies[i];
            let display = Utils.getDisplayedPrice(testAmount, false, currency.code, currency.symbol);

            Utils.clog(3, display);

            // Test getNumericPrice()
            /*if(Utils.getNumericPrice(display) !== testAmount){
                console.log('=== error found with getNumericPrice ===');
                break;
            }*/
        }
    },

    /**
     * Return a promise for localize data
     *
     * It will be resolved with localize data when localize finished, or false if it fails.
     */

    getLocalizeData: () => {
        return Config.localizeDataDeferred.promise;

    },

    /**
     * Stop execution/timer
     */
    stop(){
        gipShopify.stop();
    }
};

window.GIP = PublicAPI;