/**
 * public methods and variables for use within app
 */
import { Config } from "../config";
import * as Constants from "../constants";
import * as Utils from "../utils";
import * as Sentry from "@sentry/browser";
import { ERRORS, getPaymentMethod, PAYMENT_METHOD_CLASS } from "./index";
import { Promise } from "q";
import { store, setters } from "./AppC";
const debug = {
    iframeLoadTimeoutRunCount: 0,
};

let timoutId = null;

/**
 * should be overridden by index.mount()
 * @param {Error} err
 */
export let extListener = function (err) {
    console.error(err);
};

/**
 * @param {Function} errListener
 */
export let setListener = (listener) => {
    extListener = listener;
};

export const IFRAME_MESSAGES = {
    SUBMIT: "submit",
    GET_CURRENT_STATE: "get-current-state",
    GET_HEIGHT: "get-height",
    UPDATE_CARDS: "update-cards",
};

export const IFRAME_EVENTS = {
    READY: "ready",
    STATE_CHANGE: "state-change",
    CARDTYPE_CHANGE: "cardtype-change",
    CURRENT_STATE: "current-state",
    HEIGHT: "height",
};

let iframeSrcValue = null;
/**
 * Get iframe src using function, so that the mid is valid at time of use
 * @return {string} I frame url with relevant query parameters
 */
export const getIframeSrc = () => {
    if (iframeSrcValue === null) {
        const params = [
            `mid=${encodeURIComponent(Config.merchantId)}`,
            `fingerprintId=${encodeURIComponent(Config.gipFingerprint)}`,
        ];
        const stylesheets = getStylesheets();
        if (stylesheets) params.push(`stylesheet=${stylesheets}`);

        let iframeSrc = Constants.CHECKOUT_SRC[Config.env];

        // if user defined a custom value, allow overriding as long as it is not in prod
        if (
            Config.env !== Constants.ENV_PROD &&
            localStorage.getItem(Constants.GIP_LS_CHECKOUT_HOST) !== null
        ) {
            Utils.clog(3, "Iframe source is being overridden");
            iframeSrc = localStorage.getItem(Constants.GIP_LS_CHECKOUT_HOST);
        }

        if (!iframeSrc || iframeSrc.toLowerCase().indexOf("http") !== 0) {
            throw "invalid link or secure checkout " + iframeSrc;
        }

        iframeSrcValue = `${iframeSrc}?${params.join("&")}`;
    }
    return iframeSrcValue;
};

/** @type {HTMLIFrameElement|null} */
export let iframeWindow = null;

/**
 * @param {HTMLIFrameElement} iframe
 */
export const setIframeWindow = (iframe) => {
    iframeWindow = iframe;
};

/**
 * Sends a message to iframe
 * @param {string} event
 * @param {any} [data]
 * @param {boolean} [failSilently]
 */
export const sendMsgToIframe = (event, data = {}, failSilently = false) => {
    try {
        iframeWindow.contentWindow.postMessage({ event, data }, "*");
        return;
    } catch (error) {
        if (failSilently) return;
        Sentry.captureException(error);
        throw error;
    }
};

export const sendCardsToIframe = () => {
    sendMsgToIframe(IFRAME_MESSAGES.UPDATE_CARDS, store.cards, true);
};

export const formState = {
    value: null,
    payload: null,
    formStateSubscribers: [],
    setValue: (payload) => {
        formState.payload = { ...payload };
        formState.value = payload.value;
        formState.formStateSubscribers.forEach((c) => c(formState.value, formState.payload));
    },
    /**
     * @param {Function} callback {value, payload}
     */
    subscribe: (callback) => {
        formState.formStateSubscribers.push(callback);
    },
    unsubscribe: (callback) => {
        const i = formState.formStateSubscribers.indexOf(callback);
        if (i >= 0) {
            formState.formStateSubscribers.splice(i, 1);
        }
    },
};

/**
 * Handle messages from the iframe form
 * @param {*} message
 */
export const handleIframeMessage = (message) => {
    if (message.data && message.data.event) {
        const { event, data } = message.data;
        Utils.clog(5, "handle message:" + event + ":" + JSON.stringify(data));

        switch (event) {
            case IFRAME_EVENTS.STATE_CHANGE:
                formState.setValue(data);

                // get size in case the card type dropdown is added or removed
                if (formState.value !== "SUBMIT_READY") {
                    initHeightUpdate();
                }
                break;

            case IFRAME_EVENTS.CURRENT_STATE:
                formState.setValue(data);
                break;

            case IFRAME_EVENTS.CARDTYPE_CHANGE:
                if (data && data.value) {
                    setters.setCardType(data.value + "");
                } else {
                    setters.setCardType(null);
                }
                break;

            case IFRAME_EVENTS.HEIGHT:
                const height = data.value;
                updateHeight(height);
                break;

            case IFRAME_EVENTS.READY:
                setters.setIframeReady(true);
                // if payment methods have been received already, send them to iframe
                if (store.paymentMethods.length > 0) {
                    sendCardsToIframe();
                }
                break;

            default:
                break;
        }
    }
};

/**
 * trigger a get-height in iframe and update height
 */
export const initHeightUpdate = () => {
    try {
        sendMsgToIframe("get-height", {}, true);
        const doc = document.getElementById("gip-payment").document;
        const height = doc.body.scrollHeight + "px";
        updateHeight(height);
    } catch (error) {}
};

function updateHeight(height) {
    if (iframeWindow.height !== height + "px") {
        iframeWindow.height = height + "px";
    }
}

// get stylesheets from document that is hosted on shopify cdn
export const getStylesheets = () => {
    let stylesheets = [...document.querySelectorAll('link[rel="stylesheet"]')]
        .map((link) => link.href)
        .filter((href) => href.includes("cdn.shopify.com"))
        .map((href) => encodeURIComponent(href))
        .join(",");

    return stylesheets;
};

export const submitCredit = () => {
    let promise = Promise((resolve, reject) => {
        if (![null, "SUBMIT_READY"].includes(formState.value)) {
            reject(ERRORS.FORM_NOT_READY);
            return;
        }

        // setup state change listener
        const onStateChange = (state, payload) => {
            switch (state) {
                case "VALIDATE_ERROR":
                    formState.unsubscribe(onStateChange);
                    // if validate error occured, no need to send
                    reject(ERRORS.VALIDATION_FAILED);
                    break;

                case "STASH_DONE":
                    formState.unsubscribe(onStateChange);
                    /** @type {CardInfo} */
                    const cardInfo = payload.cardInfo;
                    resolve(cardInfo.PaymentMethod);
                    break;

                case "STASH_ERROR":
                    formState.unsubscribe(onStateChange);
                    // if stash encounters an error, do not try again
                    reject(ERRORS.NON_RECOVERABLE);
                    break;

                default:
                    break;
            }
        };

        formState.subscribe(onStateChange);

        // send a message
        sendMsgToIframe(IFRAME_MESSAGES.SUBMIT);
    });
    return promise;
};

/**
 * wait 20 seconds for iframe to load
 * if a ready message from iframe is not received then notify checkout to abort reach payment
 */
export const initIframeLoadTimeout = () => {
    if (timoutId) timoutId = clearTimeout(timoutId);

    debug.iframeLoadTimeoutRunCount++;

    if (store.iframeReady) return;

    timoutId = setTimeout(handleIframeLoadError, 20 * 1000);
};

/**
 * Check if the iframe was not loaded and handle error
 */
export const handleIframeLoadError = () => {
    // clear timeout in case the method called from iframe onload handler
    if (timoutId) timoutId = clearTimeout(timoutId);
    if (store.iframeReady) return;

    Sentry.addBreadcrumb({
        category: "checkout-app",
        message: "could not load iframe",
        data: {
            iframeSrc: iframeSrcValue,
            ...store,
            debug,
        },
        level: "debug",
    });
    extListener(ERRORS.NON_RECOVERABLE);
};

/**
 * @returns {Boolean}
 */
export const validateCountryData = () => {
    const countryDataErrors = store.validationErrors.countryDataErrors || {};

    // get values from the UI
    const countryData = store.countryData;

    /** @type {Object} the values from const*/
    const countryDataRef = getCountryDataFieldsObject();

    // loop through country fields and check validatation agains definition in consts
    //  not using Object.values because of ie 11
    const values = Object.keys(countryData).map(function (e) {
        return countryData[e];
    });

    values.forEach(({ key, value }) => {
        const field = countryDataRef[key];
        if (field === undefined) return;

        try {
            const valid = value.match(field.regex) && field.validate(value);
            if (!valid) countryDataErrors[key] = true;
        } catch (error) {
            Utils.clog(0, error);
            // 🤷‍♂️ cannot validate for some reason, probably doesn't exist
            // send to sentry just in case
            Sentry.captureException(error, { tags: { errorType: "Validate Country Data Fail" } });
        }
    });

    /** @type {ValidationErrors} */
    const validationErrors = {
        ...store.validationErrors,
        CountryData: countryDataErrors,
    };

    // 👇 hack to prevent subsequent calls from overriding value
    // if different validators are called one after the other, this will prevent overriding
    // the validators using old data from store.validationErrors, which requires the store
    // changes to propagate.
    store.validationErrors.CountryData = countryDataErrors;

    // update using setter as-well, to make sure validation is triggered
    setters.setValidationErrors(validationErrors);

    // prepare and send debug message
    const errs = Object.keys(countryDataErrors)
        .filter((k) => countryDataErrors[k])
        .join(", ");
    Utils.clog(4, "Country data errors: ", errs);

    return Object.keys(countryDataErrors).length === 0;
};

export const removeErrorOnCountryData = (key) => {
    const countryDataErrors = store.validationErrors.CountryData || {};
    if (countryDataErrors[key]) {
        delete countryDataErrors[key];
    }

    /** @type {ValidationErrors} */
    const validationErrors = {
        ...store.validationErrors,
        CountryData: countryDataErrors,
    };

    // 👇 hack to prevent subsequent calls from overriding value
    // if different validators are called one after the other, this will prevent overriding
    // the validators using old data from store.validationErrors, which requires the store
    // changes to propagate.
    store.validationErrors.CountryData = countryDataErrors;

    // update using setter as-well, to make sure validation is triggered
    setters.setValidationErrors(validationErrors);
};

/**
 * @returns {Boolean}
 */
export const validateIssuer = () => {
    const issuerErrors = {};

    const paymentMethod = getPaymentMethod(true);

    // is issuer needed?
    let valid = true;

    if (paymentMethod?.Issuers && paymentMethod.Issuers.length > 0) {
        // check if issuer is not set, return false
        // is valid
        valid = store.issuerId !== null && store.issuerId !== "";

        if (!valid) {
            issuerErrors[paymentMethod.Id] = true;
        }
    }

    // update errors

    /** @type {ValidationErrors} */
    const validationErrors = {
        ...store.validationErrors,
        Issuer: issuerErrors,
    };

    // 👇 hack to prevent subsequent calls from overriding value
    // if different validators are called one after the other, this will prevent overriding
    // the validators using old data from store.validationErrors, which requires the store
    // changes to propagate.
    store.validationErrors.Issuer = issuerErrors;

    // update using setter as-well, to make sure validation is triggered
    setters.setValidationErrors(validationErrors);

    return valid;
};

/**
 * Get only the country-specific fields as object
 */
const getCountryDataFieldsObject = () => {
    const country = Utils.getCountry();
    return Constants.COUNTRY_DATA[country] || {};
};

/**
 * Get the country-specific fields as array with key
 */
export const getCountryDataFields = () => {
    const countryObj = getCountryDataFieldsObject();
    return Object.keys(countryObj).map((key) => ({
        key: key, // key used for matchieng the fields
        id: countryObj[key].id, // id used for formatting the field name before sending data
        mask: countryObj[key].mask,
        name: countryObj[key].name,
        placeholder: countryObj[key].placeholder,
        regex: countryObj[key].regex,
        validate: countryObj[key].validate,
        formatFunction: countryObj[key].formatFunction || ((value) => value),
    }));
};

export const getOtherPaymentMethods = () => {
    if (!store.paymentMethods) return null;
    const otherMethods = store.paymentMethods.filter(
        ({ Class }) => Class !== PAYMENT_METHOD_CLASS.CARD
    );
    return otherMethods;
};
