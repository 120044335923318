import {Config} from "./config";
import * as Utils from "./utils";
import {PriceScanner} from "./price-scanner";
import {priceCache} from "./price-cache";
import $ from "jquery";
import * as Constants from "./constants"
import {Observable} from "rxjs";

export class Converter {

    constructor() {

        this.priceScanner = new PriceScanner();

        // Flag indicating if we are currently running a price conversion
        this.convertPending = false;

        // Flag indicating if converter has been aborted
        this.convertAborted = false;

        // Initialize observable for the converter
        this.convertObservable = Observable.create(observer => {
            this.convertObserver = observer;
        });

        // Flag to set whether to convert as soon as possible,
        // ie. immediately after current convert.
        this.convertASAP = false;
        this.convertObservable.subscribe(() => {
            if (this.convertASAP) {
                this.convert();
                this.convertASAP = false;
            }
        });

    }

    init() {

        // If FLC only, abort convert.
        if (Config.flcOnly) {
            Utils.abortConverter('FLC only, do not convert prices');
        }

        Config.monitorObservable.subscribe(() => {

            this.convert();

        });

    }

    /**
     * Convert all the prices on the page
     */
    convert() {

        // Don't proceed if aborted
        if (this.convertAborted) return;

        // Don't proceed if we are already processing
        if (this.convertPending) return;

        this.convertPending = true;

        // Scan all prices
        let newPriceIDs = this.priceScanner.scan();

        // Convert and cache prices
        priceCache.update().then(priceUpdateSuccess => {

            // Handle price cache update response
            if (priceUpdateSuccess) {

                // Update the page
                this.priceScanner.updatePrices(newPriceIDs);
            }

            // Process currency selector
            if (Config.currencySelectorProcessed === false) {

                Utils.clog(3, 'Process currency selector');

                // If localize was successful
                if (Config.localizeData) {

                    // If the local currency is already in the list
                    // select it and remove the (local) option
                    let selectOption = $(Constants.GIP_CURRENCY_SELECT).children("[value=" + Config.localizeData.Currency + "]");
                    if (selectOption.length >= 1) {
                        $(Constants.GIP_CURRENCY_SELECT).children("[value='-1']").remove();
                        selectOption.prop("selected", true);
                    } else {
                        // Set the (local) option to what we just detected and select it
                        $(Constants.GIP_CURRENCY_SELECT_LOCAL).html(Config.localizeData.Currency).prop("selected", true);
                        $(Constants.GIP_CURRENCY_SELECT).val(-1);

                        // Add CSS rule for the localize currency logo
                        let currencySelectorStyleEle = $('style#gointerpay_currency_selector_styles');
                        if(currencySelectorStyleEle.length === 1){

                            currencySelectorStyleEle.append('.gip_currency_select_label.gip-currency-class-' + Config.localizeData.Currency.toLowerCase() + '{ background: no-repeat 12px/30px 20px url(https://st.rch.io/flags/' + Utils.getSymbolForCurrency(Config.localizeData.Currency, 'country_code') + '.png); }');

                        }
                    }

                }

                // Hide local option for now if there is a problem with localize
                // or not localizing at all.
                else {
                    $(Constants.GIP_CURRENCY_SELECT).children("[value='-1']").remove();
                }

                Config.currencySelectorProcessed = true; // Don't process again
            }

            this.convertPending = false;

            if (this.convertObserver) {
                this.convertObserver.next(true);
            }

            //Utils.clog(3, 'Finished convert loop');

        }).done(); // End the Q promise chain to allow any exceptions to be thrown.

    }

    /**
     * Set the next convert to run as soon as possible
     *
     * If no convert is running, it will run immediately. If a convert is
     * running it will queue to run immediately after.
     */
    forceConvert() {
        if (!this.convertPending) {
            this.convert();
        } else {
            this.convertASAP = true;
        }
    }

    abort() {
        if(this.convertAborted){
            Utils.clog(3, "Converter already aborted");
        } else {
            this.convertAborted = true;
            Utils.clog(3, "Converter aborted");
        }
    }

    aborted() {
        return this.convertAborted;
    }
}