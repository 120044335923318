import { h } from "preact";
import { PAYMENT_TYPES } from "./index";
import {
    getIframeSrc,
    setIframeWindow,
    initHeightUpdate,
    handleIframeLoadError,
} from "./appInternal";
import { SpinnerC } from "./SpinnerC";

export const PaymentMethodReachC = (props) => {
    /** @type {Store} */
    const store = props.store;
    const className =
        "radio-wrapper content-box__row content-box__row--secondary card-fields-container card-fields-container--loaded card-fields-container--transitioned";

    // determine visibility
    const showBody = store.paymentMethodId === PAYMENT_TYPES.CREDIT;

    // assign visibility via style
    const style = `${showBody ? "" : "display:none;"} padding:0; margin:0;`;

    const iframeSrc = getIframeSrc();

    /**
     * executes if there's a change in iframe (mount/show/hide)
     * @param {HTMLIFrameElement} iframe
     */
    const iframeChange = (iframe) => {
        if (!iframe) return;

        setIframeWindow(iframe);

        // if onload function is not defined, add resize handler
        if (!iframe.onload)
            iframe.onload = () => {
                initHeightUpdate();
            };

        // if iframe is visible, execute resize handler
        if (showBody) initHeightUpdate();
    };

    return (
        <div class={className} style={style}>
            <div
                class="fieldset"
                data-credit-card-fields=""
                style="padding:0; margin:0;"
                dir="ltr"
            >
                <SpinnerC show={!store.iframeReady} />
                <iframe
                    src={iframeSrc}
                    width="100%"
                    height="0"
                    style="border:none; overflow: hidden;"
                    scrolling="no"
                    id="gip-payment"
                    sandbox="allow-forms allow-modals allow-popups allow-scripts allow-same-origin"
                    ref={iframeChange}
                ></iframe>
            </div>
        </div>
    );
};
