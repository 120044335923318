import { h } from "preact";

export const CardImagesC = (props) => {
    let { cardIds, maxCards, excludeCards } = props;
    if (!excludeCards) excludeCards = [];

    const numCards = cardIds.length;

    const CardsF = cardIds
        .filter((cardId) => {
            // remove list of excluded cards
            return excludeCards.indexOf(cardId) < 0;
        })
        .slice(0, maxCards)
        .map((cardId) => {
            const src = `https://st.rch.io/methods/${cardId}.png`;
            const style = `height: auto; display: inline-block; vertical-align: middle; padding-bottom:5px`;
            return (
                <li class="payment-icon" style="margin-right: 3px;">
                    <div style="position: relative; max-width:40px; max-height:30px;">
                        <img src={src} style={style}></img>
                    </div>
                </li>
            );
        });

    const AndMoreF =
        numCards > maxCards ? (
            <li class="payment-icon-list__more">
                <span class="content-box__small-text">and more… </span>
            </li>
        ) : null;

    return (
        <div class="radio__label__accessory">
            <ul role="list">
                {CardsF}
                {AndMoreF}
            </ul>
        </div>
    );
};
