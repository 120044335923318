import * as Utils from './utils';
import {Config} from "./config";
import {Observable, Subject} from "rxjs";

// Number of times to execute the loop before changing the timeout
const MAX_MONITOR_LOOPS = 10; // 100000

// Maximum timeout, in MS
const MAX_MONITOR_TIMEOUT = 1500;

// Factor to change timeout by each time we backoff.
const MONITOR_BACKOFF_FACTOR = 2;

export class Monitor {

    constructor(){

        /**
         * Monitor properties
         */

        // Loop counter to determine backoff
        this.monitorCount = 0;

        // How often we're currently checking the page, in ms, initially.
        this.monitorTimeout = 125; // 2000

        // JS interval id
        this.monitorInterval = null;

    }

    /**
     * Init the polling loop
     */
    init(){

        //Utils.clog(3, 'Scan loop started!');

        // Initialize observable to be used for the monitor
        Config.monitorObserver = new Subject();
        Config.monitorObservable = Observable.create(observer => {
            Config.monitorObserver.subscribe(observer);
        });

        // Initialize observable to be used for every localizeData event
        Config.localizeDataObservable = Observable.create(observer => {
            Config.localizeDataObserver = observer;
        });

        // Kick it off
        this.monitorInterval = setInterval(() => {
            this.backoffFunc();
        }, this.monitorTimeout);
    }

    /**
     * A function we'll call forever once the back off is done
     */
    infiniteFunc() {

        // Just call update
        this.update();
    }

    /**
     * Back off mechanism
     */
    backoffFunc() {

        // Call update
        this.update();

        // Update the counter
        this.monitorCount++;

        // Figure out if we need to restart the interval
        if (this.monitorCount === MAX_MONITOR_LOOPS) {

            this.monitorTimeout = Math.min(MAX_MONITOR_TIMEOUT,
                this.monitorTimeout *
                MONITOR_BACKOFF_FACTOR);

            // If we've got to our max, then just call the func
            // that never checks again.
            let func = MAX_MONITOR_TIMEOUT === this.monitorTimeout
                ? this.infiniteFunc : this.backoffFunc;

            // Reset
            this.monitorCount = 0;
            if (this.monitorInterval) {
                clearInterval(this.monitorInterval);

                this.monitorInterval = setInterval(() => {
                    func.call(this);
                }, this.monitorTimeout);
            }
        }
    }

    /**
     * Run for each loop
     */
    update(){

        if(Config.monitorObserver) {
            Config.monitorObserver.next(true);
        }

    }

    /**
     * Stop the monitor
     */
    stop(){

        if (this.monitorInterval) {
            Utils.clog(3, "Stopping monitor");
            //Utils.clog(3, this.monitorInterval);
            clearInterval(this.monitorInterval);
            this.monitorInterval = null;
        }

    }

}