import * as Constants from './constants'
import * as Utils from "./utils"
import {Config} from "./config";
import {gipShopify} from "./gip-shopify";
import $ from "jquery";
import * as Sentry from "@sentry/browser";

/**
 * Log with version info
 * @param {number} level 0:error, 1:warning, 2:info, 3:verbose, 4:debug, 5:silly
 * @param  {...any} args 
 */
export function clog(level, ...args) {

    let elements = ["GIP Shopify Connect v" + Constants.VERSION + ":"].concat(args);

    // Use the console object for the 'this' argument as some older browsers require it
    // See: https://bugs.chromium.org/p/chromium/issues/detail?id=48662
    // Resolved in: https://bugs.chromium.org/p/chromium/issues/detail?id=179628
    if(level <= Config.logLevel) {
        if (level === 0) {
            console.error.apply(console, elements);
        } else if (level === 1) {
            console.warn.apply(console, elements);
        } else {
            console.log.apply(console, elements);
        }
    }

}

/**
 * Abort and cleanup any conversion functions
 */
export function abortConverter(mesg) {

    if (mesg) clog(2, mesg);

    clog(3, 'Aborting price conversion');

    // Don't update again
    gipShopify.abortConverter();

    // Reset all page changes
    if(Config.preloadStyleEle && Config.preloadStyleEle.parentNode){

        // Use parentNode.removeChild() instead of simply remove(), due to IE11
        // not supporting it.
        // See: https://stackoverflow.com/a/40009566/11296191
        Config.preloadStyleEle.parentNode.removeChild(Config.preloadStyleEle);
    }

    // Default to store currency, but without caching
    Utils.selectCurrency(Config.storeCurrency, true);
}

/**
 * Load Shopify api host domain
 *
 * This is only available in checkout pages.
 */
export function loadShopifyAPIHost() {

    let shopifyAPIHost = ((window.Shopify || {}).Checkout || {}).apiHost;

    // Parse out '.myshopify.com'
    if(typeof shopifyAPIHost === 'string'
        && shopifyAPIHost.indexOf('.myshopify.com') !== -1){

        shopifyAPIHost = shopifyAPIHost.substring(0, shopifyAPIHost.indexOf('.myshopify.com'));
    }

    Config.shopifyAPIHost = shopifyAPIHost;
}

/**
 * Save shipping country to localstorage
 */
export function selectShippingCountry(country) {

    Config.shippingCountry = country;

    localStorage.setItem(Constants.GIP_LS_SHIPPING_COUNTRY, country);

}

/**
 * Get shipping country from localstorage
 */
export function loadCacheShippingCountry() {

    Config.shippingCountry = localStorage.getItem(Constants.GIP_LS_SHIPPING_COUNTRY);

}

/**
 * Load disable flag from cache
 */
export function loadGIPDisable() {

    // Fetch it
    let disable = localStorage.getItem(Constants.GIP_LS_DISABLE);

    return disable === '1';
}

/**
 * Load debug flag from cache
 */
export function loadGIPDebug() {

    // Fetch it
    Config.debugMode = asBool(localStorage.getItem(Constants.GIP_LS_DEBUG));

    if (Config.debugMode) {

        window.GIP_CONFIG = Config;

    }

    return Config.debugMode;
}

/**
 * Load test flag from localstore
 * Allows access to internals for unit(like) testing
 */
export function loadGIPTest() {
    if (asBool(localStorage.getItem(Constants.GIP_LS_TEST))) {

        window.GIP_UTIL = this;
        window.GIP_API = require("./gip-api").GIPAPI;

    }
}

/**
 * Load debug flag from cache
 */
export function loadGIPLogLevel() {

    // Fetch it
    let logLevel = localStorage.getItem(Constants.GIP_LS_LOG_LEVEL);

    // if not defined, use default value
    if(parseInt(logLevel) !== NaN) Config.logLevel = parseInt(logLevel);
    
    return Config.logLevel;
}

/**
 * Load trace ID from cache
 */
export function loadTraceID() {

    // Fetch it
    Config.traceID = localStorage.getItem(Constants.GIP_TRACE);
}

/**
 * Load user selected currency from cache
 */
export function loadCacheCurrency() {

    let currency;
    let reachCurrency = getParameterByName(Constants.GIP_CURRENCY_QUERY_PARAM);
    if(reachCurrency){

        clog(4, 'Load currency from query params');

        currency = reachCurrency;

    } else {

        clog(4, 'Load cached currency');

        currency = localStorage.getItem(Constants.GIP_LS_USER_CURRENCY);

        // If there is a currency tag set, and it is not the same as the existing one,
        // reset the user's currency.
        if(Config.currencyTag
            && localStorage.getItem(Constants.GIP_LS_USER_CURRENCY_TAG) !== Config.currencyTag){

            localStorage.setItem(Constants.GIP_LS_USER_CURRENCY_TAG, Config.currencyTag);

            currency = null;

            clog(4, 'New currency tag detected, user currency cleared');
        }
    }

    // Set initial currency
    selectCurrency(currency);
}

/**
 * Set user selected currency and save to cache (localStorage)
 */
export function selectCurrency(currencyCode, noCache = false) {

    clog(2, 'Selected currency:', currencyCode);

    // Clear currency code classes
    // Removes any '.gip-currency-class-*' classes from '.gip-currency-class' elements
    let gip_currency_class_eles = $(Constants.GIP_CURRENCY_CLASS);
    gip_currency_class_eles.removeClass((index, className) => {
        return (className.match (/(^|\s)gip-currency-class-\S+/g) || []).join(' ');
    });

    if (currencyCode && currencyCode.length === 3) {

        Config.selectedCurrency = currencyCode.toUpperCase();

        if(noCache === false) {

            // Save in localStorage
            localStorage.setItem(Constants.GIP_LS_USER_CURRENCY, Config.selectedCurrency);

        }

        // Select currency selector option
        $(Constants.GIP_CURRENCY_SELECT).children('option').removeAttr("selected");
        $(Constants.GIP_CURRENCY_SELECT).val(Config.selectedCurrency);

        // Display selected option text
        let selectedOptionText = $.trim($(Constants.GIP_CURRENCY_SELECT + " option:selected").first().text());
        if(selectedOptionText) {
            $(Constants.GIP_CURRENCY_SELECT_TEXT).text(selectedOptionText);
        } else {
            $(Constants.GIP_CURRENCY_SELECT_TEXT).text(Config.selectedCurrency);
        }

        // Display currency code
        $(Constants.GIP_CURRENCY_CODE_CLASS).text(Config.selectedCurrency);

        // Add a class with currency code ie. '.gip-currency-class-usd'
        // to any element with the class '.gip-currency-class'
        gip_currency_class_eles.addClass('gip-currency-class-' + Config.selectedCurrency.toLowerCase());

    } else {

        // Clear currency selector
        $(Constants.GIP_CURRENCY_SELECT).children('option').removeAttr("selected");

        // Clear selected option text
        $(Constants.GIP_CURRENCY_SELECT_TEXT).text('-');

        Config.selectedCurrency = null;

        // Remove cached currency
        localStorage.removeItem(Constants.GIP_LS_USER_CURRENCY);
    }
}

/**
 * Reload the page and remove the currency param after the currency is changed manually.
 *
 * Reloading is necessary for now since our script shuts down conversion when switching to store currency,
 * and there's too many issues with booting back up.
 */
export function refreshPageAfterCurrencyChange(){

    // Remove the currency query param before reloading so we don't force the currency again.
    let newURL = Utils.removeURLParameter(location.href, Constants.GIP_CURRENCY_QUERY_PARAM);

    location.href = newURL;

    // If there's a hash in the URL, we will need to reload manually as the browser
    // doesn't do this by default.
    if(newURL.indexOf('#') !== -1){
        location.reload();
    }
}

/**
 * Function to handle Safari and FireFox network issue.
 *
 * Safari and FF will abort a network request with status 0 and call the error handler
 * when the page is reloaded or navigates to the next page before the request finishes.
 * This is by design and doesn't happen in other browsers so we'll have to handle it
 * specifically. In our case, we will simply stop all processing and let the next
 * page load.
 * See: https://bugzilla.mozilla.org/show_bug.cgi?id=1084399
 */
export function safariAndFFAbortedError(jqXHR){

    // Testing jqXHR
    /*Utils.clog(3, 'jqXHR:', jqXHR);
    Utils.clog(3, 'jqXHR.status:', jqXHR.status);
    Utils.clog(3, 'jqXHR.statusText:', jqXHR.statusText);
    Utils.clog(3, 'jqXHR.state():', jqXHR.state());*/

    // A 0 status means network request is unfinished. If this happens in an error handler
    // then we know for sure it was aborted by either Safari or FF.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/status
    if(jqXHR.status === 0){

        clog(1, 'Safari / FF has aborted a request');

        return true;

    } else {

        return false;

    }

}

// Save payment type so we know which payment method to auto select
// if the payment section or page reloads.
// The value can be one of the following:
//   - 'CREDIT' for any cards
//   – the ID of the Reach payment method
//   – '_SHOPIFY_' for any shopify gateway
export function savePreviousPaymentType(type) {
    localStorage.setItem(Constants.GIP_LS_PAYMENT_TYPE, type);
}
export function loadPreviousPaymentType() {
    return localStorage.getItem(Constants.GIP_LS_PAYMENT_TYPE);
}
export function clearPreviousPaymentType() {
    localStorage.removeItem(Constants.GIP_LS_PAYMENT_TYPE);
}

/**
 * Get the numeric value of a price string or element
 * 
 * @param {String|JQuery} target string or element to get price from
 * @returns {Number}
 */
export function getNumericPrice(target) {

    let displayedPriceString,
        priceNumeric,
        match,
        nestedStrArr,
        displayedPriceNumeric,
        displayedPriceBeforeNumber;

    // get strings that have number from element and children
    nestedStrArr = removeNonNumericTexts(getNestedText(target));

    if (nestedStrArr.length === 1) {
        // only one value found, assign to displayedPriceString to process
        displayedPriceString = nestedStrArr[0];
    } else if (nestedStrArr.length > 1) {
        // more than one value found, assign first to displayedPriceString to process
        // and register a breadcrumb
        displayedPriceString = nestedStrArr[0];
        Sentry.addBreadcrumb('getNumericPrice value uncertain');
    } else {
        // no values found, add breadcrumb and return 0
        Sentry.addBreadcrumb('getNumericPrice value missing');
        return 0;
    }
    
    match = displayedPriceString.match(/([^\d]*)([\d,.]+)/);

    if(match) {

        // Get the amount in the string
        displayedPriceNumeric = match[2];

        // Turn amount into number
        priceNumeric = parseFloat(displayedPriceNumeric.replace(/,/g, ''));

        if(!Utils.isNumber(priceNumeric)){
            priceNumeric = 0;
        }

        // Get the substring before the amount value
        displayedPriceBeforeNumber = match[1];

        // Check for negative sign
        if (displayedPriceBeforeNumber.indexOf('-') > -1) {
            priceNumeric = -Math.abs(priceNumeric);
        }

    } else {
        priceNumeric = 0;
    }

    return priceNumeric;
}

/**
 * Get the displayed price string
 * 
 * @param {Number} convertedPrice
 * @param {*} excludeCurrency
 * @param {string} currency
 * @param {string} symbol
 * @returns {string} Formtatted price
 */
export function getDisplayedPrice(convertedPrice, excludeCurrency, currency, symbol) {

    if (currency) {
        // Use provided currency
        if(symbol) {
            // Use provided symbol
        } else {
            // Lookup symbol
            symbol = getSymbolForCurrency(currency);
        }
    } else {
        currency = Config.selectedCurrency || Config.storeCurrency;
        symbol = getSymbolForCurrency(currency);
    }

    if (excludeCurrency === true) {
        // Exclude normally
    } else if (excludeCurrency === false || excludeCurrency === undefined) {
        // Include normally
    } else {
        // If a currency code is provided here, it will process smartly. The currency will then
        // be excluded only if it matches the store currency. The currency code will be set to
        // this as well.
        currency = excludeCurrency;
        excludeCurrency = (currency === Config.storeCurrency);
        // Lookup symbol
        symbol = getSymbolForCurrency(currency);
    }

    // Use the Internationalization API to get the value with proper formatting
    let code_value = new Intl.NumberFormat(
        'en-US', {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'code'
        }).format(Math.abs(convertedPrice));

    // Chrome returns GBP 123, FF/Safari return GBP123
    // The space after GBP could be a non standard space,
    // so we replace all types of spaces
    let value = code_value.replace(/^[A-Z]{3}[\u0020\u202F\u00A0\u2000\u2001\u2003]*/, '');

    //clog(3, convertedPrice, code_value, value, currency);

    // Exception for USD
    // Never show USD except for CAD and AUD based stores
    if(currency === 'USD' && Config.storeCurrency !== 'CAD' && Config.storeCurrency !== 'AUD' ){
        excludeCurrency = true;
    }

    if (convertedPrice % 1 === 0 && Config.hideZeroDecimals && !isCheckoutPage()) {
        // remove .00, .000, .0000, .00000
        value = value.replace(/\.0{2,5}/g, '');
    }

    const price = (convertedPrice < 0 ? "- " : "")
        + (symbol || "")
        + value
        + (excludeCurrency ? "" : " " + currency);

    return price
    // TODO: FIX SHOP-506 issue that doesn't translate special characters
    // currently breaks discount codes
    // return '&#x202A;' + (convertedPrice < 0 ? "- " : "")
    //     + (symbol || "")
    //     + value
    //     + (excludeCurrency ? "" : " " + currency)
    //     + '&#x202C;&lrm;';
}

/**
 * Convert an amount using the current available RateOffer.
 * Only used as a helper to convert small amounts locally. Since all conversion
 * is done on the server, should limit usage.
 */
export function convert(amount){
    if(!isNumber(amount)){
        return null;
    } else {
        let convertedAmount = parseFloat(amount) * Config.localizeData.RateOffer.Rate;

        return isZeroDecimalCurrency(Config.localizeData.Currency) ? Math.round(convertedAmount) : Utils.round(convertedAmount);
    }
}

/**
 * Returns true if checkout page.
 */
export function isCheckoutPage(){
    return Config.shopifyCartId != null;
}

/**
 * Returns true if customer info page.
 */
export function isCustomerInfoPage(){
    // Pattern to check nested members
    return ((window.Shopify || {}).Checkout || {}).step === 'contact_information';
}

/**
 * Returns true if checkout page.
 */
export function isShippingPage(){
    return $('div.section.section--shipping-method').length === 1
        // Pattern to check nested members
        && ((window.Shopify || {}).Checkout || {}).step === 'shipping_method';
}

/**
 * Returns true if payment method page.
 */
export function isPaymentMethodPage(){
    return Config.shopifyCartId
        // Pattern to check nested members
        && ((window.Shopify || {}).Checkout || {}).step === 'payment_method';
}

/**
 * Returns true if Shopify redirect page.
 */
export function isShopifyRedirectPage(){
    return Config.isShopifyRedirect;
}

/**
 * Returns true if thank you page.
 */
export function isThankYouPage(){
    return Config.isThankYouPage
        // Pattern to check nested members
        && ((window.Shopify || {}).Checkout || {}).step === 'thank_you';
}

/**
 * Returns true if order summary page.
 */
export function isOrderSummaryPage(){
    return ((window.Shopify || {}).Checkout || {}).isOrderStatusPage === true;
}

/**
 * Check if product page
 */
export function isProductPage(){
    return window.location.pathname.indexOf('/products/') !== -1;
}

/**
 * Check if cart
 */
export function isCartPage(){
    return window.location.pathname.indexOf('/cart') !== -1;
}

/**
 * Check if a value is a number
 *
 * Works with problematic types such as null, undefined, ""
 */
export function isNumber(val) {
    return !isNaN(parseFloat(val)) && isFinite(val);
}

/**
 * Returns the country we are dealing with on the page
 *
 * This will be the billing country selected if the shopper chooses to specify
 * one, the shipping country if they don't and the country we got from the last
 * call to /localize if something goes horribly wrong.
 * @param {Boolean} logError default is true, if set to false will skip logging error
 */
export function getCountry(logError = true) {

    let code =
        // The billing country as defined on the PM page.
        Config.billingCountry ||
        // If they don't select to specify a different billing country.  We
        // should have picked this up on the cust info page.
        Config.shippingCountry ||
        // Something is likely to have gone wrong, but we can try to recover
        // by using the last country retrieved from the localize call.
        (Config.localizeData ? Config.localizeData.Country : null) || 
        // in some cases, this code may need to be taken from the Payment Page
        // using a text compare
        getCodeByAddress();

    // It would be very bad if we didn't have a country.
    if (logError && code == null) {
        clog(1, "Unable to determine country code.");
    }

    return code;
}

/**
 * Round floats to 2 decimals
 */
export function round(amount) {

    return Math.round(amount * 100) / 100;

}

// Modifying Array prototype is a bad idea – broke the code in other places.
// See: https://stackoverflow.com/questions/948358/adding-custom-functions-into-array-prototype
// Array.prototype.diff = function(a) {
//     return this.filter(function(i) {return a.indexOf(i) < 0;});
// };

export function arrayDiff(array1, array2){
    return array1.filter(function(i) {return array2.indexOf(i) < 0;});
}

/**
 * @typedef Currency
 * @type {object}
 *
 * @property {string} code ISO 4217 Code
 * @property {string} symbol 
 * @property {string} name
 * @property {string} country_code
 */

 /**
  * @type {Array.<Currency>}
  */
export const currencies = [
    {code: 'AED', symbol: 'د.إ', name: 'United Arab Emirates Dirham', country_code: 'AE'},
    {code: 'ALL', symbol: 'Lek', name: 'Albanian Lek', country_code: 'AL'},
    {code: 'AMD', symbol: '֏', name: 'Armenian Dram', country_code: 'AM'},
    {code: 'ARS', symbol: '$', name: 'Argentina Peso', country_code: 'AR'},
    {code: 'AUD', symbol: '$', name: 'Australian Dollar', country_code: 'AU'},
    {code: 'AWG', symbol: 'ƒ', name: 'Aruban Guilder', country_code: 'AW'},
    {code: 'AZN', symbol: '₼', name: 'Azerbaijani manat', country_code: 'AZ'},
    {code: 'BAM', symbol: 'KM', name: 'Bosnia-Herzegovina Convertible Marks', country_code: 'BA'},
    {code: 'BBD', symbol: '$', name: 'Barbados Dollar', country_code: 'BB'},
    {code: 'BDT', symbol: 'Tk', name: 'Bangladesh Taka', country_code: 'BD'},
    {code: 'BGN', symbol: 'лв', name: 'New Bulgarian Lev', country_code: 'BG'},
    {code: 'BHD', symbol: 'BD', name: 'Bahraini Dinar', country_code: 'BH'},
    {code: 'BMD', symbol: '$', name: 'Bermudian Dollar', country_code: 'BM'},
    {code: 'BND', symbol: '$', name: 'Brunei Dollar', country_code: 'BN'},
    {code: 'BOB', symbol: '$b', name: 'Bolivia Boliviano', country_code: 'BO'},
    {code: 'BRL', symbol: 'R$', name: 'Brazilian Real', country_code: 'BR'},
    {code: 'BSD', symbol: '$', name: 'Bahamian Dollar', country_code: 'BS'},
    {code: 'BWP', symbol: 'P', name: 'Botswana Pula', country_code: 'BW'},
    {code: 'BYN', symbol: 'Br', name: 'Belarusian Ruble', country_code: 'BY'},
    {code: 'BZD', symbol: 'BZ$', name: 'Belize Dollar', country_code: 'BZ'},
    {code: 'CAD', symbol: '$', name: 'Canadian Dollar', country_code: 'CA'},
    {code: 'CHF', symbol: 'Fr.', name: 'Swiss Franc', country_code: 'CH'},
    {code: 'CLP', symbol: '$', name: 'Chile Peso', country_code: 'CL'},
    {code: 'CNY', symbol: '¥', name: 'China Yuan Renminbi', country_code: 'CN'},
    {code: 'COP', symbol: '$', name: 'Colombian Peso', country_code: 'CO'},
    {code: 'CRC', symbol: '₡', name: 'Costa Rican Colon', country_code: 'CR'},
    {code: 'CUP', symbol: '₱', name: 'Cuba Peso', country_code: 'CU'},
    {code: 'CVE', symbol: '$', name: 'Cape Verdi Escudo', country_code: 'CV'},
    {code: 'CZK', symbol: 'Kč', name: 'Czech Koruna', country_code: 'CZ'},
    {code: 'DJF', symbol: 'Fdj', name: 'Djibouti Franc', country_code: 'DJ'},
    {code: 'DKK', symbol: 'kr', name: 'Danish Krone', country_code: 'DK'},
    {code: 'DOP', symbol: 'RD$', name: 'Dominican Republic Peso', country_code: 'DR'},
    {code: 'DZD', symbol: 'DA', name: 'Algerian Dinar', country_code: 'DZ'},
    {code: 'EEK', symbol: 'kr', name: 'Estonian Kroon', country_code: 'ES'},
    {code: 'EGP', symbol: 'جنيه', name: 'Egyptian Pound', country_code: 'EG'},
    {code: 'ETB', symbol: 'Br', name: 'Ethiopian Birr', country_code: 'ET'},
    {code: 'EUR', symbol: '€', name: 'Euro', country_code: 'EU'},
    {code: 'FJD', symbol: '$', name: 'Fiji Dollar', country_code: 'FJ'},
    {code: 'FKP', symbol: '£', name: 'Falkland Islands Pound', country_code: 'FK'},
    {code: 'GBP', symbol: '£', name: 'British Pound', country_code: 'GB'},
    {code: 'GEL', symbol: 'ლ', name: 'Georgian Lari', country_code: 'GE'},
    {code: 'GGP', symbol: '£', name: 'Guernsey Pound', country_code: 'GG'},
    {code: 'GHS', symbol: '¢', name: 'Ghana Cedi', country_code: 'GH'},
    {code: 'GIP', symbol: '£', name: 'Gibraltar Pound', country_code: 'GI'},
    {code: 'GMD', symbol: 'D', name: 'Gambia Delasi', country_code: 'GA'},
    {code: 'GNF', symbol: 'FG', name: 'Guinea Franc', country_code: 'GU'},
    {code: 'GTQ', symbol: 'Q', name: 'Guatemala Quetzal', country_code: 'GT'},
    {code: 'GYD', symbol: '$', name: 'Guyanese Dollar', country_code: 'GY'},
    {code: 'HKD', symbol: '$', name: 'Hong Kong Dollar', country_code: 'HK'},
    {code: 'HNL', symbol: 'L', name: 'Honduras Lempira', country_code: 'HN'},
    {code: 'HRK', symbol: 'kn', name: 'Croatia Kuna', country_code: 'HR'},
    {code: 'HUF', symbol: 'Ft', name: 'Hungary Forint', country_code: 'HG'},
    {code: 'IDR', symbol: 'Rp', name: 'Indonesian Rupiah', country_code: 'ID'},
    {code: 'ILS', symbol: '₪', name: 'New Israeli Scheqel', country_code: 'IL'},
    {code: 'IMP', symbol: '£', name: 'Isle of Man Pound', country_code: 'IM'},
    {code: 'INR', symbol: '₹', name: 'India Rupee', country_code: 'IN'},
    {code: 'ISK', symbol: 'kr', name: 'Iceland Krona', country_code: 'IS'},
    {code: 'JEP', symbol: '£', name: 'Jersey Pound', country_code: 'JE'},
    {code: 'JMD', symbol: 'J$', name: 'Jamaican Dollar', country_code: 'JM'},
    {code: 'JOD', symbol: 'د.ا', name: 'Jordanian Dinar', country_code: 'JO'},
    {code: 'JPY', symbol: '¥', name: 'Japanese Yen', country_code: 'JP'},
    {code: 'KES', symbol: 'KSh', name: 'Kenyan Shilling', country_code: 'KE'},
    {code: 'KGS', symbol: 'лв', name: 'Kyrgyzstan Som', country_code: 'KG'},
    {code: 'KHR', symbol: '៛', name: 'Cambodia Riel', country_code: 'KH'},
    {code: 'KMF', symbol: 'CF', name: 'Comoro Franc', country_code: 'KM'},
    {code: 'KRW', symbol: '₩', name: 'South-Korean Won', country_code: 'KR'},
    {code: 'KWD', symbol: 'ك', name: 'Kuwait Dinar', country_code: 'KW'},
    {code: 'KYD', symbol: '$', name: 'Cayman Islands Dollar', country_code: 'KY'},
    {code: 'KZT', symbol: '₸', name: 'Kazakhstani Tenge', country_code: 'KZ'},
    {code: 'LAK', symbol: '₭', name: 'Laos Kip', country_code: 'LA'},
    {code: 'LBP', symbol: 'ل.ل', name: 'Lebanese Pound', country_code: 'LB'},
    {code: 'LKR', symbol: '₨', name: 'Sri Lanka Rupee', country_code: 'LK'},
    {code: 'LRD', symbol: '$', name: 'Liberia Dollar', country_code: 'LR'},
    {code: 'LYD', symbol: 'ل.د', name: 'Libyan Dinar', country_code: 'LY'},
    {code: 'MAD', symbol: 'MAD', name: 'Moroccan Dirham', country_code: 'MA'},
    {code: 'MDL', symbol: 'L', name: 'Moldovia Leu', country_code: 'MD'},
    {code: 'MKD', symbol: 'ден', name: 'Macedonia Denar', country_code: 'MK'},
    {code: 'MNT', symbol: '₮', name: 'Mongolia Tugrik', country_code: 'MN'},
    {code: 'MOP', symbol: 'MOP$', name: 'Macau Pataca', country_code: 'MO'},
    {code: 'MRO', symbol: 'أوقية‎;', name: 'Mauritania Ouguiya', country_code: 'MR'},
    {code: 'MUR', symbol: '₨', name: 'Mauritius Rupee', country_code: 'MU'},
    {code: 'MVR', symbol: 'Rf', name: 'Maldives Rufiyaa', country_code: 'MV'},
    {code: 'MWK', symbol: 'MK', name: 'Malawi Kwacha', country_code: 'MW'},
    {code: 'MXN', symbol: '$', name: 'Mexican Peso', country_code: 'MX'},
    {code: 'MYR', symbol: 'RM', name: 'Malaysia Ringgit', country_code: 'MY'},
    {code: 'MZN', symbol: 'MT', name: 'Mozambique Metical', country_code: 'MZ'},
    {code: 'NAD', symbol: '$', name: 'Namibia Dollar', country_code: 'NA'},
    {code: 'NGN', symbol: '₦', name: 'Nigerian naira', country_code: 'NG'},
    {code: 'NIO', symbol: 'C$', name: 'Nicaragua Cordoba Oro', country_code: 'NI'},
    {code: 'NOK', symbol: 'kr', name: 'Norwegian Krone', country_code: 'NO'},
    {code: 'NPR', symbol: '₨', name: 'Nepalese Rupee', country_code: 'NP'},
    {code: 'NZD', symbol: '$', name: 'New Zealand Dollar', country_code: 'NZ'},
    {code: 'OMR', symbol: '﷼', name: 'Rial Omani', country_code: 'OM'},
    {code: 'PAB', symbol: 'B/.', name: 'Panamanian Balboa', country_code: 'PA'},
    {code: 'PEN', symbol: 'S/.', name: 'Peruvian Nuevo Sol', country_code: 'PE'},
    {code: 'PGK', symbol: 'K', name: 'New Guinea Kina', country_code: 'PG'},
    {code: 'PHP', symbol: '₱', name: 'Philippine Peso', country_code: 'PH'},
    {code: 'PKR', symbol: '₨', name: 'Pakistan Rupee', country_code: 'PK'},
    {code: 'PLN', symbol: 'zł', name: 'Polish Zloty', country_code: 'PL'},
    {code: 'PYG', symbol: 'Gs', name: 'Paraguay Guarani', country_code: 'PY'},
    {code: 'QAR', symbol: '﷼', name: 'Qatari Rial', country_code: 'QA'},
    {code: 'ROL', symbol: 'lei', name: 'Romanian Lei', country_code: 'RO'},
    {code: 'RON', symbol: 'lei', name: 'New Romanian Lei', country_code: 'RO'},
    {code: 'RSD', symbol: 'Дин.', name: 'Serbian Dinar', country_code: 'RS'},
    {code: 'RUB', symbol: '₽', name: 'Russia Ruble', country_code: 'RU'},
    {code: 'RWF', symbol: 'FRw', name: 'Rwanda Franc', country_code: 'RW'},
    {code: 'SAR', symbol: '﷼', name: 'Saudi Riyal', country_code: 'SA'},
    {code: 'SBD', symbol: '$', name: 'Solomon Island Dollar', country_code: 'SB'},
    {code: 'SCR', symbol: '₨', name: 'Seychelles Rupee', country_code: 'SC'},
    {code: 'SEK', symbol: 'kr', name: 'Swedish Krona', country_code: 'SE'},
    {code: 'SGD', symbol: '$', name: 'Singapore Dollar', country_code: 'SG'},
    {code: 'SHP', symbol: '£', name: 'St. Helena Pound', country_code: 'SH'},
    {code: 'SLL', symbol: 'Le', name: 'Sierra Leone Leone', country_code: 'SL'},
    {code: 'SOS', symbol: 'S', name: 'Somalia Shilling', country_code: 'SO'},
    {code: 'SRD', symbol: '$', name: 'Suriname Dollar', country_code: 'SR'},
    {code: 'STD', symbol: '₣', name: 'Sao Tome & Principe Dobra', country_code: 'ST'},
    {code: 'SVC', symbol: '$', name: 'El Salvador Colón', country_code: 'SV'},
    {code: 'SYP', symbol: '£', name: 'Syria Pound', country_code: 'SY'},
    {code: 'SZL', symbol: 'E', name: 'Swaziland Lilangeni', country_code: 'SZ'},
    {code: 'THB', symbol: '฿', name: 'Thailand Baht', country_code: 'TH'},
    {code: 'TND', symbol: 'دينار‎', name: 'Tunisian Dinar', country_code: 'TN'},
    {code: 'TOP', symbol: 'T$', name: 'Tonga Pa’anga', country_code: 'TO'},
    {code: 'TRY', symbol: '₺', name: 'New Turkish Lira', country_code: 'TR'},
    {code: 'TTD', symbol: 'TT$', name: 'Trinidad & Tobago Dollar', country_code: 'TT'},
    {code: 'TVD', symbol: '$', name: 'Tuvalu Dollar', country_code: 'TV'},
    {code: 'TWD', symbol: 'NT$', name: 'Taiwan New Dollar', country_code: 'TW'},
    {code: 'TZS', symbol: 'TSh', name: 'Tanzanian Shilling', country_code: 'TZ'},
    {code: 'UAH', symbol: '₴', name: 'Ukraine Hryvnia', country_code: 'UA'},
    {code: 'UGX', symbol: 'UGX', name: 'Uganda Shilling', country_code: 'UG'},
    {code: 'USD', symbol: '$', name: 'United States Dollar', country_code: 'US'},
    {code: 'UYU', symbol: '$U', name: 'Peso Uruguayo', country_code: 'UY'},
    {code: 'UZS', symbol: 'лв', name: 'Uzbekistani Som', country_code: 'UZ'},
    {code: 'VEF', symbol: 'Bs', name: 'Venezuela Bolívar', country_code: 'VE'},
    {code: 'VND', symbol: '₫', name: 'Vietnamese New Dong', country_code: 'VN'},
    {code: 'VUV', symbol: 'VT', name: 'Vanuatu Vatu', country_code: 'VU'},
    {code: 'WST', symbol: 'WS$', name: 'Samoan Tala', country_code: 'WS'},
    {code: 'XAF', symbol: 'CFA', name: 'CFA Franc BEAC', country_code: 'CM'},
    {code: 'XCD', symbol: '$', name: 'East Carribean Dollar', country_code: 'AG'},
    {code: 'XOF', symbol: 'CFA', name: 'CFA Franc BCEAO', country_code: 'BJ'},
    {code: 'XPF', symbol: 'CFP', name: 'CFP Franc', country_code: 'PF'},
    {code: 'YER', symbol: '﷼', name: 'Yemeni Rial', country_code: 'YE'},
    {code: 'ZAR', symbol: 'R', name: 'South African Rand', country_code: 'SA'},
    {code: 'ZMW', symbol: 'Z$', name: 'Zambia Kwacha', country_code: 'ZM'},
];

/**
 * currency objects with code as key
 * @type {Object.<string, Currency>}
 */
export const currenciesIndexed = {};

// populate currenciesIndexed
currencies.forEach(currency => {currenciesIndexed[currency.code]=currency});

/**
 * Lookup symbol or other details for a currency code
 *
 * @param {string} currencyCode - 3 digit currency code
 * @param {string} key _optional_ defaults to `symbol`
 */
export function getSymbolForCurrency(currencyCode, key = 'symbol') {
    let return_value = null;
    let currency = currenciesIndexed[currencyCode];
    if(currency !== undefined && currency[key] !== undefined) {
        return_value = currency[key];
    }
    return return_value;
}


export const zeroDecimalCurrencies = [
    'BIF',
    'BYR',
    'CLF',
    'CLP',
    'CVE',
    'DJF',
    'GNF',
    'ISK',
    'JPY',
    'KMF',
    'KRW',
    'PYG',
    'RWF',
    'UGX',
    'UYI',
    'VND',
    'VUV',
    'XAF',
    'XOF',
    'XPF',
    'IDR',
    'MGA',
    'MRO'
];

/**
 * Check if a currency is zero decimal
 */
export function isZeroDecimalCurrency(code) {

    return zeroDecimalCurrencies.indexOf(code) !== -1;

}

/**
 * Encode html entities
 */

let escapeChars = {
    '¢': 'cent',
    '£': 'pound',
    '¥': 'yen',
    '€': 'euro',
    '©':'copy',
    '®': 'reg',
    '<': 'lt',
    '>': 'gt',
    '"': 'quot',
    '&': 'amp',
    '\'': '#39'
};

let regexString = '[';
for(let key in escapeChars) {
    regexString += key;
}
regexString += ']';

let regex = new RegExp( regexString, 'g');

export function escapeHTML(str) {
    return str.replace(regex, function(m) {
        return '&' + escapeChars[m] + ';';
    });
}

/**
 * Gateway code name mapping
 *
 * Names are from Shopify and are case-sensitive and matched exactly.
 */

let gatewayCodeMap = {

    // 'all' will override behaviour of the flag, and include all gateways besides Reach's.
    'all': '_ALL_GATEWAYS_',

    'shopify': 'Credit card',
    'shoppay_v2': 'Shop Pay',
    'sezzle': 'Sezzle',
    'sezzle_v2': 'Buy Now, Pay Later with Sezzle',
    'paypal': 'PayPal',
    'amazon': 'Amazon Pay',
    'cash'  : 'Cash on Delivery (COD)',
    'afterpay' : 'Afterpay', // Australia afterpay
    'afterpay_na' : 'Afterpay North America',
    'bank': 'Bank Deposit',
    'money_order': 'Money Order',
    'klarna': 'Buy now, pay later with Klarna',
    'klarna_v2': 'Klarna',
    'klarna_v3': 'Klarna - Flexible payments',
    'klarna_es': 'Compra ahora, paga después con Klarna', //es lang 
    'klarna_fr': 'Acheter maintenant, payer plus tard avec Klarna', //fr lang 
    'klarna_de_invoice': 'Rechnung mit Klarna', //de lang - Klarna Invoice 
    'klarna_de_installment': 'Ratenkauf mit Klarna', //de lang - Klarna Installments 
    'klarna_de_immediately': 'Sofort bezahlen mit Klarna', //de lang - Klarna Pay Now 
    'klarna_it': 'Compra ora, paga dopo con Klarna', //it lang 
    'clearpay': 'Clearpay',
    'clearpay_uk': 'Clearpay UK',
    'laybuy': 'Laybuy',
    'paybright': 'PayBright',
    'quadpay': 'Zip - Pay in 4 (Quadpay)',
    'alipay': 'Alipay',
    'chinapay': 'China Payments',
    'wechatpay': 'WeChatPay',
    'nihaopay': 'NihaoPay',
    'splitit': 'Splitit: Pay over time with no application',
    'zip': 'Zip - Pay in 4 (Quadpay)',
    'zip_v2': 'Pay in 4 with Zip (previously Quadpay)',
    'zip_v3': 'Pay in 4 with Zip',
    'zip_v4': 'Zip – Pay in installments',
    'tabby': '.Split in 4 payments with Tabby. No fees قسمها إلى 4 اقساط شهرية مع تابي. بدون رسوم.‎', //Tabby for MetroBrazil
    'komoju_in_store': 'コンビニ決済 - KOMOJU',  //Convenience store payment-KOMOJU
    'komoju_transfer': '銀行振込 - KOMOJU',  //Bank transfer-KOMOJU
    'komoju_pay_easy': 'ペイジー決済 - KOMOJU', //Pay-easy payment-KOMOJU
    'alma_fr_installment' : 'Alma - Paiement en 3 fois avec Alma',
    'spotii': 'Spotii - Pay in 4 instalments Cost-Free ◉◎◎◎ قسطها على 4 دفعات مجاناً',
	'clearpay_eur' : 'Clearpay EUR',
    'rapyd' : 'Rapyd Payments'
};

/**
 * Converts codes to names
 *
 * Any invalid codes will simply not be added to the list.
 */
export function gatewayNamesFromCodes(code_arr) {

    let names = [];

    for (let i = 0; i < code_arr.length; i++) {
        const code = code_arr[i];

        if(gatewayCodeMap[code] !== undefined){
            names.push(gatewayCodeMap[code]);
        }
    }

    return names;
}

/**
 * Remove a specific url parameter
 *
 * @see https://stackoverflow.com/a/1634841/11296191
 */
export function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    let urlparts = url.split('?');
    if (urlparts.length >= 2) {

        let prefix = encodeURIComponent(parameter) + '=';
        let pars = urlparts[1].split(/[&;]/g);

        //reverse iteration as may be destructive
        for (let i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}

/**
 * Prepend a new parameter to the query string
 *
 * @see: https://stackoverflow.com/a/487049
 */
export function addURLParameter(key, value) {

    key = encodeURI(key);
    value = value !== undefined ? encodeURI(value) : undefined;

    let kvp = document.location.search.substr(1).split('&');

    // Remove any empty space elements
    kvp = kvp.filter(obj => {
        return obj !== "";
    });

    let i=kvp.length;
    let x;
    while(i--) {
        x = kvp[i].split('=');

        if (x[0]===key) {
            if(x[1] !== undefined) {
                x[1] = value;
            }
            kvp[i] = x.join('=');
            break;
        }
    }
    if(i<0) {
        kvp.unshift(value !== undefined ? [key,value].join('=') : key);
    }

    return location.origin + location.pathname + '?' + kvp.join('&') + location.hash;
}

/**
 * Get query parameters
 *
 * @see https://stackoverflow.com/a/901144/11296191
 */
export function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * parse variables encoded with `{{  }}` using passed object
 * 
 * @param {string} templateString 
 * @param {Object} variables 
 * @returns {string}
 */
export function parseTemplate(templateString, variables = {}) {

    let parsedStr = templateString;

    for (const key of Object.keys(variables)) {
        const regexp = new RegExp("\\{\\{\\s?" + key + "\\s?\\}\\}", "gm");
        parsedStr = parsedStr.replace(regexp, variables[key]);
    }

    return parsedStr;
}

/**
 * Convert variable to boolean
 * @param {*} val
 * @returns Boolean
 */
export function asBool(val) {
    try {
        return Boolean(JSON.parse(val + ""));
    } catch (error) {
        return false;
    }
}

/**
 * convert line feed and new line to line break html
 * @param {string} str 
 */
export const nl2br = str => str.replace(/(\r\n|\n\r|\r|\n)/g, "<br/>");

/**
 * Find country code using the address.
 * 
 * This is intended for when user is redirected to the Payment page
 *   without having the coutry data available via the Information
 *   page. It relies on the address text, that is compared to a list
 *   of countries in a list of billing addresses in options of the 
 *   checkout_billing_address_country select element. It is needed
 *   when shopify warehouse stores redirect to the Shipping page.
 */
function getCodeByAddress() {
    const addressSelector = 'address.address';
    const optionsSelector = '#checkout_billing_address_country option';
    const adrEl = document.querySelector(addressSelector);
  
    if (isCheckoutPage() && adrEl) {
        const addressText = adrEl.innerText;
        const options = Array.prototype.slice.apply(document.querySelectorAll(optionsSelector))
        let option;
        for (let i = 0; i < options.length; i++) {
           option = options[i];
            if (option.value && option.dataset && option.dataset.code && addressText.indexOf(option.value) >= 0) {
                return option.dataset.code;
            }
        } 
    }
    return null
}

/**
 * Determine if variable is a jquery element
 * @param {*} el 
 * @returns {Boolean}
 */
function isJqueryElement(el) {
    try {
        return el.jquery.length > 0
    } catch (error) {
        // do nothing
    }
    return false
}

/**
 * Find html text in nested elements recursievly
 * helps in getting text value no matter how deep it is in case
 * that another library nests it. Returns all string values as 
 * an array, and makes no judgment on what it finds
 * 
 * @param {*} el 
 * @returns {Array<String>}
 */
function getNestedText(el) {
    let arr = []

    if(isJqueryElement(el)) {

        // conents may be child elements or text
        // text is highest priority, and added to array first

        if (el.text().length > 0) {

            // add text if available
            arr = [].concat(arr, [el.text().trim()])

        }

        if (el.children().length > 0) {

            // dig deeper
            el.children().each(i => {
                const e = el.children()[i];
                arr = [].concat(arr, getNestedText($(e)));
            })

        }
        
    } else {

        arr = [].concat(arr, el)

    }

    return arr
}

/**
 * removes any values from array that do not include a number
 * @param {Array} arr 
 * @returns {Array}
 */
function removeNonNumericTexts(arr) {
    return arr.filter(val => val.match(/\d/gi)!== null)
}

/**
 * Make a Promise-based ajax get call
 * @param {*} url 
 * @param {*} payload 
 */
export async function ajaxGet(url, payload) {
    const target = url+'?'+queryStringEncode(payload);

    return new Promise((resolve, reject) => {
        var req = new XMLHttpRequest();
        req.open("get", target, true);
        req.timeout = Constants.AJAX_TIMEOUT;
        req.onreadystatechange = function receiveResponse() {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    resolve(this.response);
                } else {
                    reject(this.response);
                }
            }
        };
        req.send(payload);
        req = null;
    });
}

/**
 * encode object to query string
 * use this instead of URL and URLSearchParams to extend support to IE11
 * @param {Object} obj 
 * @returns {String}
 */
const queryStringEncode = (obj) => Object.keys(obj).map((k) => `${k}=${encodeURIComponent(obj[k])}`).join("&");

/**
 * debounce
 * usage: `debounce(callback,2000)(args)`
 * 
 * @param {Function} callback 
 * @param {Number} delay in milliseconds
 * @returns {Function}
 */
export const debounce = (callback, delay = 250) => {
    let timeoutId
    return (...args) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            timeoutId = null
            callback(...args)
        }, delay)
    }
};

/**
 * get lang value from html document
 * defaults to `en` if not found
 */
 export const getHtmlLang = () => {
    let lang = document.querySelector("html").lang;
    if (lang && lang.length < 2) {
        lang = "en";
    }
    return lang;
};

/**
 * Get RFC 1766 locale code (ie: fr-CA)
 * @returns {String}
 */
export const getLocaleCode = () => getHtmlLang() + '-' + getCountry();
