/* global APP_VERSION */
/* global CHECKOUT_HOST */

export const VERSION = APP_VERSION;

// Local storage for disable flag
export const GIP_LS_DISABLE = "GIP_DISABLE";

// Local storage for debug flag
export const GIP_LS_DEBUG = "GIP_DEBUG";

// Local storage for testing flag
export const GIP_LS_TEST = "GIP_TEST";

// Local storage for debug flag
export const GIP_LS_LOG_LEVEL = "GIP_LOG_LEVEL";

// Local storage for banner flag
export const GIP_LS_BANNER_SEEN = "GIP_BANNER_SEEN";

/**
 * allow overriding whitelist gateway using local storage
 */
export const GIP_LS_WHITELIST_GATEWEAYS = "GIP_WHITELIST_GATEWEAYS";

// Local storage for trace ID
export const GIP_TRACE = "GIP_TRACE";

export const ENV_PROD = "production";
export const ENV_SANDBOX = "sandbox";
export const ENV_DEV = "development";
export const ENV_QA = "qa";

// Shopify selectors mapping
// Use a key here rather than hardcoding the selector in multiple places, if possible.
export const SHOPIFY_SELECTORS = {
    checkoutShippingMethods: 'div.section.section--shipping-method .content-box > div.content-box__row span.radio__label__accessory > span',            // Checkout shipping methods
    checkoutDiscountedShippingMethods: 'div.section.section--shipping-method .content-box > div.content-box__row span.radio__label__accessory > del',   // Checkout discounted shipping methods

    checkoutSubtotal: 'tr.total-line.total-line--subtotal td.total-line__price > span:first-child',                                                     // Checkout subtotal

    checkoutDiscountsAndGiftCards: 'tr.total-line.total-line--reduction > td.total-line__price > span:first-child',                                     // Checkout discounts and giftcards
    checkoutDiscounts: 'tr.total-line.total-line--reduction[data-discount-type] > td.total-line__price > span:first-child',                             // Checkout discounts

    // TODO remove checkoutDiscountsOld after Mar 2, 2020 checkout update
    checkoutDiscountsOld: 'tr.total-line.total-line--reduction[data-discount-success] > td.total-line__price > span:first-child',                          // Checkout discounts old

    checkoutGiftCards: 'tr.total-line.total-line--reduction[data-giftcard-success]',                                                                    // Checkout gift cards
    checkoutDiscountsMobile: 'div.section.section--reductions.hidden-on-desktop form > span.content-box__row__right',                                   // Checkout discounts mobile

    checkoutShipping: 'tr.total-line.total-line--shipping > td.total-line__price > span:first-child',                                                   // Checkout shipping total
    checkoutDuties: 'tr.total-line.total-line--duties > td.total-line__price > span:first-child',                                                       // Checkout duties (custom element)

    checkoutTax: 'tr.total-line.total-line--taxes > td.total-line__price > span:first-child',                                                           // Checkout taxes
    checkoutTaxInPriceLine: 'tr.total-line > th.total-line__name.payment-due-label > span.payment-due-label__taxes',                                    // Checkout taxes included in price entire line
    checkoutTaxInPrice: 'tr.total-line > th.total-line__name.payment-due-label > span.payment-due-label__taxes > span',                                 // Checkout taxes included in price

    checkoutTotal: 'tr.total-line > td.total-line__price.payment-due > span.payment-due__price',                                                        // Checkout total
    checkoutTotalCurrency: 'tr.total-line > td.total-line__price.payment-due > span.payment-due__currency',                                             // Checkout total currency

    /**
     * Default selector for price
     * finds a tag with `data-product-id` that does not have variant that ends with `:compare`
     */
    productPrice: '[data-product-id]:not([data-variant-id$=":compare"])',
};

/**
 * location of iframe checkout
 * @type {Object.<string, string>}
 */
export const CHECKOUT_SRC = {
    [ENV_PROD]: "https://secure.rch.io/index.html",
    [ENV_SANDBOX]: "https://secure.rch.how/index.html",
    [ENV_DEV]: "https://staging-secure.rch.red/index.html",
    [ENV_QA]: "https://secure.rch.ninja/index.html",
}

// An array of selectors that need to automatically convert prices
export const CONVERT_SELECTORS = [
    // Prices on normal shop pages
    'span.money',

    // We add an extra class to the shipping methods once we have finished parsing the price
    // to indicate it is ready for conversion.
    SHOPIFY_SELECTORS.checkoutShippingMethods + '.gip-shipping-price-processed',
    SHOPIFY_SELECTORS.checkoutDiscountedShippingMethods + '.gip-shipping-price-processed',

    SHOPIFY_SELECTORS.checkoutShipping,
    SHOPIFY_SELECTORS.checkoutDuties,
    SHOPIFY_SELECTORS.checkoutTax,
    SHOPIFY_SELECTORS.checkoutTaxInPrice,
];

// Selector for the payment section  on the Shopify checkout page
export const SHOPIFY_PAYMENT_SELECTOR = "div.section--payment-method";

// Configuration Names
export const CONFIG_NAMES = {
    MerchantId: "data-merchant_id",
    StoreCurrency: "data-store_currency",
    processStoreCurrency: "data-process_store_currency",
    Env: "data-env",
    FlcOnly: "data-flc_only",
    WhitelistedGateways: "data-whitelisted_gateways",
    WhitelistedStoreCurrencyGateways: "data-whitelisted_store_currency_gateways",
    CurrencyTag: "data-currency_tag",
    DisabledCountries: "data-disabled_countries",
    BlockGACrawlers: "data-block_ga_crawlers",
    AutoRoundPrices: "data-auto_round_prices",
    AllowedCurrencies: "data-allowed_currencies",
    BadgeTheme: "data-badge_theme",
    BannerTheme: "data-banner_theme",
    BannerPosition: "data-banner_position",
    HideZeroDecimals: "data-hide_zero_decimal",
    EdgeMode: "data-edge_mode",
    KlarnaAnchorProduct: "data-klarna_anchor_product",
    KlarnaPlacementTheme: "data-klarna_placement_theme",
    KlarnaPlacementKey: "data-klarna_placement_key",
};

// Checkout API paths
export const LOCALIZE_PATH = "/v2.21/localize";

// Timeout for AJAX requests to the Checkout API
export const AJAX_TIMEOUT = 10000;

// Selector for optional dropdown for selecting the currency
export const GIP_CURRENCY_SELECT = "select.gip_currency_select";

// Selector for displaying the text of the selected option. If no text is available, will default
// to showing the currency code instead.
export const GIP_CURRENCY_SELECT_TEXT = ".gip-currency-select-text";

// Selector for displaying the currency code
export const GIP_CURRENCY_CODE_CLASS = ".gip-currency-code";

// Selector for currency classes
// Any element with this class will have a class with currency info added
// ie. '.gip-currency-class-usd'
export const GIP_CURRENCY_CLASS = ".gip-currency-class";

// Selector for optional
export const GIP_CURRENCY_SELECT_LOCAL = `${GIP_CURRENCY_SELECT} > option.gip_currency_select_local`;

// Regex for detecting Shopify checkout pages
//
// Pages we need to detect:
// Thank You Page
//   - to update the payment method
//   - use the original rate offer used for the transaction
//   - Sample URL: https://checkout.shopify.com/15678647/checkouts/2885db5d8ecfb75e53903a019129bbe2/thank_you
//
// Forwarding and Processing page
//   - to avoid extra work on these redirect pages
//   - Sample Forward URL: https://checkout.shopify.com/15678647/checkouts/38acf43a8763d8015011921f8e83983c/forward?complete=1&previous_step=payment_method&step=
//   - Sample Procressing URL: https://checkout.shopify.com/15678647/checkouts/2cd34a02eb5cafe512e6331da153bfc0/processing

export const SHOPIFY_CHECKOUT_REGEX
    = new RegExp(/\/\d+\/[a-z0-9]+\/([a-z0-9]{32})(?:(\/thank_you)|(\/forward|\/processing))?/i);

// Regex for pulling hostname out of a url
export const SCRIPT_REGEX = /^(http[s]?:)?\/\/[^/]+/;

// Local storage for user selected currency
export const GIP_LS_USER_CURRENCY = "GIP_USER_CURRENCY";

// Tag for refreshing the user currency when needed
export const GIP_LS_USER_CURRENCY_TAG = "GIP_USER_CURRENCY_TAG";

// Special query param used in Google Ads custom parameters
// to force a certain currency.
export const GIP_CURRENCY_QUERY_PARAM = "_rchcur"; // Stands for 'Reach currency'

/**
 * Checkout related
 */

// Checkout API paths
export const METHODS_PATH = "/v2.21/getPaymentMethods";
export const FINGERPRINT_PATH = "/v2.21/fingerprint";
export const BADGE_PATH = "/v2.21/badge";
export const CARD_INFO_PATH = "/v2.21/getCardInfo";

// Parse the fingerprint ID out of iframe src
// Example url: https://checkout-sandbox.gointerpay.net/pixel/3939a03c-ea50-4109-b687-64650ae27d2c/450663df-a3d4-4c3c-9ffc-a765aa04d673.htm
export const FINGERPRINT_REGEX = new RegExp(/([\da-z-]+)\.htm$/);

// Local storage for shipping country
export const GIP_LS_SHIPPING_COUNTRY = "GIP_SHIPPING_COUNTRY";

export const SHOPIFY_FREE_SELECTOR
    = "div[data-payment-subform='gift_cards'], div[data-payment-subform='free']";

// Selector for the billing address toggle on PM page
export const SHOPIFY_DIFFBILL_SELECTOR =
    "input[name='checkout[different_billing_address]']";

// Selector for billing address country
export const SHOPIFY_BILLCOUNTRY_SELECTOR = "#checkout_billing_address_country";

// Various messages shown to the user. Obviously will need to be i18n if we
// ever care.
export const MESSAGES = {
    SelectMethod: "Please select a payment method below.",
    SelectIssuer: "Please select a valid issuer.",
    MissingField: "Please check the data entered below.",
    BadConfig: "This shop is not configured properly.",
    UnableToProcess: "We are currently unable to process your payment. Please try again.",
    MissingEmail: "Please enter your email address.",
    AuthorizingPayment: "Authorizing payment ... ",
    DoNotReload: "Please do not reload the page."
};

// Various strings that end up being shown to the user in one form or
// another.  Enumerated here so we can add i18n later.
export const STRINGS = {
    Email: "Email"
};

// Attribute name for the gateway id
export const SHOPIFY_GATEWAY_ATTRIBUTE = 'data-select-gateway';

// Selector for the gateway DOM elements.
export const SHOPIFY_GATEWAY_SELECTOR = "div[" + SHOPIFY_GATEWAY_ATTRIBUTE + "]";

// Selector for individual panels associated with methods
export const SHOPIFY_GATEWAY_SUBFIELDS_SELECTOR = "div[data-subfields-for-gateway]";

// Reach gateway names
export const GIP_GATEWAY_NAMES = [
    // Keep old names until after transition
    'GoInterpay',
    'GoInterpay-v1.3',
    'GoInterpay Sandbox',
    'GoInterpay Dev',

    // New names
    'Reach',
    'Reach Sandbox',
    'Reach Dev',
    'Reach QA'
];

// Save the last payment type selected
export const GIP_LS_PAYMENT_TYPE = 'GIP_PAYMENT_TYPE';

// Edge mode may enable functionality not available by default
export const GIP_LS_EDGE_MODE = 'GIP_EDGE_MODE';

// Checkout iframe host
export const GIP_LS_CHECKOUT_HOST = 'GIP_CHECKOUT_HOST';
/**
 * for debug/dev/test purposes, allow defining klarna setting using local storage
 */
export const GIP_LS_KLARNA_ANCHOR_PRODUCT = 'GIP_DEBUG_KLARNA_ANCHOR_PRODUCT';

// For parsing the expiry date field
export const EXPIRY_REGEX = /.*(\d{2}).*\/?.*(\d{2}).*/;

// Supported additional data fields
export const COUNTRY_DATA_FIELDS = [
    'national_id',
    'birthdate'
];

/**
 * payment methods that support klarna customer messaging
 */
export const KLARNA_PAYMENT_METHODS = [
    "KLARNA",
    "KLARNA_PAYLATER", // will eventually be removed
    "KLARNA_PAYNOW", // will eventually be removed
    "KLARNA_PAYOVERTIME", // will eventually be removed
]

// Country additional data
export const COUNTRY_DATA = {
    BR: {
        national_id: {
            id: "NationalIdentifier",
            name: 'CPF',
            placeholder: 'CPF',
            regex: /\d{11}/,
            validate: () => true,
            formatFunction: (value) => value.replace(/[^a-zA-Z\d]+/g, ""),
            mask: '###.###.###-##'
        },
        birthdate: {
            id: "BirthDate",
            name: 'Birthdate',
            placeholder: 'Birthdate (YYYY-MM-DD)',
            regex: /\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])/,
            validate: (value) => {
                let year = parseInt(value.substring(0,4),10);
                let current_date = new Date();
                let current_year = current_date.getFullYear();
                
                return (year < current_year) && (year >= current_year - 100);
            },
            mask: '####-##-##'
        }
    },
    AR: {
        national_id: {
            id: "NationalIdentifier",
            name: 'DNI',
            placeholder: 'DNI',
            regex: /^\d{7,11}$/i,
            validate: () => true,
            formatFunction: (value) => value.replace(/[^a-zA-Z\d]+/g, ""),
            mask: '###########'
        }
    },
    MX: {
        national_id: {
            id: "NationalIdentifier",
            name: 'RFC / CURP / IFE',
            placeholder: 'RFC / CURP / IFE',
            regex: /^[a-zA-Z0-9]{1,18}$/i,
            validate: () => true,
            formatFunction: (value) => value.replace(/[^a-zA-Z\d]+/g, ""),
            mask: 'WWWWWWWWWWWWWWWWWW'
        }
    }
};



/**
 * token definition for mask
 */
export const COUNTRY_DATA_MASK_TOKENS = {
    // number
    '#': { pattern: /[0-9]/ },
    // number or character => uppercase
    'W': { pattern: /[0-9a-zA-Z]/, uppercase: true },
  }

// Payment Method Display Rules
// Order credit cards based on the following country/currency combos.
export const PAYMENT_METHOD_ORDER_PER_COUNTRY = {
    BR: {
        BRL: ['VISA', 'MC', 'ELO', 'HIPERCARD']
    },
    CN: {
        CNY: ['UNIONPAY', 'VISA', 'MC', 'AMEX']
    },
    HK: {
        HKD: ['UNIONPAY', 'VISA', 'MC', 'AMEX'] 
    },
    JP: {
        JPY: ['JCB', 'VISA', 'MC', 'AMEX'] 
    },
    SG: {
        SGD: ['UNIONPAY', 'VISA', 'MC', 'AMEX'] 
    }
};