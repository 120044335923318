import $ from "jquery";
import * as Utils from "./utils";
import { GIP_LS_BANNER_SEEN } from "./constants";
import { attachModal } from "./modal";
import { Config } from "./config";
import { GIPAPI } from "./gip-api";
import bannerTemplate from "./template/banner.html";

const openClass = "reach--banner-open";

/**
 * makes api call and renders banner
 *
 * checks first if banner has been seen already
 * then checks if currency is supported (price localization)
 * or that user is using store currency
 * then calls the badge API
 * and then renders it
 */
export const callBadgeAndRenderBanner = () => {
    /**
     * EDGEMODE: Disables Banner functionality while in edge mode
     */
    if (Config.edgeMode !== true) return;

    // disable for FLC
    if (Config.flcOnly === true) return;

    let bannerSeen = localStorage.getItem(GIP_LS_BANNER_SEEN);
    if (bannerSeen === null) {
        GIPAPI.isHandledByReach().async(() => {
            const showBanner = Config.isBadgeRequired;
            Utils.clog(4, `Banner ${showBanner ? "is" : "is not"} required`);
            if (showBanner) {
                GIPAPI.badge(badge => {
                    Utils.clog(4, "Badge data loaded, render banner");
                    renderBanner(badge);
                });
            }
        });
    } else {
        Utils.clog(4, `Banner already seen`);
    }
};

/**
 * Builds the display for the banner
 *
 * @param {Object} badge /badge API response
 */
const renderBanner = badge => {
    const templateVars = {
        ...badge,
        theme: Config.bannerTheme,
        bannerPosition: Config.bannerPosition,
    };

    const parsedBannerTemplate = Utils.parseTemplate(
        bannerTemplate,
        templateVars
    );

    const closeBanner = () => {
        // toggle visibility
        $(".reach--banner").removeClass(openClass);
    };

    // add flag to LocalStore, not to show again
    localStorage.setItem(GIP_LS_BANNER_SEEN, true);

    // remove any other item with reach--banner class
    $(".reach--banner").remove();
    $("body").append(parsedBannerTemplate);

    const banner = $(".reach--banner");

    banner.addClass(openClass);

    // transition time is 0.75s, add 5s
    const autoClose = setTimeout(function() {
        closeBanner(banner);
    }, 6500);

    $(".reach--close-button")
        .off("click.GIP-Banner")
        .one("click.GIP-Banner", e => {
            e.preventDefault();
            closeBanner(0);
        });

    attachModal(badge, $(".reach--inline-link"));
};
