import $ from "jquery";
import * as Utils from "./utils";
import modalTemplate from "./template/modal.html";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

let modalLoaded = false;

/**
 * Builds the display for the modal, and attaches visibility trigger to passed element
 *
 * @param {Object} badge
 * @param {JQuery} element
 */
export const attachModal = (badge, element) => {
    if (!modalLoaded) {
        $(".gip_badge-modal-overlay").remove();
    }

    element.off("click.GIP").on("click.GIP", e => {
        e.preventDefault();
        if (!modalLoaded) {
            const parsedModalTemplate = Utils.parseTemplate(modalTemplate, {
                TermsOfServiceUrl: badge.TermsOfServiceUrl,
            });
            $("body").append(parsedModalTemplate);
            modalLoaded = true;
        }
        const $modal = $(".gip_badge-modal");
        $(".gip_badge-modal-overlay").addClass("is-visible");
        $modal.addClass("is-opened");
        disableBodyScroll($modal);

        $(document)
            .off("mouseup.GIP touchend.GIP")
            .one("mouseup.GIP touchend.GIP", event => {
                if (
                    !$modal.is(event.target) &&
                    $modal.has(event.target).length === 0 &&
                    $modal.hasClass("is-opened")
                ) {
                    $(".gip_badge-modal-overlay").removeClass("is-visible");
                    $modal.removeClass("is-opened");
                    clearAllBodyScrollLocks();
                }
            });

        $(".gip_badge-modal__close-btn")
            .off("click.GIP")
            .one("click.GIP", () => {
                $(".gip_badge-modal-overlay").removeClass("is-visible");
                $modal.removeClass("is-opened");
                clearAllBodyScrollLocks();
            });
    });
};
