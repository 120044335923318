import { h } from "preact";
import { useState, useEffect } from "preact/hooks";
import { store, setters } from "./AppC";
import { getCountryDataFields, removeErrorOnCountryData } from "./appInternal";
import { create as createMask } from "maska";
import { COUNTRY_DATA_MASK_TOKENS } from "../constants";

/**
 * Handles showing additional data fields based on country
 */
export const AdditionalDataC = () => {
    const countryFields = getCountryDataFields();
    const shouldShow = countryFields.length > 0;

    // hanlde visibility of Shops Additional Info section
    if (store.country === "BR") {
        hideShopAdditionalInfo();
    } else {
        showShopAdditionalInfo();
    }

    return (
        shouldShow && (
            <div style="margin-bottom: 30px;margin-top: 30px;">
                <div class="section__header">
                    <h2 class="section__title">Additional Data</h2>
                    <p class="section__text">
                        In order to checkout, we are required to gather the data
                        below.
                    </p>
                </div>
                <fieldset class="content-box">
                    <div class="radio-group__row content-box__row content-box__row--secondary card-fields-container--transitioned">
                        <div class="fieldset">
                            {countryFields.map((field) => (
                                <CountryFieldC field={field} />
                            ))}
                        </div>
                    </div>
                </fieldset>
            </div>
        )
    );
};

export const CountryFieldC = (props) => {
    /** @type {CountryDataField} */
    const field = props.field;

    const [inputMask, setInputMask] = useState(null);

    // track Shopify's duplicate input element
    const [shopInputEl, setShopInputEl] = useState(undefined);

    if (store.country === "BR" && field.key === "national_id") {
        if (shopInputEl === undefined) {
            // check if the brazil national ID exists
            /** @type {HTMLInputElement} */
            const el = document.getElementById(
                "checkout_localized_fields_shipping_credential_br"
            );

            setShopInputEl(el);
            hideShopAdditionalInfo();
        }
    }

    if (store.country === "BR" && shopInputEl) {
        hideShopAdditionalInfo();
    }

    useEffect(() => {
        // initialize data on mount
        updateCountryData(field, "");
    }, []);

    useEffect(() => {
        if (shopInputEl) {
            shopInputEl.value = store.countryData[field.id].value;
        }
    }, [shopInputEl, field.id, store.countryData]);

    const onRef = (dom) => {
        if (dom && !inputMask) {
            setInputMask(createMask(dom, { tokens: COUNTRY_DATA_MASK_TOKENS }));
        }
    };

    /**
     * @param {InputEvent} e
     */
    const handleChange = (e) => {
        let value = e.target.value;
        updateCountryData(field, value);

        // on value change, remove error
        removeErrorOnCountryData(field.key);
    };

    const showError = issuerHasErrors(field.key);
    const errorClass = showError && "gip_field--error";

    return (
        <div class={`field--half field field--required ${errorClass}`}>
            <div class="field__input-wrapper">
                <div
                    class="field__input-wrapper"
                    aria-describedby="error-for-name tooltip-for-name"
                >
                    <input
                        ref={onRef}
                        name={"input-" + field.name}
                        data-mask={field.mask}
                        autocomplete="off"
                        type="text"
                        onInput={handleChange}
                        class="field__input"
                        placeholder={field.placeholder}
                        id={`gip_country_field_${field.key}`}
                    />
                </div>
            </div>
            {showError && (
                <p class="field__message gip_field__message--error">
                    Please enter a valid value
                </p>
            )}
        </div>
    );
};

/**
 * helper to manage updating the data
 * @param {CountryDataField} field
 * @param {string} value
 */
const updateCountryData = (field, value) => {
    // delay to prevent synchronous override when multiple inputs are present
    setTimeout(() => {
        const updatedCountryData = {
            ...store.countryData,
        };

        updatedCountryData[field.id] = {
            ...updatedCountryData[field.key],
            value: field.formatFunction(value),
            key: field.key,
        };

        setters.setCountryData(updatedCountryData);
    }, 0);
};

/**
 * @param {string} fieldKey
 */
const issuerHasErrors = (fieldKey) => {
    const countryDataErrors = store.validationErrors.CountryData || {};
    return countryDataErrors[fieldKey] !== undefined;
};

/**
 * hide additional info section (i.e. CPF field)
 */
const hideShopAdditionalInfo = () => {
    const el = document.querySelector(".section.section--custom-fields");
    if (el && el.style.display !== "none") el.style.display = "none";
};

/**
 * show additional info section (i.e. CPF field)
 */
const showShopAdditionalInfo = () => {
    const el = document.querySelector(".section.section--custom-fields");
    if (el && el.style.display !== "inherit") el.style.display = "inherit";
};
