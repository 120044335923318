import $ from "jquery";
import * as Utils from "./utils";
import { Config } from "./config";
import { GIPAPI } from "./gip-api";
import { attachModal } from "./modal";
import badgeTemplate from "./template/badge.html";

/**
 * makes api call and renders badge
 */
export const callAndRenderBadge = () => {
    // disable for FLC
    if (Config.flcOnly === true) return;

    GIPAPI.isHandledByReach().async(() => {
        const showBadge = Config.isBadgeRequired;
        Utils.clog(4, `Badge ${showBadge ? "is" : "is not"} required`);
        if (showBadge) {
            GIPAPI.badge(badge => {
                Utils.clog(4, "Badge data loaded, render badge");
                renderBadge(badge);
            });
        }
    });
};

/**
 * Builds the display for the badge and modal
 */
const renderBadge = badge => {
    let badgeContainer = $(".gip_badge-wrapper");

    const templateVars = { ...badge, theme: Config.badgeTheme };

    const parsedBadgeTemplate = Utils.parseTemplate(
        badgeTemplate,
        templateVars
    );

    badgeContainer.html(parsedBadgeTemplate);

    attachModal(badge, $(".reach--inline-link"));
};
