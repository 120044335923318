import {Config} from "./config";
import * as Constants from "./constants";
import * as Utils from "./utils"
import $ from "jquery";
import Q from "q";
import * as Sentry from "@sentry/browser";
import { Deferred } from "./deferred";

let badgeDeferred = null;

/**
 * Modify options before they are processed by $.ajax()
 */
$.ajaxPrefilter(settings => {

    // Append trace ID if available
    if(typeof Config.traceID === 'string' && Config.traceID.length > 0) {

        let traceParam = '_trace_=' + encodeURIComponent(Config.traceID);

        //Utils.clog(3, '===', settings.method, settings.url);

        // For GET requests we will append to the URL.
        // An undefined method will also be defaulted to GET.
        // Note that this will run before the data object parameters are appended to the URL by $.ajax().
        if (settings.method === 'GET' || settings.method === undefined) {

            //Utils.clog(3, settings.url);

            if(settings.url.indexOf('?') !== -1){
                settings.url += '&' + traceParam;
            } else {
                settings.url += '?' + traceParam;
            }

            //Utils.clog(3, settings.url);
        }

        // For POST requests we will append to the data string, which will already contain
        // the data object parameters set in $.ajax().
        else if (settings.method === 'POST') {

            //Utils.clog(3, settings.data);

            if(settings.data.length > 0){
                settings.data += '&' + traceParam;
            } else if(settings.data.length === 0) {
                settings.data = traceParam;
            }

            //Utils.clog(3, settings.data);
        }
    }
});

let GIPAPI = {

    /**
     * Call localize on the Checkout Api, and get a rate.
     */
    localize(merchantPrices, callback){

        let args = {
            MerchantId: Config.merchantId,
            IncludeRate: true,
            MerchantPrices: JSON.stringify(merchantPrices)
        };

        // Has the currency been selected?
        if (Config.selectedCurrency != null) {
            args.Currency = Config.selectedCurrency;
        }

        // Do it
        $.ajax({
            method: 'POST',
            url: Config.apiHost + Constants.LOCALIZE_PATH,
            timeout: Constants.AJAX_TIMEOUT,
            data: args,
            success: function(data) {

                // figure out if badge should be shown
                // reference chart: https://app.lucidchart.com/documents/edit/322ccbe2-79ff-450b-ae30-e5b47207dbd8/0_0
                Config.isActiveCurrency = !!data.RateOffer;
                Config.isStoreCurrency = data.Currency === Config.storeCurrency;

                // If rate offer not returned, abort GIP.
                if(!Config.isActiveCurrency) {

                    Utils.abortConverter('No rate offer available');

                    Config.localizeDataDeferred.resolve(false);

                    if(Config.localizeDataObserver) {
                        Config.localizeDataObserver.next(false);
                    }

                    callback(false);

                } else {

                    let convert = false;
                    if(Config.disabledCountries.indexOf(data.Country) !== -1){
                        Config.isDisabledCountry = true;
                        Utils.abortConverter('Localized to a disabled country code: ' + data.Country);
                    } else if(Config.allowedCurrencies.length > 0 && Config.allowedCurrencies.indexOf(data.Currency) === -1){
                        Utils.abortConverter('Localized currency is restricted: ' + data.Currency);
                    } else if(Utils.isThankYouPage() || Utils.isOrderSummaryPage()){
                        Utils.abortConverter('Don\'t convert for thank you and order summary pages');
                    } else if(data.Currency === Config.storeCurrency){
                        Utils.abortConverter('Rate offer same as store currency');
                    } else {
                        convert = true;
                    }

                    // Save to memory
                    Config.localizeData = data;

                    if(convert){

                        // Set currency to localized result
                        Utils.selectCurrency(Config.localizeData.Currency);

                        Config.localizeDataDeferred.resolve(data);

                        if(Config.localizeDataObserver) {
                            Config.localizeDataObserver.next(data);
                        }

                        callback(data);

                    } else {

                        Config.localizeDataDeferred.resolve(false);

                        if(Config.localizeDataObserver) {
                            Config.localizeDataObserver.next(false);
                        }

                        callback(false);
                    }

                }

            },
            error: function(jqXHR) {

                if(Utils.safariAndFFAbortedError(jqXHR)){
                    return;
                }

                Utils.abortConverter("Unable to get localize result");

                Config.localizeDataDeferred.resolve(false);

                if(Config.localizeDataObserver) {
                    Config.localizeDataObserver.next(false);
                }

                callback(false);
            }
        });
    },

    /**
     * send localize rate request(s) to determine if badge should be shown
     *      is also used to determine initial step of determining if klarna
     *      should be shown (use Config.isKlarnaMessagingSupported instead of response)
     * @return {Deferred<LocalizeResponse>} deferred
     */
    isHandledByReach() {
        let dataResponse = null;
        if (badgeDeferred !== null) {
            return badgeDeferred;
        } else {
            badgeDeferred = Deferred();
                
            let args = {
                MerchantId: Config.merchantId,
                IncludeRate: true,
                MerchantPrices: null,
                Currency: Config.selectedCurrency,
            };

            /**
             * curry response
             * @param {Bool} isSupportedCurrency value to resolve promise with
             * @returns Function
             */
            const setConfigBadge = (isSupportedCurrency) => () => {
                Config.isBadgeRequired = isSupportedCurrency;
                Config.isKlarnaMessagingSupported = isSupportedCurrency;

                if (isSupportedCurrency && Config.localizeData === null) {
                    // if localize data is empty, use the result
                    Config.localizeData = dataResponse;
                }
                badgeDeferred.set(dataResponse);
            };

            const onResponse = (response) => {
                Utils.clog(4, "Localize loaded for badge");
                // figure out if badge should be shown
                // reference chart: https://app.lucidchart.com/documents/edit/322ccbe2-79ff-450b-ae30-e5b47207dbd8/0_0
                Config.isActiveCurrency = !!response.RateOffer;
                Config.isStoreCurrency = response.Currency === Config.storeCurrency;
                dataResponse = response;

                if(!Config.isStoreCurrency && Config.processStoreCurrency && !Config.isActiveCurrency) {
                    Utils.clog(4, "Make a second localize check");
                    // if the RateOffer is not provided for first call, check again, but pass store currency
                    $.ajax({
                        method: 'POST',
                        url: Config.apiHost + Constants.LOCALIZE_PATH,
                        timeout: Constants.AJAX_TIMEOUT,
                        data: {...args, Currency:Config.storeCurrency},
                        success: (storeResponse) => {
                            dataResponse = storeResponse;
                            Config.isActiveCurrency = !!storeResponse.RateOffer;
                            const showBadge = (!Config.isStoreCurrency && Config.isActiveCurrency) || (Config.isStoreCurrency && Config.processStoreCurrency && Config.isActiveCurrency);
                            Utils.clog(4, "2nd Localize loaded for badge, showBadge:" + showBadge);
                            setConfigBadge(showBadge)();
                        },
                        error: setConfigBadge(false)
                    })
                } else {
                    const showBadge = (!Config.isStoreCurrency && Config.isActiveCurrency) || (Config.isStoreCurrency && Config.processStoreCurrency && Config.isActiveCurrency);
                    setConfigBadge(showBadge)();
                }
            };

            $.ajax({
                method: 'POST',
                url: Config.apiHost + Constants.LOCALIZE_PATH,
                timeout: Constants.AJAX_TIMEOUT,
                data: args,
                success: onResponse,
                error: setConfigBadge(false)
            });
        }

        return badgeDeferred;
    },

    /**
     * Get checkout cart data
     */
    getCheckoutCart(callback){

        // New url for this endpoint only
        let url;
        if(Config.env === Constants.ENV_PROD) {
            url = 'https://shopify.rch.io';
        } else if(Config.env === Constants.ENV_SANDBOX) {
            url = 'https://shopify.rch.how';
        } else if(Config.env === Constants.ENV_DEV) {
            url = 'https://shopify.rch.red';
        } else if(Config.env === Constants.ENV_QA) {
            url = 'https://shopify.rch.ninja';
        }
        url += '/getCart/r1';

        let data = {
            MerchantId: Config.merchantId,
            Currency: Config.selectedCurrency || Config.storeCurrency,
            Token: Config.checkoutToken,
            DiscountItems: Config.checkoutData.discountItems.join(',')
        };

        $.ajax({
            url: url,
            data: data,
            success: function(response) {
                callback(response);
            },
            error: function(jqXHR) {

                if(Utils.safariAndFFAbortedError(jqXHR)){
                    return;
                }

                Utils.abortConverter("Unable to get checkout data");
            }
        });
    },

    /**
     * Get device fingerprint
     */
    fingerprint(){

        if(Config.merchantId === undefined) {

            Utils.clog(1, "MID not available, fingerprint API not called");

        } else {
            $.ajax({
                method: 'GET',
                url: Config.apiHost + Constants.FINGERPRINT_PATH,
                data: {
                    MerchantId: Config.merchantId
                }
            }).done(function (response) {

                // Sets gip_device_fingerprint
                eval(response);

                // Parse fingerprint ID
                // @TODO ES6 modules run eval in strict mode, so no access to gip_device_fingerprint
                Config.gipFingerprint = $('#gip_fingerprint').attr('src').match(Constants.FINGERPRINT_REGEX)[1];
            });
        }
    },

    /**
     * Get payment methods
     */
    getPaymentMethods(callback){
        const data = {
            MerchantId: Config.merchantId,
            Country: Utils.getCountry(),
            Currency: Config.selectedCurrency || Config.storeCurrency,
            Locale: Utils.getHtmlLang()
        };

        Sentry.addBreadcrumb({
            category: "getPaymentMethods",
            message: "getPaymentMethods request",
            data
        });

        $.ajax({
            method: 'GET',
            url: Config.apiHost + Constants.METHODS_PATH,
            data
        }).done(function (response) {

            //Utils.clog(3, response);

            /** @var response.PaymentMethods */
            if(response.PaymentMethods){

                callback(response.PaymentMethods);

            } else{

                callback(false);

            }

        }).fail(function() {
            const error = new Error("getPaymentMethods request failed")
            Sentry.captureException(error, { tags: {errorType: "getPaymentMethods API Fail"} } );
            throw error;
        });
    },

    /**
     * Get badge
     */
    badge(callback){

        if(Config.merchantId === undefined) {

            Utils.clog(1, "MID not available, badge API not called");

        } else {

            $.ajax({
                method: 'GET',
                url: Config.apiHost + Constants.BADGE_PATH,
                data: {
                    MerchantId: Config.merchantId,
                    Theme: Config.badgeTheme,
                }
            }).done(function (response) {

                callback(response);

            }).fail(function() {
                
                const error = new Error("get badge request failed");
                Sentry.captureException(error, { tags: {errorType: "Badge API Fail"} } );
                throw error;

            });

        }

    },

    /**
     * Stash data
     *
     * @param sgwData - hash of data destined for use by the SGW.
     */
    stash(sgwData) {

        if(!sgwData.CartId){
            Utils.clog(3, "No cart id available. Can't stash");
            return;
        }

        let sgwDataDeferred = Q.defer();

        let urlPrefix = Config.stashHost + "/" + Config.merchantId;

        if (sgwData) {
            let url = urlPrefix + '/' + sgwData.CartId;

             // wrapped request in setInterval to avoid NS_BINDING_ABORTED issue
            // see: https://stackoverflow.com/a/12214363/197546
            const n = setInterval(() => {
                $.ajax({
                    method: "POST",
                    timeout: Constants.AJAX_TIMEOUT,
                    url: url,
                    async: false,
                    data: sgwData,
                    success: function() {
                        sgwDataDeferred.resolve();
                    },
                    error: function(xhr, ajaxOptions, thrownError) {
                        Sentry.captureException(thrownError, { tags: {errorType: "Stash API Fail"} } );
                        sgwDataDeferred.reject();
                    }
                });
                clearInterval(n);
            }, 0);
        } else {
            sgwDataDeferred.resolve();
        }

        return sgwDataDeferred;

    },

    /**
     * Get card info
     */
    getCardInfo(iin, callback){

        let url = Config.apiHost + Constants.CARD_INFO_PATH;

        $.ajax({
            url: url,
            data: {
                MerchantId: Config.merchantId,
                IIN: iin
            },
            success: function(response) {
                callback(response);
            },
            error: function(error) {
                callback(error);
            }
        });
    },

    /**
     * Get order summary page data
     */
    getOrderSummaryData(callback){

        let checkoutURL = location.origin + location.pathname;
        let match = checkoutURL.match(/https?:\/\/(.+)\/(\d+)\/orders\/([a-f\d]+)/);
        let urlToken = match ? match[3] : null;

        // New url for this endpoint only
        let url;
        if(Config.env === Constants.ENV_PROD) {
            url = 'https://shopify.rch.io';
        } else if(Config.env === Constants.ENV_SANDBOX) {
            url = 'https://shopify.rch.how';
        } else if(Config.env === Constants.ENV_DEV) {
            url = 'https://shopify.rch.red';
        } else if(Config.env === Constants.ENV_QA) {
            url = 'https://shopify.rch.ninja';
        }
        url += '/getOrder/r1';

        let data = {
            MerchantId: Config.merchantId
        };

        // Send different params depending on context
        if(Utils.isThankYouPage()){

            data.CheckoutToken = Config.checkoutToken;

        } else if(Utils.isOrderSummaryPage()){

            data.OrderToken = urlToken;
            data.OrderId = Config.postCheckoutPageOrderID;

        }

        $.ajax({
            url: url,
            data: data,
            success: function(response) {

                if(response.WithReach){

                    if(response.currency === Config.storeCurrency){

                        Utils.abortConverter("Reach order in domestic currency; do not process order summary");

                    } else {

                        callback(response);

                    }

                } else{

                    Utils.abortConverter("Non-Reach order; do not process order summary");

                }

            },
            error: function(jqXHR) {

                if(Utils.safariAndFFAbortedError(jqXHR)){
                    return;
                }

                Utils.abortConverter("Unable to get order summary data");
            }
        });
    }
};

export {GIPAPI};
